import { t } from "i18next";
import { useState, useEffect } from "react";
import { EthiopiaModalTypes } from "types/ethiopiaModal.types";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const EthiopiaModel = ({ isVisible, handleClose }: EthiopiaModalTypes) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setShow(true);
    } else {
      setTimeout(() => setShow(false), 300);
    }
  }, [isVisible]);

  return show ? (
    <div tw="fixed inset-0 flex items-center justify-center z-[1999]">
      <div tw="fixed inset-0 bg-black opacity-50" onClick={handleClose}></div>
      <aside
        tw="relative bg-white text-grey-500 gap-4 max-w-[35rem] p-4 rounded shadow-lg transition-transform duration-300 transform flex flex-col m-[1rem]"
        className={isVisible ? "animate-slide-in" : "animate-slide-out"}
        css={[
          isVisible
            ? tw`translate-y-0 opacity-100`
            : tw`translate-y-full opacity-0`,
        ]}>
        <h2>
          <strong tw="font-medium">{t("warning_ethiopia_title")}</strong>
        </h2>
        <div tw="text-sm">
          <strong tw="font-medium">{t("warning_ethiopia_subtitle")}</strong>
          {t("warning_ethiopia_subtitle_1")}{" "}
          <strong tw="font-medium">{t("Tigray")}</strong>
          {t("warning_ethiopia_subtitle_2")}
          {t("warning_ethiopia_subtitle_3")}
        </div>
        <div tw="text-sm">
          <strong tw="font-medium">{t("warning_ethiopia_description")}</strong>
          {t("warning_ethiopia_description_1")}
          {t("warning_ethiopia_description_1_1")}{" "}
          <strong tw="font-medium">{t("Sidama")}</strong>{" "}
          {t("warning_ethiopia_description_2")}{" "}
          <strong tw="font-medium">{t("Southwest")}</strong>
          {t("warning_ethiopia_description_3")}{" "}
          <strong tw="font-medium">{t("central")}</strong>
        </div>
        <button
          onClick={handleClose}
          tw="mt-4 p-2 bg-orange-200 hover:bg-orange-400 font-semiBold uppercase rounded-[2.75rem] text-orange-700">
          {t("close")}
        </button>
      </aside>
    </div>
  ) : null;
};

export default EthiopiaModel;
