import shareIcon from "assets/images/shareIcon.svg";
import { useAfricanYouth } from "context/africanYouth";
import { t } from "i18next";
import { SharedUrltypes } from "types/share-url.types";
import useShareUrl from "hooks/urlStateManagement";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const ShareBtn = ({
    ages,
    section,
    country,
    comparedCountry,
    gender,
    region,
    status,
    sector
}: SharedUrltypes) => {
    const { year, setFilters } = useAfricanYouth();
    const handleShareUrl = useShareUrl();

    return (
        <button
            onClick={() => {
                handleShareUrl({
                    ages,
                    section,
                    country,
                    comparedCountry,
                    year,
                    gender,
                    region,
                    status,
                    sector
                });
                setFilters({
                    name: "isSharedModalOpen",
                    value: true
                });
                setFilters({
                    name: "sharedModalSection",
                    value: section
                });
            }}
            tw="md:w-[calc(100% - 2rem)] md:m-auto hover:bg-orange-400 bg-orange-200 p-[0.5rem 1rem] flex justify-center items-center rounded-[1rem] font-semiBold text-sm  text-orange-700 uppercase gap-[1rem] w-[6.938rem]"
        >
            <img src={shareIcon} alt="Share" />
            {t("share")}
        </button>
    );
};

export default ShareBtn;
