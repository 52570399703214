import { createContext, useContext, useReducer } from "react";
import { globalStateReducer } from "reducers/globalState";
import {
  ActiveFiltersDispatchProps,
  ChildrenProps,
} from "types/african-youth-context.types";
import { initialState } from "utils/initialState";

const AfricanYouthContext = createContext(initialState);

export const AfricanYouthContextProvider = ({ children }: ChildrenProps) => {
  const [state, dispatch] = useReducer(globalStateReducer, initialState);

  const setCountryFilters = ({ name, value }: ActiveFiltersDispatchProps) =>
    dispatch({
      payload: { name, value },
      type: "SELECT_COUNTRY_FILTERS",
    });

  const setAgesFilters = ({ name, value }: ActiveFiltersDispatchProps) =>
    dispatch({
      payload: { name, value },
      type: "SELECT_AGE_FILTERS",
    });

  const setFilters = ({ name, value }: ActiveFiltersDispatchProps) =>
    dispatch({
      payload: { name, value },
      type: "SELECT_FILTERS",
    });

  const setMobileModalDropdown = ({
    name,
    value,
    section,
    data,
  }: ActiveFiltersDispatchProps) =>
    dispatch({
      payload: { name, value, section, data },
      type: "SELECT_FILTERS_MODAL",
    });

  const setActiveLanguage = ({ name, value }: ActiveFiltersDispatchProps) => {
    dispatch({
      payload: { name, value },
      type: "SELECT_FILTERS",
    });
    localStorage.setItem("i18nextLng", value);
    window.location.reload();
  };

  const setFiltersDropdown = ({ name, value }: ActiveFiltersDispatchProps) => {
    dispatch({
      payload: { name, value },
      type: "SELECT_FILTERS",
    });
  };

  const resetMobileModalDropdown = ({
    name,
    value,
  }: ActiveFiltersDispatchProps) => {
    dispatch({
      payload: { name, value },
      type: "RESET_MOBILE_MODAL_DROPDOWN",
    });
  };

  const value = {
    activeFeedback: state.activeFeedback,
    area: state.area,
    activeSummaryGender: state.activeSummaryGender,
    ages: state.ages,
    country: {
      ...state.country,
    },
    regionMapSection: state.regionMapSection,
    regionGendersSection: state.regionGendersSection,
    regionTrendsSection: state.regionTrendsSection,
    mobileModalDropdown: {
      ...state.mobileModalDropdown,
    },
    isFiltersDialogOpen: state.isFiltersDialogOpen,
    filterListForSection: state.filterListForSection,
    employment: state.employment,
    gender: state.gender,
    language: state.language,
    status: state.status,
    disaggregated: state.disaggregated,
    trendsEmployedSector: state.trendsEmployedSector,
    mapEmployedSector: state.mapEmployedSector,
    mapEmployedStatus: state.mapEmployedStatus,
    genderSector: state.genderSector,
    genderStatus: state.genderStatus,
    mapCoords: state.mapCoords,
    year: state.year,
    sharedModalSection: state.sharedModalSection,
    isSharedModalOpen: state.isSharedModalOpen,
    sharedUrl: state.sharedUrl,
    setActiveLanguage,
    setCountryFilters,
    setFilters,
    setFiltersDropdown,
    setMobileModalDropdown,
    setAgesFilters,
    resetMobileModalDropdown,
    showEthiopiaModal: state.showEthiopiaModal,
  };

  return (
    <AfricanYouthContext.Provider value={value}>
      {children}
    </AfricanYouthContext.Provider>
  );
};

export const useAfricanYouth = () => {
  const context = useContext(AfricanYouthContext);
  if (context === undefined)
    throw new Error(
      "useAfricanYouth needs to be within the AfricanYouthContext"
    );

  return context;
};
