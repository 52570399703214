import useWindowResize from "hooks/useWindowResize";
import info from "assets/images/info.svg";
import arrowDown from "assets/images/arrowDown.svg";
import useToggle from "hooks/useToggle";
import { IndexKey } from "types/indexKey";
import { t } from "i18next";
import { InnerGroupsType } from "types/situations.types";
import { Tooltip } from "types/employment.types";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Inner = ({
    data,
    tooltip,
    setTooltip
}: {
    data: InnerGroupsType;
    tooltip: Tooltip | null;
    setTooltip: (category: Tooltip | null) => void;
}) => {
    const { toggleState, handleToggleState } = useToggle();
    const { isDesktop } = useWindowResize();

    const handleInnerGroups = () =>
        data.map(({ data, name }) => (
            <div key={`${name}_inner`} tw="flex flex-col">
                <div tw="flex justify-between">
                    <div tw="flex py-[0.5rem] gap-[0.5rem] items-center">
                        <h4 tw="font-regular text-sm">{t(name)}</h4>
                        <button
                            tw="relative w-[25px]"
                            className={isDesktop ? "group" : ""}
                            onMouseOutCapture={() => setTooltip(null)}
                            onMouseOverCapture={(e) =>
                                setTooltip({ event: e, category: name })
                            }
                            onClick={(e) => {
                                return isDesktop
                                    ? undefined
                                    : tooltip?.category
                                    ? setTooltip(null)
                                    : setTooltip({ event: e, category: name });
                            }}
                        >
                            <img width={25} src={info} alt="Info" />
                        </button>
                    </div>
                    <button
                        onClick={() =>
                            handleToggleState(`is${name}DropdownOpen`)
                        }
                    >
                        <img
                            src={arrowDown}
                            alt="Arrow"
                            css={[
                                toggleState[
                                    `is${name}DropdownOpen` as keyof IndexKey
                                ] && tw`transform rotate-[180deg]`
                            ]}
                        />
                    </button>
                </div>
                <div tw="flex w-full bg-grey-50">
                    {data.map(({ percentage, color }) => (
                        <span
                            key={color}
                            tw="block h-[0.5rem] last-of-type:rounded-r-[0.5rem]"
                            style={{
                                background: color,
                                width: `${percentage}%`
                            }}
                        ></span>
                    ))}
                </div>
                {toggleState[`is${name}DropdownOpen` as keyof IndexKey] &&
                    data.map(({ percentage, value, name, color }) => (
                        <div
                            key={percentage}
                            tw="border-b border-b-grey-100 flex justify-between py-[0.5rem] last-of-type:border-0 font-regular text-sm"
                        >
                            <div tw="flex items-center">
                                {color && (
                                    <span
                                        tw="block w-[1rem] h-[1rem] rounded-[50%] mr-[0.5rem] "
                                        style={{ background: color }}
                                    ></span>
                                )}
                                <h5>{t(name)}</h5>
                            </div>
                            <div tw="flex items-center font-semiBold">
                                <DynamicSvg iconName="population" />
                                <span tw="px-[0.5rem]">{value}</span>
                                <span tw="text-sm text-grey-500">
                                    {percentage}%
                                </span>
                            </div>
                        </div>
                    ))}
            </div>
        ));

    return <>{handleInnerGroups()}</>;
};

export default Inner;
