import DynamicSvg from "components/DynamicSvg/DynamicSvg";
import { t } from "i18next";
import { handleToLocaleString, handleToFixed } from "utils/formatting";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const handleAgriculture = (name?: string | undefined) => {
    if (name === "Agriculture; forestry and fishing") {
        return "agriculture";
    }
    return name;
};

const Sectors = ({ data }: { data: any }) =>
    data.map((sector: any) => (
        <div
            key={sector.name}
            tw="border border-grey-100 p-[0.2rem] rounded flex items-start justify-between gap-[0.25rem]"
        >
            <div tw="flex flex-col">
                <div tw="flex items-center gap-[0.2rem]">
                    <img
                        tw="h-[0.85rem]"
                        src={`assets/images/sectors_smalls/${handleAgriculture(
                            sector.name
                        )!.toLocaleLowerCase()}.svg`}
                        alt={sector}
                        width={14}
                    />
                    <h4 tw="font-semiBold text-[0.56rem]">{t(sector.name)}</h4>
                </div>
                <span
                    style={{ background: sector.color }}
                    tw="ml-[0.1rem] rounded-full w-[0.65rem] h-[0.65rem]"
                ></span>
            </div>
            <div tw="flex gap-[0.25rem] items-center font-semiBold">
                <DynamicSvg iconName="population" size={14} />
                <span tw="text-xs">
                    {handleToLocaleString(sector.population)}
                </span>
                <span tw="text-[0.56rem] text-grey-500">
                    {handleToFixed(sector.percentage)}%
                </span>
            </div>
        </div>
    ));

export default Sectors;
