import closeShare from "assets/images/closeShare.svg";
import shareWhite from "assets/images/shareWhite.svg";
import { useAfricanYouth } from "context/africanYouth";
import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Header = () => {
    const { setFilters } = useAfricanYouth();

    return (
        <div tw="flex gap-[1rem] items-center justify-between border-b border-b-white pb-[1rem]">
            <div tw="flex items-center gap-[1rem]">
                <img width={12} src={shareWhite} alt="Share" />
                <h2 tw="font-semiBold text-xl text-white">
                    {t("share_image_files")}
                </h2>
            </div>
            <button
                tw="rounded-full border border-white p-5 hover:bg-grey-500 hover:bg-opacity-80"
                onClick={() => {
                    setFilters({ name: "sharedUrl", value: null });
                    setFilters({
                        name: "isSharedModalOpen",
                        value: false
                    });
                    setFilters({
                        name: "sharedModalSection",
                        value: null
                    });
                }}
            >
                <img alt="Close button" src={closeShare} />
            </button>
        </div>
    );
};

export default Header;
