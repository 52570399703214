import { useAfricanYouth } from "context/africanYouth";
import useForm from "hooks/useForm";
import { t } from "i18next";
import { feedbackIcons } from "utils/helpUs";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
import MessageSent from "components/Main/Feedback/MessageSent";
import useWindowResize from "hooks/useWindowResize";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

const Help = () => {
    const { activeFeedback, setFilters } = useAfricanYouth();
    const { formData, handleClick, handleChange, handleReset, handleSubmit } =
        useForm();
    const { isDesktop } = useWindowResize();

    return (
        <section
            tw="p-[0 4rem 2rem] sm:p-0 border-t border-t-grey-200"
            id="help_us"
        >
            <h2 tw="font-semiBold text-xl text-grey-600 sm:pt-[2rem] p-[2rem 0 0.5rem] sm:px-[1rem]">
                {t("help_us_get_better_title")}
            </h2>
            <p tw="text-grey-500 text-sm pb-[0.5rem] sm:px-[1rem]">
                {t("help_us_get_better_subtitle")}
            </p>
            <span tw="block border-b border-b-grey-200 pb-[1.5rem]"></span>
            {formData?.isMessageSent ? (
                <MessageSent handleReset={handleReset} />
            ) : (
                <div
                    css={[
                        tw`border border-grey-200 rounded-[0.5rem] max-w-[54.375rem] md:border-0 w-full mx-auto mt-[1.5rem] md:mt-0 p-[9.75rem 12.688rem] lg:p-[9.75rem 8rem] md:p-[3.5rem 1rem]`,
                        activeFeedback &&
                            tw`p-[1rem 12.688rem] lg:p-[1rem 8rem] md:p-[3.5rem 1rem]`
                    ]}
                >
                    <h3
                        css={[
                            tw`text-grey-500 pb-[1.5rem] font-medium text-center`,
                            activeFeedback && tw`pt-[1rem]`
                        ]}
                    >
                        {t("help_us_get_better_question")}
                    </h3>
                    <div
                        css={[
                            tw`flex justify-between items-start sm:p-0 sm:max-w-[27rem] max-w-[37rem] m-auto gap-[1rem] w-full`,
                            activeFeedback && tw`p-[0 1rem 1rem]`
                        ]}
                    >
                        {feedbackIcons.map((item) => (
                            <button
                                css={[
                                    tw`text-grey-400 text-sm flex items-center flex-col gap-[0.5rem]`,
                                    item.name === activeFeedback?.name &&
                                        tw`text-grey-600`
                                ]}
                                key={item.name}
                                name={item.id}
                                onClick={(e) => {
                                    setFilters({
                                        name: "activeFeedback",
                                        value: item
                                    });
                                    handleClick(e);
                                }}
                            >
                                <DynamicSvg
                                    size={isDesktop ? 48 : 40}
                                    iconName={item.name}
                                    primaryColor={
                                        item.name === activeFeedback?.name
                                            ? item.color
                                            : theme`colors.grey.50`
                                    }
                                    secondaryColor={
                                        item.name === activeFeedback?.name
                                            ? theme`colors.grey.50`
                                            : item.color
                                    }
                                />
                                {t(item.name)}
                            </button>
                        ))}
                    </div>
                    {activeFeedback && (
                        <form
                            onSubmit={handleSubmit}
                            css={[
                                tw`text-grey-500 p-[1rem] font-medium flex flex-col gap-[1rem] items-center`,
                                activeFeedback &&
                                    tw`border-t border-t-grey-200 mt-[1rem]`
                            ]}
                        >
                            <p tw="text-center sm:w-full w-[25rem]">
                                {t(activeFeedback?.question)}
                            </p>
                            <textarea
                                onChange={handleChange}
                                name="message"
                                placeholder={t("type_here") as string}
                                tw="w-full m-auto h-[10rem] bg-grey-50 border font-regular border-orange-600 rounded text-grey-700 text-[1.063rem] p-[0.5rem 0.75rem] placeholder-grey-700"
                            ></textarea>
                            <button
                                disabled={!formData?.message}
                                type="submit"
                                css={[
                                    tw`sm:m-[2.25rem auto 0] bg-orange-600 ml-auto py-[0.5rem] w-full max-w-[7rem] text-center flex justify-center
                            items-center rounded-[2.75rem] font-semiBold text-[0.938rem]  text-white uppercase gap-[1rem]`,
                                    !formData?.message && tw`opacity-30`
                                ]}
                            >
                                {t("send")}
                            </button>
                        </form>
                    )}
                </div>
            )}
        </section>
    );
};

export default Help;
