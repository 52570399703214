import { useCallback, useState } from "react";

const useActiveLink = () => {
    const [activeLink, setActiveLink] = useState("#home");

    const handleActiveSection = useCallback(
        (id: string) => setActiveLink(id),
        [setActiveLink]
    );

    return { handleActiveSection, activeLink };
};

export default useActiveLink;
