const pngscale = 2;

export const BASE_DPI = 72;

export const pngOptions = (element: any) => ({
  height: element.clientHeight * pngscale,
  width: element.clientWidth * pngscale,
  style: {
    transform: `scale(${pngscale}) translate(${
      element.clientWidth / pngscale / pngscale
    }px, ${element.clientHeight / pngscale / pngscale}px)`,
  },
});

export const itemsPerSlide = 3;

export const sectorsPerSlide = 9;

export const currentYear = new Date().getFullYear();

export const secondsPerYear = 31556926;

export const beginningCurrentYear =
  new Date(currentYear, 0, 0).getTime() / 1000;

export const splitItems = (items: [], itemsWithinSlide: number) => {
  const groups = [];
  for (let i = 0; i < items.length; i += itemsWithinSlide) {
    groups.push(items.slice(i, i + itemsWithinSlide));
  }
  return groups;
};

export const handleAgriculture = (name?: string | undefined) => {
  if (name === "Agriculture; forestry and fishing") {
    return "agriculture";
  }
  return name;
};

export const isEthiopiaSelected = (countryName: string) =>
  countryName === "Ethiopia";
