import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "twin.macro";

const PercentageSideBySideBarChart = ({ data }: { data: any }) => {
    const options = {
        tooltip: {
            enabled: false
        },
        credits: false,
        chart: {
            height: 40,
            type: "bar",
            marginLeft: 0,
            marginRight: 0,
            spacingLeft: 0,
            backgroundColor: "transparent"
        },
        xAxis: {
            labels: {
                enabled: false
            },
            gridLineWidth: 0
        },
        yAxis: {
            min: 0,
            max: 100,
            title: {
                text: null
            },
            labels: {
                enabled: false
            },
            gridLineWidth: 0
        },
        plotOptions: {
            series: {
                stacking: "normal",
                dataLabels: {
                    enabled: true,
                    format: "{point.y:.0f}%",
                    style: { shadow: false }
                }
            }
        },
        legend: {
            enabled: false
        },
        title: {
            text: null
        },
        series: data
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default PercentageSideBySideBarChart;
