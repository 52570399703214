import Vertical from "components/ActiveShare/Inner/Genders/Container/Vertical";
import Horizontal from "components/ActiveShare/Inner/Genders/Container/Horizontal";
import { useAfricanYouth } from "context/africanYouth";
import { urls } from "config/urls";
import useFetch from "hooks/useFetch";
import { getCountryId } from "utils/countries";

const Container = ({
    isShareModalVertical,
    isDesktop
}: {
    isShareModalVertical: boolean;
    isDesktop: boolean;
}) => {
    const {
        year,
        country,
        genderStatus,
        ages,
        employment,
        regionGendersSection,
        genderSector,
        gender
    } = useAfricanYouth();

    const { data } = useFetch(
        `${urls.API_HOST_URL}gender?year=${year}&ageFrom=${
            ages.from || 15
        }&ageTo=${ages.to || 35}&countryCode=${getCountryId(
            country.sectors_section || ""
        )}&sector=${genderStatus && genderStatus.toLocaleUpperCase()}${
            employment !== "both"
                ? `&jobType=${employment.toLocaleUpperCase()}`
                : ""
        }${regionGendersSection ? `&region=${regionGendersSection}` : ""}`
    );

    const { data: sectorsData } = useFetch(
        `${urls.API_HOST_URL}sectors/${genderSector}?year=${year}&ageFrom=${
            ages.from || 15
        }&ageTo=${ages.to || 35}&countryCode=${getCountryId(
            country.sectors_section || ""
        )}${regionGendersSection ? `&region=${regionGendersSection}` : ""}`
    );

    return data && sectorsData ? (
        !isDesktop || isShareModalVertical ? (
            <Vertical
                data={{
                    data:
                        genderSector && sectorsData
                            ? [...sectorsData.sectors]
                            : [...data],
                    country: country.employment_section!,
                    year,
                    ages: [ages.from!, ages.to!],
                    gender,
                    status: genderStatus!,
                    sector: genderSector!,
                    region: regionGendersSection!
                }}
                isShareModalVertical={isShareModalVertical}
                isDesktop={isDesktop}
            />
        ) : (
            <Horizontal
                data={{
                    data:
                        genderSector && sectorsData
                            ? [...sectorsData.sectors]
                            : [...data],
                    country: country.employment_section!,
                    year,
                    ages: [ages.from!, ages.to!],
                    gender,
                    status: genderStatus!,
                    sector: genderSector!,
                    region: regionGendersSection!
                }}
                isShareModalVertical={isShareModalVertical}
                isDesktop={isDesktop}
            />
        )
    ) : null;
};

export default Container;
