import { t } from "i18next";
import facebook from "assets/images/footer/facebook.svg";
import globe from "assets/images/footer/globe.svg";
import instagram from "assets/images/footer/instagram.svg";
import linkedIn from "assets/images/footer/linkedIn.svg";
import twitter from "assets/images/footer/twitter.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const ContactUs = () => (
    <div>
        <h3 tw="tracking-[0.2em] uppercase pb-[1rem]">
            {t("footer_contact_us")}
        </h3>
        <a href="mailto:ayec@worlddata.io">ayec@worlddata.io</a>
        <ul tw="flex justify-between gap-[1rem] pt-[1rem] max-w-[20rem] h-[3.7rem]">
            <li>
                <a href="https://worlddata.io/">
                    <img src={globe} alt="Website" />
                </a>
            </li>
            <li>
                <a href="https://www.linkedin.com/company/world-data-lab">
                    <img src={linkedIn} alt="LinkedIn" />
                </a>
            </li>
            <li>
                <a href="https://twitter.com/worlddatalab">
                    <img src={twitter} alt="Twitter" />
                </a>
            </li>
            <li>
                <a href="https://www.facebook.com/worlddatalab/">
                    <img src={facebook} alt="Facebook" />
                </a>
            </li>
            <li>
                <a href="https://instagram.com/worlddatalab">
                    <img src={instagram} alt="Instagram" />
                </a>
            </li>
        </ul>
    </div>
);

export default ContactUs;
