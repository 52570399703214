import { t } from "i18next";
import { useAfricanYouth } from "context/africanYouth";
import arrow from "assets/images/arrow.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";

const AgeSlider = () => {
    const { ages, setAgesFilters } = useAfricanYouth();

    const handleAges = (type: keyof typeof ages, isMinus: boolean) =>
        setAgesFilters({
            name: type,
            value: ages[type]! + (isMinus ? -1 : 1)
        });

    return (
        <div tw="flex justify-between items-center">
            <div>
                <p tw="text-sm font-semiBold text-center text-grey-500">
                    {t("From")}
                </p>
                <div tw="flex justify-between items-center">
                    <button
                        onClick={() =>
                            ages.from! > 15
                                ? handleAges("from", true)
                                : undefined
                        }
                    >
                        <DynamicSvg
                            iconName={"minusIcon"}
                            primaryColor={
                                ages.from! <= 15 ? "#E4E4E4" : "#EC9B41"
                            }
                        />
                    </button>
                    <span tw="p-[0.4rem 0.8rem] m-[0.8rem] rounded-[0.3rem] border border-orange-600">
                        {ages.from ? ages.from : 15}
                    </span>
                    <button
                        onClick={() =>
                            ages.from! < 31
                                ? handleAges("from", false)
                                : undefined
                        }
                    >
                        <DynamicSvg
                            iconName={"plusIcon"}
                            primaryColor={
                                ages.from! >= 31 ? "#E4E4E4" : "#EC9B41"
                            }
                        />
                    </button>
                </div>
                <p tw="text-sm text-center text-grey-500">Min: 15 | Max: 31</p>
            </div>
            <img src={arrow} alt="Line" />
            <div>
                <p tw="text-sm font-semiBold text-center text-grey-500">
                    {t("to")}
                </p>
                <div tw="flex justify-between items-center">
                    <button
                        onClick={() =>
                            ages.to! > 19 ? handleAges("to", true) : undefined
                        }
                    >
                        <DynamicSvg
                            iconName={"minusIcon"}
                            primaryColor={
                                ages.to! <= 19 ? "#E4E4E4" : "#EC9B41"
                            }
                        />
                    </button>
                    <span tw="p-[0.4rem 0.8rem] m-[0.8rem] rounded-[0.3rem] border border-orange-600">
                        {ages.to ? ages.to : 35}
                    </span>
                    <button
                        onClick={() =>
                            ages.to! < 35 ? handleAges("to", false) : undefined
                        }
                    >
                        <DynamicSvg
                            iconName={"plusIcon"}
                            primaryColor={
                                ages.to! >= 35 ? "#E4E4E4" : "#EC9B41"
                            }
                        />
                    </button>
                </div>
                <p tw="text-sm text-center text-grey-500">Min: 19 | Max: 35</p>
            </div>
        </div>
    );
};
export default AgeSlider;
