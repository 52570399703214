import { t } from "i18next";
import { countries } from "utils/lists";
import { handleToFixed, handleToLocaleString } from "utils/formatting";
import { useAfricanYouth } from "context/africanYouth";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Info = ({
    data,
    isShareModalVertical,
    isInnerRegion
}: {
    data: any;
    isShareModalVertical: boolean;
    isInnerRegion?: boolean;
}) => {
    const { sharedModalSection } = useAfricanYouth();

    return (
        <article
            css={[
                tw`flex flex-col gap-[0.25rem] w-full max-w-[30rem] m-auto`,
                isInnerRegion && tw`m-0`
            ]}
        >
            <div tw="flex justify-between h-[29px]">
                <img
                    tw="self-center"
                    width={isShareModalVertical ? 16 : 32}
                    alt={data.country!}
                    src={`https:${
                        countries.find(({ name }) => data.country === name)
                            ?.file_url
                    }`}
                />
                <div>
                    <h3 tw="text-[0.56rem] text-grey-400">
                        {t(data.region ? "regionShare" : "countryShare")}
                    </h3>
                    <p tw="text-[0.68rem] text-grey-700 truncate max-w-[4.875rem]">
                        {data.region || data.country}
                    </p>
                </div>
                <div>
                    <h3 tw="text-[0.56rem] text-grey-400">{t("agesShare")}</h3>
                    <p tw="text-[0.68rem] text-grey-700">
                        {`${data.ages[0]}-${data.ages[1]}`}
                    </p>
                </div>
                <div>
                    <h3 tw="text-[0.56rem] text-grey-400">
                        {t("genderShare")}
                    </h3>
                    <p tw="text-[0.68rem] text-grey-700 capitalize">
                        {data.gender}
                    </p>
                </div>
                <div>
                    <h3 tw="text-[0.56rem] text-grey-400">{t("yearShare")}</h3>
                    <p tw="text-[0.68rem] text-grey-700">{data.year}</p>
                </div>
            </div>
            {data.status && (
                <div tw="flex gap-[1rem] items-center justify-between">
                    <div>
                        <h3 tw="text-[0.56rem] text-grey-400">
                            {t("status.title")}
                        </h3>
                        <p tw="text-[0.68rem] text-grey-700">{data.status}</p>
                    </div>
                    {data.disaggregated && (
                        <div>
                            <h3 tw="text-[0.56rem] text-grey-400">
                                {t("disaggregated.title")}
                            </h3>
                            <p tw="text-[0.68rem] text-grey-700 capitalize">
                                {data.disaggregated}
                            </p>
                        </div>
                    )}
                    {data.sector && (
                        <div>
                            <h3 tw="text-[0.56rem] text-grey-400">
                                {t("sectorShare")}
                            </h3>
                            <p tw="text-[0.68rem] text-grey-700 capitalize">
                                {data.sector}
                            </p>
                        </div>
                    )}
                </div>
            )}
            {sharedModalSection === "Employment" && (
                <div
                    css={[
                        tw`bg-white border border-grey-100 rounded p-[0.25rem] flex items-center justify-between gap-[0.5rem] py-[0.25rem]`,
                        !isShareModalVertical && tw`justify-between`
                    ]}
                >
                    <h4 tw="text-[0.56rem]">
                        {t(
                            data.data.sectorGroup
                                ? `${data.data.sectorGroup.toLocaleLowerCase()}.title`
                                : "population.title"
                        )}
                    </h4>
                    <span tw="flex gap-[0.2rem] items-center text-[0.68rem] font-semiBold text-grey-600">
                        <DynamicSvg iconName="population" size={14} />
                        {handleToLocaleString(data.data.totalPopulation)}
                        {data.data.populationPercentage && (
                            <span tw="text-grey-500 text-[0.56rem]">
                                {handleToFixed(data.data.populationPercentage)}%
                            </span>
                        )}
                    </span>
                </div>
            )}
        </article>
    );
};

export default Info;
