import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const MessageSent = ({ handleReset }: any) => (
    <div tw="border border-grey-200 rounded-[0.5rem] max-w-[54.375rem] md:border-0 w-full mx-auto mt-[1.5rem] md:mt-0 p-[4rem 0] md:p-[0 1rem] sm:p-[0 1rem 4.5rem]">
        <p tw="m-auto text-grey-500 text-[1.063rem] px-[1rem] text-center font-medium sm:w-full w-full max-w-[36rem] md:pt-[2rem]">
            {t("help_us_get_better_feedback")}
        </p>
        <button
            onClick={handleReset}
            tw="m-[1.5rem auto 0] bg-orange-600 p-[0.5rem] sm:w-full max-w-[20rem] text-center flex justify-center
            items-center rounded font-semiBold text-[0.938rem] text-white uppercase gap-[1rem] md:mr-auto w-[6.938rem] mr-[8.313rem] sm:mr-auto"
        >
            {t("finish")}
        </button>
    </div>
);

export default MessageSent;
