import Vertical from "components/ActiveShare/Inner/CountryComparison/Container/Vertical";
import Horizontal from "components/ActiveShare/Inner/CountryComparison/Container/Horizontal";
import { useAfricanYouth } from "context/africanYouth";
import { urls } from "config/urls";
import useFetch from "hooks/useFetch";
import { getCountryId } from "utils/countries";
import { getGender } from "utils/filters";

const Container = ({
    isShareModalVertical,
    isDesktop
}: {
    isShareModalVertical: boolean;
    isDesktop: boolean;
}) => {
    const { year, country, ages, gender } = useAfricanYouth();

    const { data: comparisonCountry1 } = useFetch(
        `${urls.API_HOST_URL}countries/${getCountryId(
            country.comparison_country_1_section || ""
        )}?year=${year}&ageFrom=${ages.from || 15}&ageTo=${ages.to || 35}${
            gender !== "all" ? `&genderFilter=${getGender(gender)}` : ""
        }
    `,
        !country.comparison_country_1_section
    );

    const { data: comparisonCountry2 } = useFetch(
        `${urls.API_HOST_URL}countries/${getCountryId(
            country.comparison_country_2_section || ""
        )}?year=${year}&ageFrom=${ages.from || 15}&ageTo=${ages.to || 35}${
            gender !== "all" ? `&genderFilter=${getGender(gender)}` : ""
        }
    `,
        !country.comparison_country_2_section
    );

    return comparisonCountry1 && comparisonCountry2 ? (
        !isDesktop || isShareModalVertical ? (
            <Vertical
                countryComparison1={{
                    data: comparisonCountry1,
                    country: "",
                    year,
                    ages: [ages.from!, ages.to!],
                    gender
                }}
                countryComparison2={{
                    data: comparisonCountry2,
                    country: "",
                    year,
                    ages: [ages.from!, ages.to!],
                    gender
                }}
                isShareModalVertical={isShareModalVertical}
                isDesktop={isDesktop}
            />
        ) : (
            <Horizontal
                countryComparison1={{
                    data: comparisonCountry1,
                    country: "",
                    year,
                    ages: [ages.from!, ages.to!],
                    gender
                }}
                countryComparison2={{
                    data: comparisonCountry2,
                    country: "",
                    year,
                    ages: [ages.from!, ages.to!],
                    gender
                }}
                isShareModalVertical={isShareModalVertical}
                isDesktop={isDesktop}
            />
        )
    ) : null;
};

export default Container;
