/** @jsxImportSource @emotion/react */
import "twin.macro";

const Pages = ({
    activeSlide,
    itemsLength
}: {
    activeSlide: number;
    itemsLength: number;
}) => (
    <span tw="flex justify-end text-[0.56rem] text-grey-400 gap-1">
        Part <strong tw="text-grey-600">{activeSlide}</strong>
        of <strong tw="text-grey-600">{itemsLength}</strong>
    </span>
);

export default Pages;
