import i18n from "i18next";
import fra from "i18n/fra.json";
import eng from "i18n/eng.json";
import por from "i18n/por.json";
import kis from "i18n/kis.json";
import detector from "i18next-browser-languagedetector";
import TagManager from "react-gtm-module";
import { initReactI18next } from "react-i18next";
import AfricaYouthEmployment from "views/AfricaYouthEmployment";
import { AfricanYouthContextProvider } from "context/africanYouth";
import ErrorBoundary from "components/ErrorBoundary";

const tagManagerArgs = {
    dataLayerName: "pageView",
    gtmId: "G-QHD8JNM9RC"
};

TagManager.initialize(tagManagerArgs);

i18n.use(initReactI18next)
    .use(detector)
    .init({
        supportedLngs: ["eng", "fra", "por", "kis"],
        resources: {
            eng: {
                translation: eng
            },
            fra: {
                translation: fra
            },
            por: {
                translation: por
            },
            kis: {
                translation: kis
            }
        },
        fallbackLng: "eng",
        interpolation: {
            escapeValue: true
        }
    });

const App = () => {
    TagManager.dataLayer(tagManagerArgs);

    return (
        <ErrorBoundary>
            <AfricanYouthContextProvider>
                <AfricaYouthEmployment />
            </AfricanYouthContextProvider>
        </ErrorBoundary>
    );
};

export default App;
