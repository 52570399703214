import { t } from "i18next";
import { DataSharedProps } from "types/data-shared.types";
import { handleToLocaleString, handleToFixed } from "utils/formatting";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Statuses = ({ data }: { data: DataSharedProps }) => (
    <>
        <div tw="flex items-center justify-between py-[0.25rem]">
            <div tw="flex items-center gap-[0.5rem]">
                <span tw="rounded-full bg-green-600 w-[0.65rem] h-[0.65rem]"></span>
                <h4 tw="font-semiBold text-[0.56rem]">{t("Employed.title")}</h4>
            </div>
            <div tw="flex gap-[0.5rem] items-center font-semiBold">
                <span tw="text-xs">
                    {handleToLocaleString(
                        data.data.employmentBreakdown.totalEmployed
                    )}
                </span>
                <span tw="text-[0.56rem] text-grey-500">
                    {handleToFixed(
                        data.data.employmentBreakdown.employedPercentage
                    )}
                    %
                </span>
            </div>
        </div>
        <div tw="flex items-center justify-between py-[0.25rem]">
            <div tw="flex items-center gap-[0.5rem] ml-[1.2rem]">
                <h4 tw="text-[0.56rem]">
                    {t("Employed.trends.list.not_poor")}
                </h4>
            </div>
            <div tw="flex gap-[0.5rem] items-center font-semiBold">
                <span tw="text-xs">
                    {handleToLocaleString(
                        data.data.employmentBreakdown.povertyNotPoorEmployed
                    )}
                </span>
                <span tw="text-[0.56rem] text-grey-500">
                    {handleToFixed(
                        data.data.employmentBreakdown.povertyNotPoorPercentage
                    )}
                    %
                </span>
            </div>
        </div>
        <div tw="flex items-center justify-between py-[0.25rem]">
            <div tw="flex items-center gap-[0.5rem] ml-[1.2rem]">
                <h4 tw="text-[0.56rem]">
                    {t("Employed.trends.list.moderately_poor")}
                </h4>
            </div>
            <div tw="flex gap-[0.5rem] items-center font-semiBold">
                <span tw="text-xs">
                    {handleToLocaleString(
                        data.data.employmentBreakdown
                            .povertyModeratelyPoorEmployed
                    )}
                </span>
                <span tw="text-[0.56rem] text-grey-500">
                    {handleToFixed(
                        data.data.employmentBreakdown
                            .povertyModeratelyPoorPercentage
                    )}
                    %
                </span>
            </div>
        </div>
        <div tw="flex items-center justify-between border-b border-b-grey-100 py-[0.25rem] ml-[1.2rem]">
            <div tw="flex items-center gap-[0.5rem]">
                <h4 tw="text-[0.56rem]">
                    {t("Employed.trends.list.extremely_poor")}
                </h4>
            </div>
            <div tw="flex gap-[0.5rem] items-center font-semiBold">
                <span tw="text-xs">
                    {handleToLocaleString(
                        data.data.employmentBreakdown
                            .povertyExtremelyPoorEmployed
                    )}
                </span>
                <span tw="text-[0.56rem] text-grey-500">
                    {handleToFixed(
                        data.data.employmentBreakdown
                            .povertyExtremelyPoorPercentage
                    )}
                    %
                </span>
            </div>
        </div>
        <div tw="flex items-center justify-between border-b border-b-grey-100 py-[0.25rem] ml-[1.2rem]">
            <div tw="flex items-center gap-[0.5rem]">
                <h4 tw="text-[0.56rem]">{t("Employed.trends.list.formal")}</h4>
            </div>
            <div tw="flex gap-[0.5rem] items-center font-semiBold">
                <span tw="text-xs">
                    {handleToLocaleString(
                        data.data.employmentBreakdown.totalFormalJobs
                    )}
                </span>
                <span tw="text-[0.56rem] text-grey-500">
                    {handleToFixed(
                        data.data.employmentBreakdown.formalJobsPercentage
                    )}
                    %
                </span>
            </div>
        </div>
        <div tw="flex items-center justify-between border-b border-b-grey-100 py-[0.25rem] pl-[1.2rem]">
            <div tw="flex items-center gap-[0.5rem]">
                <h4 tw="text-[0.56rem]">
                    {t("Employed.trends.list.urbanShare")}
                </h4>
            </div>
            <div tw="flex gap-[0.5rem] items-center font-semiBold">
                <span tw="text-xs">
                    {handleToLocaleString(
                        data.data.employmentBreakdown.totalUrbanArea
                    )}
                </span>
                <span tw="text-[0.56rem] text-grey-500">
                    {handleToFixed(
                        data.data.employmentBreakdown.urbanAreaPercentage
                    )}
                    %
                </span>
            </div>
        </div>
        <div tw="flex items-center justify-between border-b border-b-grey-100 py-[0.25rem]">
            <div tw="flex items-center gap-[0.5rem]">
                <span tw="rounded-full bg-blue-600 w-[0.65rem] h-[0.65rem]"></span>
                <h4 tw="font-semiBold text-[0.56rem]">{t("Student.title")}</h4>
            </div>
            <div tw="flex gap-[0.5rem] items-center font-semiBold">
                <span tw="text-xs">
                    {handleToLocaleString(
                        data.data.populationBreakDown.totalStudent
                    )}
                </span>
                <span tw="text-[0.56rem] text-grey-500">
                    {handleToFixed(
                        data.data.populationBreakDown.studentPercentage
                    )}
                    %
                </span>
            </div>
        </div>
        <div tw="flex items-center justify-between border-b border-b-grey-100 py-[0.25rem]">
            <div tw="flex items-center gap-[0.5rem]">
                <span tw="rounded-full bg-yellow-600 w-[0.65rem] h-[0.65rem]"></span>
                <h4 tw="font-semiBold text-[0.56rem]">{t("Inactive.title")}</h4>
            </div>
            <div tw="flex gap-[0.5rem] items-center font-semiBold">
                <span tw="text-xs">
                    {handleToLocaleString(
                        data.data.populationBreakDown.totalInactive
                    )}
                </span>
                <span tw="text-[0.56rem] text-grey-500">
                    {handleToFixed(
                        data.data.populationBreakDown.inactivePercentage
                    )}
                    %
                </span>
            </div>
        </div>
        <div tw="flex items-center justify-between py-[0.25rem]">
            <div tw="flex items-center gap-[0.5rem]">
                <span tw="rounded-full bg-red-600 w-[0.65rem] h-[0.65rem]"></span>
                <h4 tw="font-semiBold text-[0.56rem]">
                    {t("Unemployed.title")}
                </h4>
            </div>
            <div tw="flex gap-[0.5rem] items-center font-semiBold">
                <span tw="text-xs">
                    {handleToLocaleString(
                        data.data.populationBreakDown.totalUnemployed
                    )}
                </span>
                <span tw="text-[0.56rem] text-grey-500">
                    {handleToFixed(
                        data.data.populationBreakDown.unemployedPercentage
                    )}
                    %
                </span>
            </div>
        </div>
    </>
);

export default Statuses;
