import { t } from "i18next";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
import { useAfricanYouth } from "context/africanYouth";
import {
    CompareSituationsProps,
    CountryComparisonType
} from "types/compare-situation.types";
import { useEffect, useState } from "react";
import { mapCountryComparison } from "utils/countryComparison";
import { getCountryInfo } from "utils/countries";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Table = ({
    comparisonCountry1,
    comparisonCountry2
}: {
    comparisonCountry1: CountryComparisonType;
    comparisonCountry2: CountryComparisonType;
}) => {
    const { country } = useAfricanYouth();
    const [comparisonData, setComparisonData] =
        useState<CompareSituationsProps[]>();

    useEffect(() => {
        const comparisonData = mapCountryComparison(
            comparisonCountry1,
            comparisonCountry2
        );
        setComparisonData(comparisonData);
    }, [comparisonCountry1, comparisonCountry2]);

    if (!comparisonData) {
        return <></>;
    }

    return (
        <article tw="max-w-[38rem] flex flex-col pt-2 m-[0.25rem auto 0] w-full bg-white rounded-[0.25rem] px-[1rem]">
            <div tw="grid grid-cols-[repeat(3, 1fr)] justify-between items-baseline max-w-[27rem] m-auto px-1 pb-2">
                <div tw="font-semiBold text-grey-500 text-[0.56rem] gap-2 flex items-center justify-end">
                    <span>{t(country.comparison_country_1_section || "")}</span>
                    {country.comparison_country_1_section && (
                        <img
                            key={country.comparison_country_1_section}
                            loading="lazy"
                            width={16}
                            src={`https:${
                                getCountryInfo(
                                    country.comparison_country_1_section
                                )!.file_url
                            }`}
                            alt={`${country.comparison_country_1_section} flag`}
                        />
                    )}
                </div>
                <span tw="rounded-[50%] uppercase border border-purple-200 w-6 h-6 flex items-center text-purple-800 justify-self-center text-[0.56rem] justify-center">
                    vs
                </span>
                <div tw="font-semiBold text-grey-500 text-[0.56rem] justify-start flex items-center gap-2">
                    {country.comparison_country_2_section && (
                        <img
                            key={country.comparison_country_2_section}
                            loading="lazy"
                            width={16}
                            src={`https:${
                                getCountryInfo(
                                    country.comparison_country_2_section
                                )!.file_url
                            }`}
                            alt={`${country.comparison_country_2_section} flag`}
                        />
                    )}
                    <span>{t(country.comparison_country_2_section || "")}</span>
                </div>
            </div>
            {comparisonData.map((situation) => (
                <div
                    key={situation.name}
                    css={[
                        tw`gap-2 text-xs grid grid-cols-[repeat(3, 1fr)] justify-center items-center border-b border-b-grey-200 last-of-type:border-none`,
                        situation.open || situation.name === "unemployed"
                            ? tw`border-none`
                            : null
                    ]}
                >
                    <div tw="flex flex-col justify-end text-right text-[0.56rem]">
                        <p>
                            {(situation.country1?.number || 0).toLocaleString()}
                        </p>
                        <div
                            tw="flex self-end h-[0.3rem] bg-green-600 rounded-tl rounded-bl"
                            css={[
                                situation.name === "working_poverty" ||
                                situation.name === "formal_jobs" ||
                                situation.name === "urban_areas"
                                    ? tw`bg-green-400`
                                    : null
                            ]}
                            style={{
                                width: `${situation.country1.percentage}%`
                            }}
                        ></div>
                        {situation.country1.percentage && (
                            <span tw="text-grey-500 text-[0.56rem]">
                                {situation.country1.percentage}%
                            </span>
                        )}
                    </div>
                    <div tw="flex items-center justify-center">
                        <div tw="flex flex-col items-center text-center ">
                            <DynamicSvg size={14} iconName={situation.name} />
                            <h3
                                tw="font-semiBold text-[0.6rem] text-grey-500 flex justify-center capitalize"
                                css={[
                                    situation.name === "working_poverty" ||
                                    situation.name === "formal_jobs" ||
                                    situation.name === "urban_areas"
                                        ? tw`font-regular text-[0.56rem]`
                                        : null,

                                    situation.name === "working_poverty"
                                        ? tw`max-w-[59px] text-center`
                                        : null
                                ]}
                            >
                                {t(
                                    situation.name === "population"
                                        ? `${situation.name}.title`
                                        : situation.name
                                )}
                            </h3>
                        </div>
                    </div>
                    <div tw="flex flex-col text-[0.56rem]">
                        <p>
                            {(situation.country2?.number || 0).toLocaleString()}
                        </p>
                        <div
                            tw="flex self-start w-[13rem] h-[0.3rem] bg-yellow-400 rounded-tr rounded-br"
                            css={[
                                situation.name === "working_poverty" ||
                                situation.name === "formal_jobs" ||
                                situation.name === "urban_areas"
                                    ? tw`bg-yellow-300`
                                    : null
                            ]}
                            style={{
                                width: `${situation.country2.percentage}%`
                            }}
                        ></div>
                        {situation.country2.percentage && (
                            <span tw="text-grey-500 text-[0.56rem]">
                                {situation.country2.percentage}%
                            </span>
                        )}
                    </div>
                </div>
            ))}
        </article>
    );
};

export default Table;
