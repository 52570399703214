import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Legend = ({
    isFromShare,
    isShareModalVertical
}: {
    isFromShare?: boolean;
    isShareModalVertical?: boolean;
}) => (
    <div
        css={[
            tw`text-sm text-grey-700 max-w-[20rem] lg:m-auto lg:static flex flex-col gap-[0.5rem] bottom-[4rem] absolute left-[1rem] md:flex-wrap md:m-auto md:relative md:max-w-none md:left-[unset] md:right-[unset] md:w-[calc(100% - 2rem)] md:bottom-[unset]`,
            isFromShare &&
                isShareModalVertical &&
                tw`text-xs max-w-max lg:text-[0.56rem] m-auto static`
        ]}
    >
        <h3 tw="text-[0.56rem] uppercase text-grey-400 ">
            {t("educationals")}
        </h3>
        <div
            css={[
                tw`p-[1rem 0.5rem] bg-white lg:w-full shadow rounded-[0.5rem] border border-grey-100 gap-[0.5rem] flex lg:justify-between lg:items-center flex-col lg:flex-row sm:text-[0.65rem]`,
                isFromShare &&
                    isShareModalVertical &&
                    tw`max-w-max text-[0.56rem] m-auto static flex-row`,
                isFromShare && !isShareModalVertical && tw`text-[0.65rem]`
            ]}
        >
            <h4 tw="flex sm:justify-between items-center gap-[0.25rem]">
                <span tw="block bg-yellow-100 w-[0.625rem] h-[0.625rem] rounded-[1.031rem]"></span>{" "}
                {t("tertiary")}
            </h4>
            <h4 tw="flex sm:justify-between items-center gap-[0.25rem]">
                <span tw="block bg-yellow-200 w-[0.625rem] h-[0.625rem] rounded-[1.031rem]"></span>{" "}
                {t("secondary")}
            </h4>
            <h4 tw="flex sm:justify-between items-center gap-[0.25rem]">
                <span tw="block bg-yellow-400 w-[0.625rem] h-[0.625rem] rounded-[1.031rem]"></span>{" "}
                {t("primary")}
            </h4>
            <h4 tw="flex sm:justify-between items-center gap-[0.25rem]">
                <span tw="block bg-yellow-600 w-[0.625rem] h-[0.625rem] rounded-[1.031rem]"></span>{" "}
                {t("none")}
            </h4>
        </div>
    </div>
);

export default Legend;
