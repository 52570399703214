import MobileModalDropdown from "components/MobileModalDropdown";
import Footer from "components/Footer";
import Header from "components/Header";
import Hero from "components/Main/Hero";
import Education from "components/Main/Education";
import Employment from "components/Main/Employment";
import Sectors from "components/Main/Sectors";
import Methodology from "components/Main/Methodology";
import CountryComparison from "components/Main/CountryComparison";
import Trends from "components/Main/Trends";
import Feedback from "components/Main/Feedback";
import FiltersDialog from "components/FiltersDialog";
import YearFilter from "components/YearFilter";
import { Toaster } from "react-hot-toast";
import ActiveShare from "components/ActiveShare";
import useWindowResize from "hooks/useWindowResize";
import { useEffect } from "react";
import { params } from "hooks/urlStateManagement";
import EthiopiaModel from "components/EthiopiaModal";
import { useAfricanYouth } from "context/africanYouth";
import { isEthiopiaSelected } from "utils/utilities";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const AfricaYouthEmployment = () => {
  const {
    mobileModalDropdown,
    resetMobileModalDropdown,
    showEthiopiaModal,
    setFilters,
    country,
  } = useAfricanYouth();
  const { isDesktop } = useWindowResize();

  useEffect(() => {
    const timeout = setTimeout(() => {
      const element = document.querySelector(`#${params.get("section")}`);
      if (params.has("section") && element) element.scrollIntoView();
      window.history.pushState({}, "", document.location.origin);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const hasModalShown =
      sessionStorage.getItem("hasEthiopiaModalShown") === "true";

    if (!hasModalShown) {
      const selectedEthiopia = Object.values(country).some((selectedCountry) =>
        isEthiopiaSelected(selectedCountry!)
      );
      if (selectedEthiopia) {
        setFilters({ value: true, name: "showEthiopiaModal" });
        sessionStorage.setItem("hasEthiopiaModalShown", "true");
      }
    }
  }, [country, setFilters]);

  const handleClose = () =>
    setFilters({ value: false, name: "showEthiopiaModal" });

  return (
    <>
      <Header />
      <main tw="relative max-w-[110rem] m-auto">
        <Hero />
        <Employment />
        <Sectors />
        <Trends />
        <Education />
        <CountryComparison />
        <Methodology />
        {localStorage.getItem("feedbackAlreadySubmitted") !== "true" && (
          <Feedback />
        )}
        <YearFilter />
        <FiltersDialog />
        <Toaster position="top-center" />
        <ActiveShare isDesktop={isDesktop} />
      </main>
      <Footer />
      <MobileModalDropdown />
      <EthiopiaModel isVisible={showEthiopiaModal} handleClose={handleClose} />
      {mobileModalDropdown.isMobileModalDropdownOpen && (
        <div
          onClick={() =>
            mobileModalDropdown.isMobileModalDropdownOpen &&
            resetMobileModalDropdown({
              name: "mobileModalDropdown",
              value: null,
            })
          }
          tw="fixed top-0 bottom-0 w-full z-[1002]"></div>
      )}
    </>
  );
};

export default AfricaYouthEmployment;
