import Header from "components/ActiveShare/Inner/Header";
import { DataSharedProps } from "types/data-shared.types";
import { t } from "i18next";
import Info from "components/ActiveShare/Inner/Info";
import Chart from "components/Main/Trends/Chart";
import Legend from "components/Main/Trends/Legend";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Vertical = ({
    data,
    isShareModalVertical,
    isDesktop
}: {
    data: DataSharedProps;
    isShareModalVertical: boolean;
    isDesktop: boolean;
}) => (
    <>
        <Header
            isShareModalVertical={isShareModalVertical}
            isDesktop={isDesktop}
        />
        <main tw="p-0 h-[calc(100% - 33px)]">
            <section tw="p-[0.25rem 0.5rem] flex flex-col justify-between h-full">
                <Info isShareModalVertical={isShareModalVertical} data={data} />
                <div tw="border-t pt-1 border-t-grey-100 flex flex-col text-[0.6rem] gap-1">
                    <p tw="text-grey-800 font-medium">
                        {!data.disaggregated && !data.sector
                            ? `Total ${t(`${data.status}.title`)}`
                            : t(
                                  data.disaggregated
                                      ? `${data.status}.trends.title${
                                            data.status === "Employed" ||
                                            data.status === "Unemployed"
                                                ? `_${data.disaggregated}`
                                                : ""
                                        }`
                                      : `${data.status}.title`
                              )}
                    </p>
                    <p tw="text-grey-400">
                        {t(
                            `${data.status}.trends.subTitle${
                                data.status === "Employed" &&
                                data.disaggregated === "jobs"
                                    ? `_jobs`
                                    : ""
                            }`
                        )}
                    </p>
                    <div tw="gap-[0.5rem] flex flex-col" className="scrollable">
                        <Chart
                            isFromShare={true}
                            isShareModalVertical={isShareModalVertical}
                            chartData={data.data}
                            isDesktop={isDesktop}
                        />
                        <Legend
                            isFromShare={true}
                            isShareModalVertical={isShareModalVertical}
                            isDesktop={isDesktop}
                        />
                    </div>
                </div>
                <p tw="text-[0.5rem] text-grey-400 my-1">
                    https://africayouthjobs.io/
                </p>
            </section>
        </main>
    </>
);

export default Vertical;
