import females from "assets/images/females.svg";
import males from "assets/images/males.svg";
import { t } from "i18next";
import { Fragment } from "react";
import { DataSharedGendersProps } from "types/data-shared.types";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { itemsPerSlide } from "utils/utilities";

const CarouselItems = ({ items }: { items: DataSharedGendersProps[] }) => (
    <div tw="flex gap-2">
        {items
            .filter((notUndefinedItem) => notUndefinedItem!)
            .map(({ name, population, percentage, genderPercentageMap }) => (
                <Fragment key={name}>
                    <div tw="flex flex-col items-center gap-[0.25rem] flex-1">
                        <div tw="flex flex-col items-center">
                            <div tw="flex gap-[0.5rem] justify-center items-center">
                                <DynamicSvg iconName="population" size={16} />
                                <span tw="text-grey-600 font-semiBold text-xs">
                                    {population.toLocaleString()}
                                </span>
                            </div>
                            <span tw="text-grey-500 text-[0.56rem]">
                                {percentage}%
                            </span>
                        </div>
                        <div
                            css={[
                                tw`flex justify-center items-end gap-[0.3rem] h-[8rem]`,
                                items.length > itemsPerSlide && tw`h-[6rem]`
                            ]}
                        >
                            <div tw="flex flex-col items-center h-[80%] justify-end	">
                                <span tw="text-[0.56rem] text-green-600 font-semiBold">
                                    {genderPercentageMap.male}%
                                </span>
                                <span
                                    css={[
                                        tw`block bg-green-600 w-[4rem] rounded-[0.25rem 0.25rem 0 0]`,
                                        items.length > itemsPerSlide &&
                                            tw`w-[2rem]`
                                    ]}
                                    style={{
                                        flex: `0 0 ${genderPercentageMap.male}%`
                                    }}
                                ></span>
                                <img tw="w-4" src={males} alt="Males" />
                            </div>
                            <div tw="flex flex-col items-center h-[80%] justify-end	">
                                <span tw="text-[0.56rem] text-yellow-500 font-semiBold">
                                    {genderPercentageMap.female}%
                                </span>
                                <span
                                    css={[
                                        tw`block bg-yellow-500 w-[4rem] rounded-[0.25rem 0.25rem 0 0]`,
                                        items.length > itemsPerSlide &&
                                            tw`w-[2rem]`
                                    ]}
                                    style={{
                                        flex: `0 0 ${genderPercentageMap.female}%`
                                    }}
                                ></span>
                                <img tw="w-4" src={females} alt="Females" />
                            </div>
                        </div>
                        <div
                            tw={
                                "text-center flex justify-between items-center gap-4 text-xs text-grey-600"
                            }
                        >
                            <img
                                tw="w-4"
                                alt={name}
                                src={`assets/images/sectors_smalls/${name.toLowerCase()}.svg`}
                            />
                            <h4>{t(name)}</h4>
                        </div>
                    </div>
                    <span tw="bg-grey-100 h-[189px] w-[1px] last-of-type:hidden"></span>
                </Fragment>
            ))}
    </div>
);

export default CarouselItems;
