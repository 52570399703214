import { HelpUsItems } from "types/help-us.types";

export const feedbackIcons: HelpUsItems[] = [
    {
        name: "help_us_get_better_feeling_very_good",
        color: "#458461",
        question: "help_us_get_better_very_good_question",
        id: "very_good"
    },
    {
        name: "help_us_get_better_feeling_good",
        color: "#58BF86",
        question: "help_us_get_better_good_question",
        id: "feeling_good"
    },
    {
        name: "help_us_get_better_feeling_neutral",
        color: "#EDB347",
        question: "help_us_get_better_neutral_question",
        id: "feeling_neutral"
    },
    {
        name: "help_us_get_better_feeling_bad",
        color: "#EE8A88",
        question: "help_us_get_better_bad_question",
        id: "feeling_bad"
    },
    {
        name: "help_us_get_better_feeling_very_bad",
        color: "#CF4644",
        question: "help_us_get_better_very_bad_question",
        id: "very_bad"
    }
];
