import Vertical from "components/ActiveShare/Inner/Trends/Container/Vertical";
import Horizontal from "components/ActiveShare/Inner/Trends/Container/Horizontal";
import { useAfricanYouth } from "context/africanYouth";
import useFetch from "hooks/useFetch";
import { urls } from "config/urls";
import { getCountryId } from "utils/countries";
import { getGender, getDisaggregated } from "utils/filters";

const Container = ({
  isShareModalVertical,
  isDesktop,
}: {
  isShareModalVertical: boolean;
  isDesktop: boolean;
}) => {
  const {
    country,
    disaggregated,
    status,
    ages,
    gender,
    trendsEmployedSector,
    year,
  } = useAfricanYouth();

  const { data } = useFetch(
    `${urls.API_HOST_URL}trends?countryCode=${getCountryId(
      country.trends_section || ""
    )}&ageFrom=${ages.from || 15}&ageTo=${ages.to || 35}${
      gender !== "all" ? `&gender=${getGender(gender)}` : ""
    }${
      disaggregated ? `&disaggregatedBy=${getDisaggregated(disaggregated)}` : ""
    }${status ? `&sector=${status.toLocaleUpperCase()}` : ""}${
      trendsEmployedSector &&
      ["Kenya", "Rwanda", "Ghana", "Ethiopia", "Nigeria", "Uganda"].includes(
        country.trends_section!
      )
        ? `&sectorGroup=${
            trendsEmployedSector.charAt(0).toUpperCase() +
            trendsEmployedSector.slice(1)
          }`
        : ""
    }`,
    !country.trends_section || !status
  );

  return data ? (
    !isDesktop || isShareModalVertical ? (
      <Vertical
        data={{
          data,
          country: country.trends_section!,
          year,
          ages: [ages.from!, ages.to!],
          gender,
          disaggregated,
          status,
          sector: trendsEmployedSector,
        }}
        isShareModalVertical={isShareModalVertical}
        isDesktop={isDesktop}
      />
    ) : (
      <Horizontal
        data={{
          data,
          country: country.trends_section!,
          year,
          ages: [ages.from!, ages.to!],
          gender,
          disaggregated,
          status,
          sector: trendsEmployedSector,
        }}
        isShareModalVertical={isShareModalVertical}
        isDesktop={isDesktop}
      />
    )
  ) : null;
};

export default Container;
