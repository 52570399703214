import Menu from "components/Header/Navbar";
import logo from "assets/images/logo.svg";
import menu from "assets/images/menu.svg";
import { t } from "i18next";
import useToggle from "hooks/useToggle";
import { useAfricanYouth } from "context/africanYouth";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Header = () => {
    const { isSharedModalOpen } = useAfricanYouth();
    const { handleToggleState, toggleState } = useToggle();

    return (
        <header
            css={[
                tw`max-w-[110rem] m-auto flex items-center justify-between p-[1.219rem 4rem] sm:p-[1rem] gap-[0 3rem] bg-white box-border shadow fixed top-0 z-[1001] right-0 left-0 flex-wrap`,
                isSharedModalOpen && tw`z-0`
            ]}
        >
            <div tw="flex items-center gap-[1rem]">
                <img src={logo} alt="Social Protection Clock" />
                <h1 tw="font-regular text-[0.625rem] uppercase text-green-700">
                    {t("title")}
                    <span tw="text-yellow-700 block">{t("sub_title")}</span>
                </h1>
            </div>
            <button
                tw="lg:block hidden"
                onClick={() => handleToggleState("isNavbarOpened")}
            >
                <img src={menu} alt="Menu" />
            </button>
            <Menu
                handleToggleState={handleToggleState}
                toggleState={toggleState}
            />
        </header>
    );
};

export default Header;
