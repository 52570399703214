import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const PieChart = ({
    data,
    colors,
    width
}: {
    colors: string[];
    data: any;
    width?: number;
}) => {
    const chartOptions = {
        chart: {
            height: 225,
            type: "pie",
            width,
            backgroundColor: "transparent"
        },
        tooltip: {
            enabled: false
        },
        credits: false,
        title: false,
        labels: {
            style: {
                fontFamily: "Work Sans",
                fontSize: "30px",
                fontWeight: 400
            }
        },
        plotOptions: {
            pie: {
                colors: colors,
                style: {
                    fontFamily: "Work Sans",
                    fontSize: "13px",
                    fontWeight: 400
                },
                borderWidth: 0,
                borderRadius: 0
            },
            series: {
                dataLabels: {
                    enabled: true,
                    style: {
                        fontFamily: "Work Sans",
                        fontSize: "13px",
                        fontWeight: 600,
                        background: "transparent",
                        shadow: false,
                        borderWidth: 0
                    }
                },
                states: {
                    tooltip: {
                        enabled: false
                    },
                    inactive: {
                        opacity: 1
                    }
                }
            }
        },
        series: [
            {
                data
            }
        ]
    };

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default PieChart;
