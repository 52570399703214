import { theme } from "twin.macro";
import { LatLngTuple } from "leaflet";
import { getCountryId } from "./countries";

export const centerMap: LatLngTuple = [0, 20];
export const kenyaCenterMap: LatLngTuple = [-0.023559, 37.906193];
export const rwandaCenterMap: LatLngTuple = [-1.9437057, 29.8805778];
export const defaultMapZoom: number = 3;

export const countryStyle = (country: any, isTigray: boolean) => ({
  fillColor: isTigray
    ? theme`colors.grey.200`
    : country.subnationals
    ? theme`colors.orange.700`
    : country.areSubnationalsComing
    ? theme`colors.orange.500`
    : theme`colors.orange.300`,
  fillOpacity: 1,
  weight: 0.4,
  color: theme`colors.grey.600`,
});

export const areSubnationalsAvailable = (
  mapCoords: any,
  activeCountry: string
): boolean =>
  mapCoords.find(
    (country: any) => country.iso3c === getCountryId(activeCountry)
  )?.subnationals;

export const isTigrayAfter2020 = (regionName: string, year: number): boolean =>
  regionName === "Tigray" && year > 2020;
