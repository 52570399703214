import useFetch from "hooks/useFetch";
import { urls } from "config/urls";
import { getCountryId } from "utils/countries";
import Loader from "components/Loader";
import { Fragment, useCallback, useEffect } from "react";
import { t } from "i18next";
import useToggle from "hooks/useToggle";
import { useAfricanYouth } from "context/africanYouth";
import InnerSector from "components/Main/Sectors/InnerSector";
import Tooltip from "components/Main/Sectors/Tooltip";
import females from "assets/images/females.svg";
import males from "assets/images/males.svg";
import arrow from "assets/images/arrowOpen.svg";
import arrow_back_inner from "assets/images/arrow_back_inner.svg";
import { formatNumber } from "utils/formatting";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Table = ({
    data
}: {
    data: {
        name: string;
        population: 0;
        percentage: 0;
        genderPercentageMap: {
            male: 0;
            female: 0;
        };
    }[];
}) => {
    const {
        activeSummaryGender,
        setFilters,
        year,
        ages,
        country,
        regionGendersSection,
        genderSector,
        genderStatus
    } = useAfricanYouth();
    const { handleToggleState } = useToggle();

    const {
        data: sectorsData,
        isLoading,
        hasError,
        clearData
    } = useFetch(
        `${urls.API_HOST_URL}sectors/${genderSector}?year=${year}&ageFrom=${
            ages.from || 15
        }&ageTo=${ages.to || 35}&countryCode=${getCountryId(
            country.sectors_section || ""
        )}${regionGendersSection ? `&region=${regionGendersSection}` : ""}`,
        !genderSector
    );

    const handleActiveSummaryGender = useCallback(
        (action: string, item: any) => {
            setFilters({
                name: "activeSummaryGender",
                value: item
            });
            handleToggleState(action);
        },

        [handleToggleState, setFilters]
    );

    useEffect(() => {
        !genderSector && clearData();
        hasError && setFilters({ name: "genderSector", value: null });
    }, [genderSector, hasError, clearData, setFilters]);

    return (
        <article
            css={[
                tw`sm:border-t sm:border-t-grey-200 rounded-[0.5rem] sm:border-b-0 sm:border-0 border border-grey-200 bg-white mx-auto sm:bg-grey-50 text-center gap-[2rem] p-[1rem] flex justify-between items-start overflow-auto pb-0 md:mb-4`,
                genderSector && tw`flex-col gap-0`
            ]}
        >
            {genderSector ? (
                !sectorsData || isLoading ? (
                    <div tw="m-[4rem]">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div tw="grid grid-cols-[repeat(3, 1fr)] items-center sm:flex sm:justify-between font-semiBold w-full">
                            <button
                                onClick={() =>
                                    setFilters({
                                        name: "genderSector",
                                        value: null
                                    })
                                }
                                tw="items-center text-orange-700 gap-[0.625rem] uppercase flex font-semiBold"
                            >
                                <img src={arrow_back_inner} alt="Arrow" />
                                {t("back")}
                            </button>
                            <div tw="flex items-center flex-col sm:items-end">
                                <div tw="flex gap-[0.5rem] text-grey-400">
                                    <img
                                        src={`assets/images/sectors/${sectorsData.sectorGroup}.svg`}
                                        alt={sectorsData.sectorGroup}
                                    />
                                    <h4>{sectorsData.sectorGroup}</h4>
                                </div>
                                <div tw="flex items-center gap-[0.5rem]">
                                    <span tw="text-grey-600">
                                        {sectorsData.totalPopulation.toLocaleString()}
                                    </span>
                                    <span tw="text-grey-500 text-[0.813rem] font-regular">
                                        {formatNumber(sectorsData.populationPercentage)}
                                        %
                                    </span>
                                </div>
                            </div>
                        </div>
                        <InnerSector
                            activeSummaryGender={activeSummaryGender}
                            handleActiveSummaryGender={
                                handleActiveSummaryGender
                            }
                            sectorsData={sectorsData}
                        />
                    </>
                )
            ) : (
                <div
                    tw="overflow-x-auto flex pb-[1rem] w-full"
                    className="scrollable"
                >
                    {data?.map((sector, i) => (
                        <Fragment
                            key={sector.name ? sector.name : genderStatus}
                        >
                            <div tw="w-full relative border-r border-r-grey-100 last-of-type:border-0 p-[1rem]">
                                {activeSummaryGender?.id === i && (
                                    <Tooltip
                                        activeSummaryGender={
                                            activeSummaryGender
                                        }
                                    />
                                )}
                                <div tw="flex flex-col">
                                    <div tw="flex gap-[0.5rem] justify-center">
                                        <DynamicSvg iconName="population" />
                                        <span tw="text-grey-600 font-semiBold">
                                            {sector.population.toLocaleString()}
                                        </span>
                                    </div>
                                    <span tw="text-grey-500 text-[0.813rem] pb-[0.75rem]">
                                        {formatNumber(sector.percentage)}%
                                    </span>
                                </div>
                                <div tw="flex justify-center items-end gap-[0.3rem] h-[8rem]">
                                    <button
                                        tw="flex flex-col items-center h-[80%] justify-end	"
                                        onMouseEnter={() =>
                                            handleActiveSummaryGender("males", {
                                                id: i,
                                                name: "Male",
                                                number: (
                                                    sector.population *
                                                    (sector.genderPercentageMap
                                                        .male /
                                                        100)
                                                ).toLocaleString(),
                                                percentage:
                                                    sector.genderPercentageMap.male.toFixed(
                                                        1
                                                    )
                                            })
                                        }
                                        onMouseLeave={() =>
                                            handleActiveSummaryGender(
                                                "males",
                                                null
                                            )
                                        }
                                    >
                                        <span tw="text-[0.813rem] text-green-600 font-semiBold">
                                            {formatNumber(sector.genderPercentageMap.male)}%
                                        </span>
                                        <span
                                            tw="block bg-green-600 w-[6rem] lg:w-[4rem] md:w-[2rem]  rounded-[0.25rem 0.25rem 0 0]"
                                            style={{
                                                flex: `0 0 ${sector.genderPercentageMap.male}%`
                                            }}
                                        ></span>
                                        <img src={males} alt="Males" />
                                    </button>
                                    <button
                                        tw="flex flex-col items-center h-[80%] justify-end	"
                                        onMouseEnter={() =>
                                            handleActiveSummaryGender(
                                                "females",
                                                {
                                                    id: i,
                                                    name: "Female",
                                                    number: (
                                                        sector.population *
                                                        (sector
                                                            .genderPercentageMap
                                                            .female /
                                                            100)
                                                    ).toLocaleString(),
                                                    percentage:
                                                        sector.genderPercentageMap.female.toFixed(
                                                            1
                                                        )
                                                }
                                            )
                                        }
                                        onMouseLeave={() =>
                                            handleActiveSummaryGender(
                                                "females",
                                                null
                                            )
                                        }
                                    >
                                        <span tw="text-[0.813rem] text-yellow-500 font-semiBold">
                                            {formatNumber(sector.genderPercentageMap.female)}%
                                        </span>
                                        <span
                                            tw="block bg-yellow-500 w-[6rem] lg:w-[4rem] md:w-[2rem] rounded-[0.25rem 0.25rem 0 0]"
                                            style={{
                                                flex: `0 0 ${sector.genderPercentageMap.female}%`
                                            }}
                                        ></span>
                                        <img src={females} alt="Females" />
                                    </button>
                                </div>
                                {sector.name && (
                                    <>
                                        <div
                                            tw={
                                                "justify-center grid grid-flow-col gap-2"
                                            }
                                        >
                                            <div tw="flex justify-between gap-[1rem] items-center sm:flex-col">
                                                <img
                                                    alt={sector.name}
                                                    src={`assets/images/sectors/${sector.name.toLowerCase()}.svg`}
                                                />
                                                <h4 tw="text-grey-600 text-sm py-[0.25rem] text-left">
                                                    {t(sector.name)}
                                                </h4>
                                            </div>
                                        </div>{" "}
                                        <button
                                            onClick={() =>
                                                setFilters({
                                                    name: "genderSector",
                                                    value: sector.name
                                                })
                                            }
                                            tw="gap-[0.625rem] text-orange-700 font-semiBold uppercase flex items-center m-[1rem auto 0]"
                                        >
                                            {t("open")}
                                            <img src={arrow} alt="Arrow" />
                                        </button>
                                    </>
                                )}
                            </div>
                        </Fragment>
                    ))}
                </div>
            )}
        </article>
    );
};

export default Table;
