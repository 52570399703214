import { t } from "i18next";
import Header from "components/Main/Hero/Header";
import SituationsData from "components/Main/Hero/SituationsData";
import { useAfricanYouth } from "context/africanYouth";
import useFetch from "hooks/useFetch";
import { urls } from "config/urls";
import { getCountryId } from "utils/countries";
import useTicking from "hooks/useTicking";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";

const HeroSection = () => {
    const { country, year } = useAfricanYouth();

    const { data } = useFetch(
        `${urls.API_HOST_URL}main-hero?year=${year}${
            country.hero_section && country.hero_section !== "Africa"
                ? `&countryCode=${getCountryId(country.hero_section)}`
                : ""
        }`,
        !country.hero_section
    );

    const { tickingValue } = useTicking(data?.youthTotalNumber);

    return (
        <section
            tw="max-w-[110rem] m-auto h-[calc(100vh - 7.7rem)] flex items-center md:h-auto bg-grey-50 border-b border-b-grey-100 relative md:block"
            id="home"
        >
            <article tw="p-[2.5rem 0 1rem 4rem] lg:p-[2.5rem 4rem 1rem] sm:p-[1rem 0 1rem 1rem] flex flex-col justify-center gap-[2rem] relative z-[1] max-w-[max-content] h-[calc(100% - 4.8rem)] md:gap-[1rem]">
                <Header tickingValue={tickingValue} />
                <div tw="relative flex flex-col gap-[1rem] md:gap-0">
                    {data && !isNaN(Number(tickingValue)) && (
                        <SituationsData
                            tickingValue={Number(tickingValue)}
                            data={[
                                {
                                    name: "Employed",
                                    value: data.employedNumber,
                                    percentage: data.employedPercentage,
                                    color: theme`colors.green.600`
                                },
                                {
                                    name: "Unemployed",
                                    value: data.unemployedNumber,
                                    percentage: data.unemployedPercentage,
                                    color: theme`colors.red.600`
                                },
                                {
                                    name: "Inactive",
                                    value: data.inactiveNumber,
                                    percentage: data.inactivePercentage,
                                    color: theme`colors.yellow.600`
                                },
                                {
                                    name: "Student",
                                    value: data.studentNumber,
                                    percentage: data.studentPercentage,
                                    color: theme`colors.blue.600`
                                }
                            ]}
                        />
                    )}
                    <h4 tw="md:mr-[1rem] gap-[1rem] text-xs text-grey-500 flex items-center justify-between p-[1rem 0 0.25rem] border-t border-t-grey-200 md:border-t-0 md:border-b md:border-b-orange-500 md:items-end">
                        {t("hero_section_bottom_sentence")}
                        <strong tw="text-[1.56rem] md:text-lg">
                            {" "}
                            {data?.totalNumber.toLocaleString()}
                        </strong>
                    </h4>
                </div>
            </article>
            <article></article>
            {country.hero_section === "Africa"  || country.hero_section === null ? (
                <video
                    src="assets/video/landing_page_video.mp4"
                    autoPlay
                    muted
                    loop
                    tw="absolute right-0 top-0 h-full"
                ></video>
            ) : (
                <img
                    src={`assets/images/heroSection/${country.hero_section}.png`}
                    alt="Country flag"
                    tw="object-center object-contain absolute right-0 top-0 h-full"
                />
            )}
        </section>
    );
};

export default HeroSection;
