import { t } from "i18next";
import { percentages } from "utils/employments";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const containerVariants = {
    no: tw`bg-yellow-600`,
    primary: tw`bg-yellow-400`,
    secondary: tw`bg-yellow-200`,
    tertiary: tw`bg-yellow-100`
};

const styles = {
    container: ({ variant = "no" }) => [
        tw`flex w-full`,
        containerVariants[variant as keyof typeof containerVariants]
    ]
};

const Table = ({
    data
}: {
    data: {
        sectorGroupName: string;
        educationLevels: {
            educationLevel: string;
            population: number;
            percentage: number;
        }[];
    }[];
}) => {
    return (
        <article tw="w-[15.5rem] m-auto">
            <div tw="flex flex-col gap-[1rem] relative m-[3rem 0 0] h-[13.31rem]">
                {percentages.map((_percentage) => (
                    <div
                        key={_percentage}
                        tw="border-b border-b-grey-100 first-of-type:mt-[-1.5rem]"
                    >
                        <label tw="p-[2.5rem 0] text-[0.68rem] text-grey-400">
                            {_percentage}
                        </label>
                    </div>
                ))}

                <div tw="flex justify-between w-[calc(100% - 8rem)] translate-x-0 left-[1.7rem] h-[13.81rem] absolute gap-[0.7rem] pr-[1rem]">
                    {data?.map((sector) => (
                        <div
                            key={sector.sectorGroupName}
                            tw="h-[10.31rem] text-yellow-800 font-medium mt-[-1.7rem]"
                        >
                            <img
                                src={`assets/images/sectors/${sector.sectorGroupName?.toLocaleLowerCase()}.svg`}
                                alt={`${sector.sectorGroupName} icon`}
                                tw="ml-[1.5rem] pb-[0.2rem] w-[1.5rem]"
                            />
                            {[...sector.educationLevels]
                                .reverse()
                                .map((levels) => (
                                    <div
                                        key={levels.percentage}
                                        css={[
                                            tw`p-[0.25rem] w-[8rem] text-[0.68rem] flex items-center justify-center gap-[0.5rem]`,
                                            {
                                                height: `${levels.percentage.toFixed()}%`
                                            },
                                            styles.container({
                                                variant: levels.educationLevel
                                            })
                                        ]}
                                    >
                                        <span>
                                            {levels.population.toLocaleString()}
                                        </span>
                                    </div>
                                ))}
                            <h4 tw="w-[4rem] text-center text-grey-600 mt-[0.5rem] text-[0.68rem]">
                                {t(sector.sectorGroupName)}
                            </h4>
                        </div>
                    ))}
                </div>
            </div>
        </article>
    );
};

export default Table;
