import { t } from "i18next";
import useToggle from "hooks/useToggle";
import clock from "assets/images/theClock.svg";
import methodology from "assets/images/methodology.svg";
import externalLink from "assets/images/externalLink.svg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Methodology = () => {
    const { handleToggleState, toggleState } = useToggle();

    return (
        <section
            css={[
                tw`border-t border-t-grey-200 px-[4rem] sm:px-[1rem] relative bg-grey-50 pb-[3rem]`,
                toggleState.isMethodologyArticleSelected && tw`sm:pb-[6rem]`
            ]}
            id="methodology"
        >
            <h2 tw="font-semiBold text-xl text-grey-600 p-[2rem 0 0.5rem] sm:px-[1rem]">
                {t("about_us_title")}
            </h2>
            <h3 tw="border-b border-b-grey-200 sm:pb-[2.063rem] p-[0 0 1rem] sm:px-[1rem] text-grey-500 text-[0.938rem]">
                <span tw="block w-[29rem] sm:w-full">
                    {t("about_us.description")}
                </span>
            </h3>
            <div tw="lg:px-[1rem] md:px-0 px-[4rem]">
                <div tw="flex justify-between items-center my-[2rem] gap-[1rem] max-w-[20rem] lg:max-w-none">
                    <button
                        tw="w-[9.875rem] uppercase font-medium text-sm border border-orange-600 py-[0.5rem] text-center rounded-[2.75rem] text-grey-500 focus:text-white focus:bg-orange-500 hover:text-white hover:bg-orange-500"
                        css={[
                            toggleState.isMethodologyArticleSelected
                                ? tw`text-grey-500 `
                                : tw`text-white bg-orange-500`
                        ]}
                        onClick={() =>
                            toggleState.isMethodologyArticleSelected &&
                            handleToggleState("isMethodologyArticleSelected")
                        }
                    >
                        {t("about_us.title")}
                    </button>
                    <button
                        tw="w-[9.875rem] uppercase font-medium text-sm border border-orange-600 py-[0.5rem] text-center rounded-[2.75rem] text-grey-500 focus:text-white focus:bg-orange-500 hover:text-white hover:bg-orange-500"
                        css={[
                            toggleState.isMethodologyArticleSelected &&
                                tw`text-white bg-orange-500`
                        ]}
                        onClick={() =>
                            !toggleState.isMethodologyArticleSelected &&
                            handleToggleState("isMethodologyArticleSelected")
                        }
                    >
                        {t("methodology.title")}
                    </button>
                </div>
                <div tw="lg:flex-col-reverse flex justify-between items-start md:font-regular font-medium text-[0.938rem] md:text-regular gap-[1rem]">
                    <div tw="lg:w-full w-full max-w-[40rem] lg:max-w-none flex flex-col gap-[1rem] leading-[1.4rem]">
                        <p>
                            {t(
                                toggleState.isMethodologyArticleSelected
                                    ? "methodology.text_1"
                                    : "about_us.text_1"
                            )}
                            {!toggleState.isMethodologyArticleSelected ? (
                                <a
                                    tw="text-orange-500"
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://mastercardfdn.org"
                                >
                                    {t("about_us.text_0")}
                                </a>
                            ) : (
                                ""
                            )}
                            {t(
                                !toggleState.isMethodologyArticleSelected
                                    ? "about_us.text_12"
                                    : ""
                            )}
                        </p>
                        <p>
                            {t(
                                toggleState.isMethodologyArticleSelected
                                    ? "methodology.text_2"
                                    : "about_us.text_2"
                            )}
                            {!toggleState.isMethodologyArticleSelected ? (
                                <a
                                    tw="text-orange-500"
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://mastercardfdn.org"
                                >
                                    {t("about_us.text_0")}
                                </a>
                            ) : (
                                ""
                            )}
                            {t(
                                !toggleState.isMethodologyArticleSelected
                                    ? "about_us.text_22"
                                    : ""
                            )}
                        </p>
                        <p>
                            {t(
                                toggleState.isMethodologyArticleSelected
                                    ? "methodology.text_3"
                                    : ""
                            )}{" "}
                            <span>
                                <a
                                    href="mailto:hello@worlddata.io"
                                    tw="text-orange-500"
                                >
                                    {t(
                                        toggleState.isMethodologyArticleSelected
                                            ? "methodology.text_4"
                                            : ""
                                    )}
                                </a>
                            </span>
                        </p>
                        {toggleState.isMethodologyArticleSelected && (
                            <a
                                href="/Methodology.pdf"
                                target="_blank"
                                tw="text-left rounded-[2.75rem] bg-orange-100 font-semiBold text-sm text-orange-700 flex py-[0.6rem] w-[17.25rem] uppercase justify-center hover:bg-orange-500 hover:text-white"
                            >
                                {t("methodology.details_button")}
                                <img
                                    src={externalLink}
                                    alt="External link"
                                    tw="pl-[0.5rem]"
                                />
                            </a>
                        )}
                    </div>
                    <img
                        src={
                            toggleState.isMethodologyArticleSelected
                                ? methodology
                                : clock
                        }
                        alt="Methodology"
                        tw="sm:w-[15rem] lg:m-auto"
                    />
                </div>
            </div>
        </section>
    );
};

export default Methodology;
