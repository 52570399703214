import { SituationsType } from "types/situations.types";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const ConicGradient = ({ color, percentage }: SituationsType) => {
    const conicGradientStyle = `conic-gradient(${color} ${parseFloat(
        percentage as string
    )}%, transparent 0)`;

    return (
        <div tw="relative border border-grey-300 flex items-center justify-center w-[4rem] h-[4rem] rounded-[50%]">
            <span tw=" absolute w-[90%] h-[90%] bg-grey-50 md:bg-white z-10 rounded-[50%]"></span>
            <div
                className="pie-inner"
                tw="w-[4.5rem] h-[4.5rem] absolute rounded-[50%]"
                style={{
                    background: conicGradientStyle
                }}
            ></div>
        </div>
    );
};

export default ConicGradient;
