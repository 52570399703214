import { urls } from "config/urls";
import { useAfricanYouth } from "context/africanYouth";
import useFetch from "hooks/useFetch";
import { getCountryId } from "utils/countries";
import { handleToFixed, handleToLocaleString } from "utils/formatting";
import { Fragment, useMemo } from "react";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
import arrowOpen from "assets/images/arrowOpen.svg";
import { t } from "i18next";
import arrowLeft from "assets/images/arrowLeft.svg";
import PieChart from "components/Charts/Pie";
import Inner from "components/Main/Employment/Country/Situations/Inner";
import Sectors from "components/Main/Employment/Country/Situations/Sectors";
import { IndexKey } from "types/indexKey";
import Loader from "components/Loader";
import { getGender } from "utils/filters";
import { colorsPalette } from "utils/lists";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

const Statuses = ({ data, setTooltip, tooltip }: any) => {
    const {
        year,
        regionMapSection,
        ages,
        gender,
        country,
        mapEmployedSector,
        status,
        setFilters
    } = useAfricanYouth();

    const { data: sectorsData, isLoading } = useFetch(
        `${urls.API_HOST_URL}sectors/${mapEmployedSector}?year=${year}&${
            ages.from ? `&ageFrom=${ages.from}` : ""
        }${ages.to ? `&ageTo=${ages.to}` : ""}&countryCode=${getCountryId(
            country.employment_section!
        )}${regionMapSection ? `&region=${regionMapSection}` : ""}${
            gender !== "all" ? `&genderFilter=${getGender(gender)}` : ""
        }`,
        mapEmployedSector === null
    );

    const updatedStatusesData = useMemo(
        () => ({
            ...data,
            sectors: data.sectors.map((sector: any, i: number) => ({
                ...sector,
                data: new Map()
                    .set(`${sector.percentage}`, sector.percentage)
                    .entries()
                    .next().value,
                color: colorsPalette[i]
            })),
            innerGroups: data?.employmentBreakdown?.povertyExtremelyPoorEmployed
                ? [
                      {
                          name: "poor",
                          data: [
                              {
                                  name: "notPoor",
                                  value: handleToLocaleString(
                                      data.employmentBreakdown
                                          .povertyNotPoorEmployed
                                  ),
                                  percentage: handleToFixed(
                                      data.employmentBreakdown
                                          .povertyNotPoorPercentage
                                  ),
                                  color: theme`colors.yellow.600`
                              },
                              {
                                  name: "moderatelyPoor",
                                  value: handleToLocaleString(
                                      data.employmentBreakdown
                                          .povertyModeratelyPoorEmployed
                                  ),
                                  percentage: handleToFixed(
                                      data.employmentBreakdown
                                          .povertyModeratelyPoorPercentage
                                  ),
                                  color: theme`colors.yellow.500`
                              },
                              {
                                  name: "extremelyPoor",
                                  value: handleToLocaleString(
                                      data.employmentBreakdown
                                          .povertyExtremelyPoorEmployed
                                  ),
                                  percentage: handleToFixed(
                                      data.employmentBreakdown
                                          .povertyExtremelyPoorPercentage
                                  ),
                                  color: theme`colors.yellow.300`
                              }
                          ]
                      }
                  ]
                : null
        }),
        [data]
    );

    return isLoading && mapEmployedSector ? (
        <Loader />
    ) : mapEmployedSector && sectorsData ? (
        <Sectors data={sectorsData} />
    ) : (
        <div tw="flex flex-col gap-[1rem] shadow-[0 -0.25rem 0.25rem rgba(0, 0, 0, 0.1)] h-full p-[1rem] lg:p-[1.5rem 4rem] sm:p-[1rem]">
            <button
                onClick={() =>
                    setFilters({ name: "mapEmployedStatus", value: null })
                }
                tw="my-[0.5rem] flex items-center text-orange-700 uppercase font-semiBold text-sm gap-[0.5rem]"
            >
                <img src={arrowLeft} alt="Arrow left" />
                {t(`${status}.title`)}
            </button>
            {updatedStatusesData.employmentBreakdown && (
                <div tw="flex flex-col text-grey-600 font-semiBold justify-between border border-grey-100 rounded p-[0.5rem] gap-[0.25rem]">
                    <div tw="flex justify-between">
                        <div tw="flex justify-between gap-[1rem] items-center">
                            <img
                                alt={status}
                                src={`assets/images/status/${status}.svg`}
                            />
                            <h4 tw="text-grey-600 text-sm py-[0.25rem] text-left">
                                {t(`${status}.title`)}
                            </h4>
                        </div>
                        <div tw="flex justify-between items-center gap-[0.5rem] font-semiBold">
                            <DynamicSvg iconName="population" />
                            <p tw="text-grey-600">
                                {handleToLocaleString(
                                    updatedStatusesData.employmentBreakdown[
                                        `total${status}` as keyof IndexKey
                                    ]
                                )}
                            </p>
                            <span css={[tw`text-sm text-grey-500`]}>
                                {handleToFixed(
                                    updatedStatusesData.employmentBreakdown[
                                        `${status.toLowerCase()}Percentage` as keyof IndexKey
                                    ]
                                )}
                                %
                            </span>
                        </div>
                    </div>
                    <span tw="h-[1px] w-full bg-grey-100"></span>
                    {updatedStatusesData.innerGroups && (
                        <>
                            <Inner
                                tooltip={tooltip}
                                setTooltip={setTooltip}
                                data={updatedStatusesData.innerGroups}
                            />
                        </>
                    )}
                </div>
            )}
            <PieChart
                data={updatedStatusesData.sectors.map(({ data }: any) => data)}
                colors={updatedStatusesData.sectors.map(
                    ({ color }: any) => color
                )}
            />
            {updatedStatusesData.sectors.map(
                ({ name, percentage, color, population }: any) => (
                    <Fragment key={name}>
                        <div tw="border border-orange-300 hover:shadow-[0 0 0 0.1rem #F3BF7E] rounded p-[0.5rem] flex flex-col gap-[0.25rem]">
                            <div>
                                <div tw="flex justify-between items-center">
                                    <div tw="flex justify-between gap-[1rem] items-center">
                                        <img
                                            alt={name}
                                            src={`assets/images/sectors_smalls/${name.toLowerCase()}.svg`}
                                        />
                                        <h4 tw="text-grey-600 text-sm py-[0.25rem] text-left">
                                            {t(name)}
                                        </h4>
                                    </div>
                                    <div tw="flex justify-between items-center gap-[0.5rem] font-semiBold">
                                        <DynamicSvg iconName="population" />
                                        <p tw="text-grey-600">
                                            {handleToLocaleString(population)}
                                        </p>
                                        <span css={[tw`text-sm text-grey-500`]}>
                                            {handleToFixed(percentage)}%
                                        </span>
                                    </div>
                                </div>
                                <div tw="flex justify-between items-center pt-[0.6rem] pb-[0.5rem]">
                                    <span
                                        tw="block w-[1rem] h-[1rem] rounded-[50%] ml-[0.25rem]"
                                        style={{ backgroundColor: color }}
                                    ></span>
                                </div>
                            </div>
                            <button
                                onClick={() =>
                                    setFilters({
                                        name: "mapEmployedSector",
                                        value: name
                                    })
                                }
                                tw="flex ml-auto items-center text-orange-700 uppercase font-semiBold text-sm gap-[0.5rem]"
                            >
                                {t("open")}
                                <img src={arrowOpen} alt="Arrow open" />
                            </button>
                        </div>
                    </Fragment>
                )
            )}
        </div>
    );
};

export default Statuses;
