import useWindowResize from "hooks/useWindowResize";
import { useCallback, useMemo } from "react";
import useAutocomplete from "hooks/useAutocomplete";
import { t } from "i18next";
import { useAfricanYouth } from "context/africanYouth";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { AutoCompleteStyles } from "utils/autoComplete";
import ClearIcon from "assets/images/ClearIcon";
import { Country } from "types/country.types";
import { countries } from "utils/lists";
import { areSubnationalsAvailable } from "utils/leaflet";
import { getSubnationals } from "utils/filters";
import ToggleSwitch from "components/ToggleSwitch";
import Warning from "components/Warning";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";

const Filters = ({
  zoomToCountry,
  handleMapReset,
  areMapSubNationalsActive,
  handleToggleState,
}: any) => {
  const {
    country,
    mobileModalDropdown,
    setMobileModalDropdown,
    mapCoords,
    year,
    regionMapSection,
    setFilters,
  } = useAfricanYouth();
  const { handleAutocompleteChange } = useAutocomplete();
  const { isDesktop } = useWindowResize();

  const handleChange = useCallback(
    (newValue: string, reason: string, section: string) => {
      const selectedCountry = countries.find(({ name }) => name === newValue);

      handleAutocompleteChange(newValue, reason, section);

      reason === "clear" && section !== "regionMapSection"
        ? handleMapReset(section)
        : zoomToCountry(selectedCountry?.name || newValue);
    },
    [handleMapReset, zoomToCountry, handleAutocompleteChange]
  );

  const subNationalsList = useMemo(
    () => getSubnationals(country.employment_section!, year)!,
    [country.employment_section, year]
  );

  const memoCountryInput = useMemo(
    () => (
      <Autocomplete
        readOnly={isDesktop ? false : true}
        clearIcon={<ClearIcon />}
        autoHighlight
        options={countries}
        onChange={(_, value, reason) => {
          setFilters({
            value: null,
            name: "regionMapSection",
          });
          handleChange(value?.name!, reason, "employment_section");
        }}
        value={
          country.employment_section
            ? { name: country.employment_section }
            : (null as any)
        }
        tw="w-full"
        sx={AutoCompleteStyles}
        getOptionLabel={(option) => option.name}
        renderOption={(props: any, option: Country) => (
          <button
            {...props}
            style={{
              color: theme`colors.grey.700`,
              display: "flex",
              fontSize: "0.8rem",
              fontWeight: 700,
              gap: "0.65rem",
              width: "100%",
              textAlign: "left",
            }}>
            {option.file_url ? (
              <img
                loading="lazy"
                width="16"
                src={option.file_url}
                alt={option.name}
              />
            ) : (
              <span style={{ width: "1rem" }}></span>
            )}
            {option.name}
          </button>
        )}
        renderInput={(params: any) => {
          const activeCountryFlag = countries.find(
            ({ name }) => name === params.inputProps.value
          );

          return (
            <div
              tw="flex items-center relative"
              onClick={() =>
                isDesktop
                  ? undefined
                  : setMobileModalDropdown({
                      value: !mobileModalDropdown.isMobileModalDropdownOpen,
                      name: "mobileModalDropdown",
                      section: "countries/employment_section",
                    })
              }>
              {activeCountryFlag && (
                <img
                  key={params.inputProps.value + "'s flag"}
                  loading="lazy"
                  width="24"
                  src={activeCountryFlag.file_url}
                  alt="Country flag"
                  tw="absolute left-[0.5rem] z-10"
                />
              )}
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                sx={{
                  textAlign: "left",
                  paddingLeft: params.inputProps.value ? "2rem" : 0,
                  input: {
                    "&::placeholder": {
                      color: "#ADADAD !important",
                    },
                  },
                }}
                placeholder={t("type_or_select_a_region_placeholder")}
              />
            </div>
          );
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [country.employment_section, isDesktop, handleChange]
  );

  const memoSubnationalInput = useMemo(
    () => (
      <div tw="py-[1rem]">
        <label tw="text-grey-500 leading-[0.938rem] text-sm pb-[0.625rem]">
          {t("subregion")}
        </label>
        <Autocomplete
          readOnly={isDesktop ? false : true}
          clearIcon={<ClearIcon />}
          autoHighlight
          options={subNationalsList}
          onChange={(_, value, reason) =>
            handleChange(value?.name!, reason, "regionMapSection")
          }
          value={regionMapSection ? { name: regionMapSection } : (null as any)}
          sx={AutoCompleteStyles}
          getOptionLabel={(option) => option.name}
          renderOption={(props: any, option: any) => (
            <button
              {...props}
              style={{
                color: theme`colors.grey.700`,
                display: "flex",
                fontSize: "0.8rem",
                fontWeight: 700,
                gap: "0.65rem",
                width: "100%",
                textAlign: "left",
              }}>
              {t(option.name)}
            </button>
          )}
          renderInput={(params: any) => (
            <div
              tw="flex items-center relative"
              onClick={() =>
                isDesktop
                  ? undefined
                  : setMobileModalDropdown({
                      value: !mobileModalDropdown.isMobileModalDropdownOpen,
                      name: "mobileModalDropdown",
                      section: "regionMapSection",
                      data: subNationalsList,
                    })
              }>
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                sx={{
                  textAlign: "left",
                  paddingLeft: 0,
                  input: {
                    "&::placeholder": {
                      color: "#ADADAD !important",
                    },
                  },
                }}
                placeholder={t("type_or_select_a_subregion_placeholder")}
              />
            </div>
          )}
        />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regionMapSection, isDesktop, subNationalsList, handleChange]
  );

  return (
    <article>
      <div tw="flex gap-2 items-center">
        {memoCountryInput}
        <Warning countryName={country.employment_section!} />
      </div>
      {areSubnationalsAvailable(mapCoords, country.employment_section!) ? (
        <>
          <ToggleSwitch
            sectionName="areMapSubNationalsActive"
            isActive={areMapSubNationalsActive}
            handleToggleState={() => {
              areMapSubNationalsActive &&
                setFilters({
                  value: null,
                  name: "regionMapSection",
                });
              handleToggleState("areMapSubNationalsActive");
            }}
          />
        </>
      ) : null}
      {areSubnationalsAvailable(mapCoords, country.employment_section!) &&
        areMapSubNationalsActive &&
        subNationalsList &&
        memoSubnationalInput}
    </article>
  );
};

export default Filters;
