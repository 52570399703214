import { DataSharedProps } from "types/data-shared.types";
import Header from "components/ActiveShare/Inner/Header";
import Items from "components/ActiveShare/Inner/Genders/Container/Vertical/CarouselItems";
import Info from "components/ActiveShare/Inner/Info";
import Carousel from "react-material-ui-carousel";
import { itemsPerSlide, splitItems } from "utils/utilities";
import useActiveSlide from "hooks/useActiveSlide";
import Pages from "components/ActiveShare/Inner/Pages";
import arrowCarousel from "assets/images/arrow-carousel.svg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Vertical = ({
    data,
    isShareModalVertical,
    isDesktop
}: {
    data: DataSharedProps;
    isShareModalVertical: boolean;
    isDesktop: boolean;
}) => {
    const { activeSlide, handleActiveSlide } = useActiveSlide();

    return (
        <>
            <Header
                isShareModalVertical={isShareModalVertical}
                isDesktop={isDesktop}
            />
            <main tw="p-0 h-[calc(100% - 33px)]">
                <section tw="p-[0.5rem] flex flex-col h-full gap-4">
                    <Info
                        isShareModalVertical={isShareModalVertical}
                        data={data}
                    />
                    <article>
                        {data.data.length > itemsPerSlide ? (
                            <>
                                <Carousel
                                    onChange={(now) => handleActiveSlide(now!)}
                                    sx={{
                                        height: "18.375rem",
                                        overflow: "visible"
                                    }}
                                    autoPlay={false}
                                    navButtonsAlwaysVisible={true}
                                    indicators={false}
                                    navButtonsWrapperProps={{
                                        style: {
                                            bottom: "-76px",
                                            top: "unset",
                                            height: "40px"
                                        }
                                    }}
                                    navButtonsProps={{
                                        style: {
                                            backgroundColor: "transparent",
                                            padding: 0,
                                            margin: 0
                                        }
                                    }}
                                    NextIcon={
                                        <img
                                            css={[
                                                tw`rotate-180 w-[40px]`,
                                                activeSlide ===
                                                    splitItems(
                                                        data.data,
                                                        itemsPerSlide
                                                    ).length && tw`hidden`
                                            ]}
                                            alt="Next"
                                            src={arrowCarousel}
                                        />
                                    }
                                    PrevIcon={
                                        <img
                                            tw="w-[40px]"
                                            alt="Prev"
                                            src={arrowCarousel}
                                            css={[
                                                activeSlide === 1 && tw`hidden`
                                            ]}
                                        />
                                    }
                                >
                                    {splitItems(data.data, itemsPerSlide).map(
                                        (items: any, i: number) => (
                                            <Items
                                                key={data.data[i]?.name}
                                                items={items}
                                            />
                                        )
                                    )}
                                </Carousel>
                                <div tw="flex items-center justify-between">
                                    <p tw="text-[0.5rem] text-grey-400">
                                        https://africayouthjobs.io/
                                    </p>
                                    <Pages
                                        activeSlide={activeSlide}
                                        itemsLength={
                                            splitItems(data.data, itemsPerSlide)
                                                .length
                                        }
                                    />
                                </div>
                            </>
                        ) : (
                            <Items items={data.data} />
                        )}
                        {splitItems(data.data, itemsPerSlide).length === 1 && (
                            <p tw="text-[0.5rem] text-grey-400 mt-2">
                                https://africayouthjobs.io/
                            </p>
                        )}
                    </article>
                </section>
            </main>
        </>
    );
};

export default Vertical;
