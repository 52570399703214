import { DynamicSvgProps } from "types/dynamic-svg.types";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const DynamicSvg = ({
    iconName,
    primaryColor,
    secondaryColor,
    size
}: DynamicSvgProps) => {
    return (
        <span>
            {
                {
                    population: (
                        <svg
                            width={size ? size : 24}
                            height={size ? size : 24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_1986_27809)">
                                <path
                                    d="M9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12ZM9 7C9.83 7 10.5 7.67 10.5 8.5C10.5 9.33 9.83 10 9 10C8.17 10 7.5 9.33 7.5 8.5C7.5 7.67 8.17 7 9 7ZM9 13.75C6.66 13.75 2 14.92 2 17.25V18C2 18.55 2.45 19 3 19H15C15.55 19 16 18.55 16 18V17.25C16 14.92 11.34 13.75 9 13.75ZM4.34 17C5.18 16.42 7.21 15.75 9 15.75C10.79 15.75 12.82 16.42 13.66 17H4.34ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H21C21.55 19 22 18.55 22 18V17.25C22 15.23 18.5 14.08 16.04 13.81ZM15 12C16.93 12 18.5 10.43 18.5 8.5C18.5 6.57 16.93 5 15 5C14.46 5 13.96 5.13 13.5 5.35C14.13 6.24 14.5 7.33 14.5 8.5C14.5 9.67 14.13 10.76 13.5 11.65C13.96 11.87 14.46 12 15 12Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1986_27809">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    employed: (
                        <svg
                            width={size ? size : 24}
                            height={size ? size : 24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_1986_27825)">
                                <path
                                    d="M14 6V4H10V6H14ZM4 8V19H20V8H4ZM20 6C21.11 6 22 6.89 22 8V19C22 20.11 21.11 21 20 21H4C2.89 21 2 20.11 2 19L2.01 8C2.01 6.89 2.89 6 4 6H8V4C8 2.89 8.89 2 10 2H14C15.11 2 16 2.89 16 4V6H20Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1986_27825">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    inactive: (
                        <svg
                            width={size ? size : 24}
                            height={size ? size : 24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_1986_27873)">
                                <path
                                    d="M14 6V4H10V6H14ZM4 8V19H20V8H4ZM20 6C21.11 6 22 6.89 22 8V19C22 20.11 21.11 21 20 21H4C2.89 21 2 20.11 2 19L2.01 8C2.01 6.89 2.89 6 4 6H8V4C8 2.89 8.89 2 10 2H14C15.11 2 16 2.89 16 4V6H20Z"
                                    fill="#929292"
                                />
                                <rect
                                    x="11"
                                    y="17"
                                    width="2"
                                    height="7"
                                    transform="rotate(-180 11 17)"
                                    fill="#929292"
                                />
                                <rect
                                    x="15"
                                    y="17"
                                    width="2"
                                    height="7"
                                    transform="rotate(-180 15 17)"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1986_27873">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    student: (
                        <svg
                            width={size ? size : 24}
                            height={size ? size : 24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_1986_27849)">
                                <path
                                    flip-rule="evenodd"
                                    clipRule="evenodd"
                                    d="M4 11.9091L12 17L23 10L12 3L1 10L2 10.6364V16H4V11.9091ZM6.29669 11H12V9H6.29669L12 5.37062L19.2747 10L12 14.6294L6.29669 11Z"
                                    fill="#929292"
                                />
                                <path
                                    d="M5 14H7V17.6478C7.30411 17.7986 7.73011 17.9959 8.23739 18.1931C9.30864 18.6096 10.6764 19 12 19C13.3236 19 14.6914 18.6096 15.7626 18.1931C16.2699 17.9959 16.6959 17.7986 17 17.6478V14H19V18.8247L18.5038 19.1141C18.5038 19.1141 18.5038 19.1141 18 18.2503L18.5038 19.1141L18.5018 19.1153L18.4987 19.1171L18.4891 19.1226L18.4571 19.1408C18.4301 19.156 18.392 19.1773 18.3433 19.2038C18.2461 19.2567 18.1069 19.3305 17.9315 19.4181C17.5814 19.5932 17.0841 19.8252 16.4874 20.0572C15.3086 20.5155 13.6764 21 12 21C10.3236 21 8.69136 20.5155 7.51261 20.0572C6.91594 19.8252 6.4186 19.5932 6.06847 19.4181C5.89311 19.3305 5.75386 19.2567 5.65666 19.2038C5.60804 19.1773 5.56987 19.156 5.54289 19.1408L5.51089 19.1226L5.50135 19.1171L5.49822 19.1153L5.49707 19.1146C5.49707 19.1146 5.49619 19.1141 6 18.2503L5.49619 19.1141L5 18.8247V14Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1986_27849">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    unemployed: (
                        <svg
                            width={size ? size : 24}
                            height={size ? size : 24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_1986_27892)">
                                <path
                                    d="M9.99828 3.99997H13.9983V5.99997H10.3983L12.3983 7.99997H19.9983V15.6L21.9983 17.6V7.99997C21.9983 6.88997 21.1083 5.99997 19.9983 5.99997H15.9983V3.99997C15.9983 2.88997 15.1083 1.99997 13.9983 1.99997H9.99828C9.00828 1.99997 8.19828 2.69997 8.03828 3.63997L9.99828 5.59997V3.99997ZM3.39828 1.83997L1.98828 3.24997L4.73828 5.99997H3.99828C2.88828 5.99997 2.00828 6.88997 2.00828 7.99997L1.99828 19C1.99828 20.11 2.88828 21 3.99828 21H19.7383L21.7383 23L23.1483 21.59L3.39828 1.83997ZM3.99828 19V7.99997H6.73828L17.7383 19H3.99828Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1986_27892">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    failingPerson: (
                        <svg
                            tw="absolute top-[-8rem] left-[50%] animate-failingPerson translate-x-[-50%]"
                            width="45"
                            height="73"
                            viewBox="0 0 45 73"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16.3127 12.8825L18.5613 16.8267L22.5002 14.4928L25.3126 11.2721L24.3393 9.37004L22.4998 7.5L20.0703 7.24404L16.3126 8.05163L15.1877 10.1987L16.3127 12.8825Z"
                                fill="#929292"
                            />
                            <path
                                d="M15.75 15.5662L14.625 21.4706L11.25 20.3971L4.5 13.4191L10.6875 6.97794L14.625 8.58823L15.1875 8.05147L12.9375 6.44118L17.4375 3.75735L16.875 3.22059L14.0625 4.29412L16.3125 2.14706L15.1875 1.07353L12.375 3.22059L14.0625 0.536765L13.5 0L8.4375 4.29412L0 13.4191L9.5625 25.7647L12.375 37.0368L14.0625 52.0662L5.625 62.2647L3.375 63.3382L7.3125 69.2426L11.25 72.4632L12.375 71.3897L9.5625 67.6323L8.4375 63.875L18.5625 53.1397L19.125 43.4779L20.8125 42.9412L27.5625 49.3824L32.625 63.875L33.1875 67.0956L43.875 64.9485L43.3125 63.3382H38.25L34.875 62.8015L32.0625 48.3088L24.75 38.6471L21.375 32.7426L22.5 27.375H33.1875L34.875 16.1029L37.125 9.125L36.5625 8.58823L34.875 11.8088V8.05147L34 7.5L32.5 11L32 8H31L30.9375 13.4191L28.125 12.3456L27.5625 13.4192L31.5 16.1029L30.375 23.6176L22.5 23L19.125 22.5441V19.3235C21.375 19.8603 21.9375 19.8603 23.0625 16.6397C24.2557 13.2238 25.3125 11.8088 21.9375 10.1985C19.2375 8.91025 16.875 10.7353 16.3125 12.3456C15.1875 11.8088 14.625 12.8824 14.625 13.4191C14.625 13.9311 15 15.2083 15.75 15.5662Z"
                                fill="#6E4700"
                            />
                            <path
                                d="M13.4999 52.0662L12.3749 40.7942L19.1249 40.2574L24.7499 38.1104L32.6249 48.3089L35.9999 61.1912L33.7499 62.2648L30.9374 62.8015L26.9999 49.9192L20.8124 44.0148L19.6874 44.5515V53.1398L10.6874 63.3383L7.87488 62.2648L6.18738 60.1177L13.4999 52.0662Z"
                                fill={primaryColor}
                            />
                            <path
                                d="M16.875 22.0073L14.0625 20.9338L11.25 19.3235L9 16.6396L3.9375 20.397L9 26.3014L10.125 41.3308H19.125L27.5625 38.1102L23.0625 32.2058L23.625 28.4485H27V22.5441L20.25 22.0073H16.875Z"
                                fill={secondaryColor}
                            />
                            <path
                                d="M6.74988 63.8751L5.62488 61.728L2.24988 63.3383L6.74988 69.7795L11.8124 73.0001L12.9374 71.3898L10.1249 67.6324L9.49988 64.5L6.74988 63.8751Z"
                                fill={secondaryColor}
                            />
                            <path
                                d="M34.3126 63.8749H32.0626L32.6251 67.6323L45.0001 64.9485L43.3126 62.8014H38.8126L36.0001 62.2646L34.3126 63.8749Z"
                                fill={secondaryColor}
                            />
                        </svg>
                    ),
                    help_us_get_better_feeling_very_good: (
                        <svg
                            width={size}
                            height={size}
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="24.0007"
                                cy="24"
                                r="22.5"
                                fill={primaryColor}
                                stroke="#458461"
                                strokeWidth="3"
                            />
                            <path
                                d="M11.0007 26C11.0007 23.2386 13.2393 21 16.0007 21C18.7621 21 21.0007 23.2386 21.0007 26"
                                stroke={secondaryColor}
                                strokeWidth="3"
                            />
                            <path
                                d="M27.0007 26C27.0007 23.2386 29.2393 21 32.0007 21C34.7621 21 37.0007 23.2386 37.0007 26"
                                stroke={secondaryColor}
                                strokeWidth="3"
                            />
                            <path
                                d="M29.0007 35C29.0007 37.7614 26.7621 40 24.0007 40C21.2393 40 19.0007 37.7614 19.0007 35"
                                stroke={secondaryColor}
                                strokeWidth="3"
                            />
                        </svg>
                    ),
                    help_us_get_better_feeling_good: (
                        <svg
                            width={size}
                            height={size}
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="24.0007"
                                cy="24"
                                r="22.5"
                                fill={primaryColor}
                                stroke="#58BF86"
                                strokeWidth="3"
                            />
                            <path
                                d="M29.0007 35C29.0007 37.7614 26.7622 40 24.0007 40C21.2393 40 19.0007 37.7614 19.0007 35"
                                stroke={secondaryColor}
                                strokeWidth="3"
                            />
                            <circle
                                cx="16.5007"
                                cy="23.5"
                                r="3.5"
                                fill={secondaryColor}
                            />
                            <circle
                                cx="31.5007"
                                cy="23.5"
                                r="3.5"
                                fill={secondaryColor}
                            />
                        </svg>
                    ),
                    help_us_get_better_feeling_neutral: (
                        <svg
                            width={size}
                            height={size}
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="24.0007"
                                cy="24"
                                r="22.5"
                                fill={primaryColor}
                                stroke="#EDB347"
                                strokeWidth="3"
                            />
                            <circle
                                cx="16.5007"
                                cy="23.5"
                                r="3.5"
                                fill={secondaryColor}
                            />
                            <circle
                                cx="31.5007"
                                cy="23.5"
                                r="3.5"
                                fill={secondaryColor}
                            />
                            <path
                                d="M19.0007 36.9702H29.7743"
                                stroke={secondaryColor}
                                strokeWidth="3"
                            />
                        </svg>
                    ),
                    help_us_get_better_feeling_bad: (
                        <svg
                            width={size}
                            height={size}
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="24.0007"
                                cy="24"
                                r="22.5"
                                fill={primaryColor}
                                stroke="#EE8A88"
                                strokeWidth="3"
                            />
                            <path
                                d="M19.0007 40C19.0007 37.2386 21.2393 35 24.0007 35C26.7622 35 29.0007 37.2386 29.0007 40"
                                stroke={secondaryColor}
                                strokeWidth="3"
                            />
                            <circle
                                cx="16.5007"
                                cy="23.5"
                                r="3.5"
                                fill={secondaryColor}
                            />
                            <circle
                                cx="31.5007"
                                cy="23.5"
                                r="3.5"
                                fill={secondaryColor}
                            />
                        </svg>
                    ),
                    help_us_get_better_feeling_very_bad: (
                        <svg
                            width={size}
                            height={size}
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="24.0007"
                                cy="24"
                                r="22.5"
                                fill={primaryColor}
                                stroke="#CF4644"
                                strokeWidth="3"
                            />
                            <path
                                d="M21.0007 21C21.0007 23.7614 18.7622 26 16.0007 26C13.2393 26 11.0007 23.7614 11.0007 21"
                                stroke={secondaryColor}
                                strokeWidth="3"
                            />
                            <path
                                d="M37.0007 21C37.0007 23.7614 34.7622 26 32.0007 26C29.2393 26 27.0007 23.7614 27.0007 21"
                                stroke={secondaryColor}
                                strokeWidth="3"
                            />
                            <path
                                d="M19.0007 40C19.0007 37.2386 21.2393 35 24.0007 35C26.7622 35 29.0007 37.2386 29.0007 40"
                                stroke={secondaryColor}
                                strokeWidth="3"
                            />
                        </svg>
                    ),
                    "agriculture.title": (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_1873_39352)">
                                <path
                                    d="M12 20H2V18H7.75C7.02 15.19 4.81 12.99 2 12.26C2.64 12.1 3.31 12 4 12C8.42 12 12 15.58 12 20ZM22 12.26C21.36 12.1 20.69 12 20 12C17.07 12 14.52 13.58 13.12 15.93C13.41 16.59 13.65 17.28 13.79 18C13.92 18.65 13.99 19.32 13.99 20H15.99H21.99V18H16.24C16.98 15.19 19.19 12.99 22 12.26ZM15.64 11.02C16.42 8.93 17.87 7.18 19.73 6.02C15.44 6.16 12 9.67 12 14C12 14.01 12 14.02 12 14.02C12.95 12.75 14.2 11.72 15.64 11.02ZM11.42 8.85C10.58 6.66 8.88 4.89 6.7 4C8.14 5.86 9 8.18 9 10.71C9 10.92 8.97 11.12 8.96 11.32C9.39 11.56 9.79 11.84 10.18 12.14C10.39 10.96 10.83 9.85 11.42 8.85Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1873_39352">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    "services.title": (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_1873_39425)">
                                <path
                                    d="M22 19V20L14 22.5L7 20.56V22H1V11H8.97L15.13 13.3C16.25 13.72 17 14.8 17 16H19C20.66 16 22 17.34 22 19ZM5 20V13H3V20H5ZM19.9 18.57C19.74 18.24 19.39 18.01 19 18.01H13.65C13.11 18.01 12.58 17.92 12.07 17.75L9.69 16.96L10.32 15.06L12.7 15.85C13.01 15.95 15 16 15 16C15 15.63 14.77 15.3 14.43 15.17L8.61 13H7V18.48L13.97 20.41L19.9 18.57Z"
                                    fill="#929292"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M9.95 4C7.7475 4 6 5.75324 6 8.04167C6 8.42916 6.0461 8.80738 6.13636 9.18305L4.1917 9.65028C4.0657 9.12582 4 8.59083 4 8.04167C4 4.68343 6.6085 2 9.95 2C11.4506 2 12.8894 2.56601 14 3.50411C15.1106 2.56601 16.5494 2 18.05 2C21.3915 2 24 4.68343 24 8.04167C24 10.4891 22.7008 12.5639 20.7948 14.6709L19.3116 13.3291C21.1251 11.3245 22 9.72474 22 8.04167C22 5.75324 20.2525 4 18.05 4C16.7937 4 15.567 4.60062 14.7672 5.55726L14 6.47486L13.2328 5.55726C12.433 4.60062 11.2063 4 9.95 4Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1873_39425">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    "industry.title": (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M17.5 3C16.9477 3 16.5 3.44772 16.5 4V10.1315C16.5 10.9302 15.6099 11.4066 14.9453 10.9635L11.3047 8.53647C10.6401 8.09343 9.75 8.56982 9.75 9.36852V10.1315C9.75 10.9302 8.85985 11.4066 8.1953 10.9635L4.5547 8.53647C3.89015 8.09343 3 8.56982 3 9.36852V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V4C21 3.44772 20.5523 3 20 3H17.5ZM6.25 15C5.69772 15 5.25 15.4477 5.25 16V16.25C5.25 16.8023 5.69772 17.25 6.25 17.25H7.625C8.17728 17.25 8.625 16.8023 8.625 16.25V16C8.625 15.4477 8.17728 15 7.625 15H6.25ZM10.3125 16C10.3125 15.4477 10.7602 15 11.3125 15H12.6875C13.2398 15 13.6875 15.4477 13.6875 16V16.25C13.6875 16.8023 13.2398 17.25 12.6875 17.25H11.3125C10.7602 17.25 10.3125 16.8023 10.3125 16.25V16ZM16.375 15C15.8227 15 15.375 15.4477 15.375 16V16.25C15.375 16.8023 15.8227 17.25 16.375 17.25H17.75C18.3023 17.25 18.75 16.8023 18.75 16.25V16C18.75 15.4477 18.3023 15 17.75 15H16.375Z"
                                fill="#929292"
                            />
                        </svg>
                    ),
                    wholesales: (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_2369_45832)">
                                <path
                                    d="M19 6H17C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6H5C3.9 6 3 6.9 3 8V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V8C21 6.9 20.1 6 19 6ZM12 3C13.66 3 15 4.34 15 6H9C9 4.34 10.34 3 12 3ZM12 13C9.67 13 7.71 11.41 7.16 9.25C6.99 8.62 7.48 8 8.13 8C8.6 8 8.98 8.34 9.11 8.8C9.46 10.07 10.62 11 12 11C13.38 11 14.54 10.07 14.89 8.8C15.02 8.34 15.4 8 15.87 8C16.52 8 17 8.62 16.84 9.25C16.29 11.41 14.33 13 12 13Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2369_45832">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    transportation: (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_2369_45816)">
                                <path
                                    d="M19.5 8H17V6C17 4.9 16.1 4 15 4H3C1.9 4 1 4.9 1 6V15C1 16.1 1.9 17 3 17C3 18.66 4.34 20 6 20C7.66 20 9 18.66 9 17H15C15 18.66 16.34 20 18 20C19.66 20 21 18.66 21 17H22C22.55 17 23 16.55 23 16V12.67C23 12.24 22.86 11.82 22.6 11.47L20.3 8.4C20.11 8.15 19.81 8 19.5 8ZM6 18C5.45 18 5 17.55 5 17C5 16.45 5.45 16 6 16C6.55 16 7 16.45 7 17C7 17.55 6.55 18 6 18ZM19.5 9.5L21.46 12H17V9.5H19.5ZM18 18C17.45 18 17 17.55 17 17C17 16.45 17.45 16 18 16C18.55 16 19 16.45 19 17C19 17.55 18.55 18 18 18Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2369_45816">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    accommodation: (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                id="accommodation"
                                clipPath="url(#clip0_2369_45835)"
                            >
                                <path
                                    id="Vector"
                                    d="M7 13C8.66 13 10 11.66 10 10C10 8.34 8.66 7 7 7C5.34 7 4 8.34 4 10C4 11.66 5.34 13 7 13ZM19 7H13C11.9 7 11 7.9 11 9V14H3V6C3 5.45 2.55 5 2 5C1.45 5 1 5.45 1 6V19C1 19.55 1.45 20 2 20C2.55 20 3 19.55 3 19V17H21V19C21 19.55 21.45 20 22 20C22.55 20 23 19.55 23 19V11C23 8.79 21.21 7 19 7Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2369_45835">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    information: (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                id="information"
                                clipPath="url(#clip0_2369_45838)"
                            >
                                <path
                                    id="Vector"
                                    d="M20 6H19V14C19 14.55 18.55 15 18 15H6V16C6 17.1 6.9 18 8 18H18L22 22V8C22 6.9 21.1 6 20 6ZM17 11V4C17 2.9 16.1 2 15 2H4C2.9 2 2 2.9 2 4V17L6 13H15C16.1 13 17 12.1 17 11Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2369_45838">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    financial: (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                id="financial and isurance"
                                clipPath="url(#clip0_2369_45844)"
                            >
                                <path
                                    id="Vector"
                                    d="M15 16H18C18.55 16 19 15.55 19 15V9C19 8.45 18.55 8 18 8H15C14.45 8 14 8.45 14 9V15C14 15.55 14.45 16 15 16ZM16 10H17V14H16V10ZM9 16H12C12.55 16 13 15.55 13 15V9C13 8.45 12.55 8 12 8H9C8.45 8 8 8.45 8 9V15C8 15.55 8.45 16 9 16ZM10 10H11V14H10V10ZM6 8C5.45 8 5 8.45 5 9V15C5 15.55 5.45 16 6 16C6.55 16 7 15.55 7 15V9C7 8.45 6.55 8 6 8ZM2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6ZM19 18H5C4.45 18 4 17.55 4 17V7C4 6.45 4.45 6 5 6H19C19.55 6 20 6.45 20 7V17C20 17.55 19.55 18 19 18Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2369_45844">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    real: (
                        <svg
                            width="20"
                            height="17"
                            viewBox="0 0 20 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g id="Group">
                                <path
                                    id="Vector"
                                    d="M17.0001 6.3V2C17.0001 1.45 16.5501 1 16.0001 1H15.0001C14.4501 1 14.0001 1.45 14.0001 2V3.6L10.6701 0.600001C10.2901 0.260001 9.7101 0.260001 9.3301 0.600001L0.970098 8.13C0.630098 8.43 0.840098 9 1.3001 9H3.0001V16C3.0001 16.55 3.4501 17 4.0001 17H7.0001C7.5501 17 8.0001 16.55 8.0001 16V11H12.0001V16C12.0001 16.55 12.4501 17 13.0001 17H16.0001C16.5501 17 17.0001 16.55 17.0001 16V9H18.7001C19.1601 9 19.3801 8.43 19.0301 8.13L17.0001 6.3ZM8.0001 7C8.0001 5.9 8.9001 5 10.0001 5C11.1001 5 12.0001 5.9 12.0001 7H8.0001Z"
                                    fill="#929292"
                                />
                            </g>
                        </svg>
                    ),
                    professional: (
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g id="Group">
                                <path
                                    id="Vector"
                                    d="M15.8002 14.4L10.0002 6.67V2.5L11.3502 0.81C11.6102 0.48 11.3802 0 10.9602 0H5.04018C4.62018 0 4.39017 0.48 4.65018 0.81L6.00018 2.5V6.67L0.200175 14.4C-0.289825 15.06 0.180175 16 1.00018 16H15.0002C15.8202 16 16.2902 15.06 15.8002 14.4Z"
                                    fill="#929292"
                                />
                            </g>
                        </svg>
                    ),
                    administrative: (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                id="administrative and support service activities"
                                clipPath="url(#clip0_2372_45726)"
                            >
                                <path
                                    id="Vector"
                                    d="M7 4C7 2.89 6.11 2 5 2C3.89 2 3 2.89 3 4C3 5.11 3.89 6 5 6C6.11 6 7 5.11 7 4ZM10.19 4.5C9.78 4.5 9.43 4.75 9.27 5.13C8.83 6.23 7.76 7 6.5 7H3.5C2.67 7 2 7.67 2 8.5V11H8V8.74C9.43 8.29 10.58 7.21 11.12 5.83C11.38 5.19 10.88 4.5 10.19 4.5ZM19 17C20.11 17 21 16.11 21 15C21 13.89 20.11 13 19 13C17.89 13 17 13.89 17 15C17 16.11 17.89 17 19 17ZM20.5 18H17.5C16.24 18 15.17 17.23 14.73 16.13C14.58 15.75 14.22 15.5 13.81 15.5C13.12 15.5 12.62 16.19 12.87 16.83C13.42 18.21 14.56 19.29 15.99 19.74V22H21.99V19.5C22 18.67 21.33 18 20.5 18ZM17.25 11.09C17.25 11.09 17.25 11.08 17.26 11.09C16.2 11.36 15.36 12.2 15.09 13.26V13.25C14.98 13.68 14.58 14 14.11 14C13.56 14 13.11 13.55 13.11 13C13.11 12.95 13.13 12.86 13.13 12.86C13.56 11.01 15.02 9.55 16.88 9.13C16.92 9.13 16.96 9.12 17 9.12C17.55 9.12 18 9.57 18 10.12C18 10.58 17.68 10.98 17.25 11.09ZM18 6.06C18 6.57 17.63 6.98 17.14 7.05C13.95 7.44 11.44 9.96 11.05 13.15C10.98 13.63 10.56 14 10.06 14C9.51 14 9.06 13.55 9.06 13C9.06 12.98 9.06 12.96 9.06 12.94C9.06 12.93 9.06 12.92 9.06 12.91C9.56 8.79 12.85 5.53 16.98 5.06H16.99C17.55 5.06 18 5.51 18 6.06Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2372_45726">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    public: (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                id="public administration and defence"
                                clipPath="url(#clip0_2372_45729)"
                            >
                                <path
                                    id="Vector"
                                    d="M14.5 12.59L15.13 15.32C15.23 15.75 14.76 16.09 14.38 15.86L12 14.42L9.61 15.86C9.23 16.09 8.76 15.75 8.86 15.32L9.5 12.6L7.4 10.79C7.06 10.5 7.24 9.95 7.68 9.91L10.46 9.67L11.54 7.11C11.71 6.7 12.29 6.7 12.46 7.11L13.54 9.66L16.32 9.9C16.76 9.94 16.94 10.49 16.6 10.78L14.5 12.59ZM4.19 4.47C3.47 4.79 3 5.51 3 6.3V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V6.3C21 5.51 20.53 4.79 19.81 4.47L12.81 1.36C12.29 1.13 11.7 1.13 11.19 1.36L4.19 4.47Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2372_45729">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    education: (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_4781_7154)">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4 11.9091L12 17L23 10L12 3L1 10L2 10.6364V16H4V11.9091ZM6.29669 11H12V9H6.29669L12 5.37062L19.2747 10L12 14.6294L6.29669 11Z"
                                    fill="#ADADAD"
                                />
                                <path
                                    d="M5 14H7V17.6478C7.30411 17.7986 7.73011 17.9959 8.23739 18.1931C9.30864 18.6096 10.6764 19 12 19C13.3236 19 14.6914 18.6096 15.7626 18.1931C16.2699 17.9959 16.6959 17.7986 17 17.6478V14H19V18.8247L18.5038 19.1141C18.5038 19.1141 18.5038 19.1141 18 18.2503L18.5038 19.1141L18.5018 19.1153L18.4987 19.1171L18.4891 19.1226L18.4571 19.1408C18.4301 19.156 18.392 19.1773 18.3433 19.2038C18.2461 19.2567 18.1069 19.3305 17.9315 19.4181C17.5814 19.5932 17.0841 19.8252 16.4874 20.0572C15.3086 20.5155 13.6764 21 12 21C10.3236 21 8.69136 20.5155 7.51261 20.0572C6.91594 19.8252 6.4186 19.5932 6.06847 19.4181C5.89311 19.3305 5.75386 19.2567 5.65666 19.2038C5.60804 19.1773 5.56987 19.156 5.54289 19.1408L5.51089 19.1226L5.50135 19.1171L5.49822 19.1153L5.49707 19.1146C5.49707 19.1146 5.49619 19.1141 6 18.2503L5.49619 19.1141L5 18.8247V14Z"
                                    fill="#ADADAD"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_4781_7154">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    human: (
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g id="Group">
                                <g id="Group_2">
                                    <path
                                        id="Vector"
                                        d="M18 4H14V2C14 0.9 13.1 0 12 0H8C6.9 0 6 0.9 6 2V4H2C0.9 4 0 4.9 0 6V18C0 19.1 0.9 20 2 20H18C19.1 20 20 19.1 20 18V6C20 4.9 19.1 4 18 4ZM8 2H12V4H8V2ZM13 13H11V15C11 15.55 10.55 16 10 16C9.45 16 9 15.55 9 15V13H7C6.45 13 6 12.55 6 12C6 11.45 6.45 11 7 11H9V9C9 8.45 9.45 8 10 8C10.55 8 11 8.45 11 9V11H13C13.55 11 14 11.45 14 12C14 12.55 13.55 13 13 13Z"
                                        fill="#929292"
                                    />
                                </g>
                            </g>
                        </svg>
                    ),
                    arts: (
                        <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g id="Group">
                                <g id="Group_2">
                                    <path
                                        id="Vector"
                                        d="M13.24 8.50998L14.81 6.93998L11.06 3.18998L9.49 4.75998L5.35 0.629983C4.57 -0.150017 3.3 -0.150017 2.52 0.629983L0.62 2.52998C-0.16 3.30998 -0.16 4.57998 0.62 5.35998L4.75 9.48998L0.15 14.1C0.0500001 14.2 0 14.32 0 14.46V17.5C0 17.78 0.22 18 0.5 18H3.54C3.67 18 3.8 17.95 3.89 17.85L8.51 13.23L12.64 17.36C13.96 18.68 15.4 17.43 15.47 17.36L17.37 15.46C18.15 14.68 18.15 13.41 17.37 12.63L13.24 8.50998ZM6.18 8.06998L2.04 3.93998L3.93 2.03998L5.2 3.30998L4.73 3.79998C4.34 4.18998 4.34 4.81998 4.73 5.20998C5.12 5.59998 5.75 5.59998 6.14 5.20998L6.62 4.72998L8.07 6.17998L6.18 8.06998ZM14.06 15.96L9.93 11.83L11.83 9.92998L13.28 11.38L12.8 11.86C12.41 12.25 12.41 12.88 12.8 13.27C13.19 13.66 13.82 13.66 14.21 13.27L14.69 12.79L15.96 14.06L14.06 15.96Z"
                                        fill="#929292"
                                    />
                                    <path
                                        id="Vector_2"
                                        d="M17.7099 4.03999C18.0999 3.64999 18.0999 3.01999 17.7099 2.62999L15.3699 0.28999C14.8999 -0.18001 14.2499 -1.02222e-05 13.9599 0.28999L12.1299 2.11999L15.8799 5.86999L17.7099 4.03999Z"
                                        fill="#929292"
                                    />
                                </g>
                            </g>
                        </svg>
                    ),
                    other: (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g id="other service activities">
                                <circle
                                    id="Ellipse 112"
                                    cx="5"
                                    cy="12"
                                    r="2"
                                    stroke="#929292"
                                    strokeWidth="2"
                                />
                                <circle
                                    id="Ellipse 113"
                                    cx="12"
                                    cy="12"
                                    r="2"
                                    stroke="#929292"
                                    strokeWidth="2"
                                />
                                <circle
                                    id="Ellipse 114"
                                    cx="19"
                                    cy="12"
                                    r="2"
                                    stroke="#929292"
                                    strokeWidth="2"
                                />
                            </g>
                        </svg>
                    ),
                    activities: (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                id="activities of households"
                                clipPath="url(#clip0_2369_45877)"
                            >
                                <path
                                    id="Vector"
                                    d="M13.0001 18H11.0001V16H13.0001V18ZM9.0001 15V19C9.0001 19.55 9.4501 20 10.0001 20H14.0001C14.5501 20 15.0001 19.55 15.0001 19V15C15.0001 14.45 14.5501 14 14.0001 14H10.0001C9.4501 14 9.0001 14.45 9.0001 15ZM19.0001 9.29997V4.99997C19.0001 4.44997 18.5501 3.99997 18.0001 3.99997H17.0001C16.4501 3.99997 16.0001 4.44997 16.0001 4.99997V6.59997L12.6701 3.59997C12.2901 3.25997 11.7101 3.25997 11.3301 3.59997L2.9701 11.13C2.6301 11.43 2.8401 12 3.3001 12H4.6101C4.8601 12 5.1001 11.91 5.2801 11.74L12.0001 5.68997L18.7101 11.74C18.9001 11.91 19.1401 12 19.3801 12H20.6901C21.1501 12 21.3701 11.43 21.0201 11.13L19.0001 9.29997Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2369_45877">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    "industry.manufacturing": (
                        <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g id="Group">
                                <path
                                    id="Vector"
                                    d="M16.9302 6.35L13.3301 8.03L11.0001 5.7V4.3L13.3301 1.97L16.9302 3.65C17.3102 3.83 17.7502 3.66 17.9302 3.29C18.1102 2.91 17.9402 2.47 17.5702 2.29L13.6501 0.459998C13.2701 0.279998 12.8202 0.359998 12.5202 0.659998L10.7801 2.4C10.6001 2.16 10.3201 2 10.0001 2C9.45015 2 9.00015 2.45 9.00015 3V4H5.82015C5.34015 2.66 3.96015 1.75 2.40015 2.06C1.23015 2.29 0.27015 3.25 0.0501498 4.42C-0.19985 5.76 0.45015 6.96 1.48015 7.58L4.08015 16H2.50015C1.67015 16 1.00015 16.67 1.00015 17.5C1.00015 18.33 1.67015 19 2.50015 19H12.5001C13.3301 19 14.0001 18.33 14.0001 17.5C14.0001 16.67 13.3301 16 12.5001 16H10.3801L5.41015 6.77C5.58015 6.53 5.72015 6.28 5.82015 6H9.00015V7C9.00015 7.55 9.45015 8 10.0001 8C10.3201 8 10.6001 7.84 10.7801 7.6L12.5202 9.34C12.8202 9.64 13.2701 9.72 13.6501 9.54L17.5702 7.71C17.9501 7.53 18.1102 7.09 17.9302 6.71C17.7502 6.34 17.3102 6.17 16.9302 6.35ZM3.00015 6C2.45015 6 2.00015 5.55 2.00015 5C2.00015 4.45 2.45015 4 3.00015 4C3.55015 4 4.00015 4.45 4.00015 5C4.00015 5.55 3.55015 6 3.00015 6Z"
                                    fill="#929292"
                                />
                            </g>
                        </svg>
                    ),
                    "industry.construction": (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                id="construction"
                                clipPath="url(#clip0_2368_45787)"
                            >
                                <path
                                    id="Vector"
                                    d="M5.23996 8.07005L8.06996 5.24005L20.8 17.97C21.58 18.75 21.58 20.02 20.8 20.8C20.02 21.58 18.75 21.58 17.97 20.8L5.23996 8.07005ZM13.73 2.41005L16.56 5.24005C17.34 6.02005 17.34 7.29005 16.56 8.07005L15.14 9.49005L9.48996 3.83005L10.9 2.42005C11.68 1.63005 12.95 1.63005 13.73 2.41005ZM3.82996 9.48005L9.48996 15.14L8.07996 16.55C7.29996 17.33 6.02996 17.33 5.24996 16.55L2.41996 13.72C1.63996 12.9401 1.63996 11.67 2.41996 10.89L3.82996 9.48005Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2368_45787">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    "industry.mining_and_quarrying": (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g id="mining">
                                <g id="Subtract">
                                    <path
                                        d="M3 14.6732V10.5311L4.51232 6.76556L6.49268 5.77937L11.0384 8.91372L14.6835 14.7224L12.5936 20.9667L6.78079 19.945L3 14.6732Z"
                                        fill="#929292"
                                    />
                                    <path
                                        d="M13.3795 21L19.2574 18.4388L20.9366 14.7595L15.3754 15.0364L13.3795 21Z"
                                        fill="#929292"
                                    />
                                    <path
                                        d="M21 14.0023L18.8793 9.77801L15.8547 7.51867V6.64283L11.8521 8.7894L15.3014 14.2861L21 14.0023Z"
                                        fill="#929292"
                                    />
                                    <path
                                        d="M15.6589 5.89247L10.9397 3L7.53695 4.88278L7.12125 5.2968L11.3424 8.20738L15.6589 5.89247Z"
                                        fill="#929292"
                                    />
                                </g>
                            </g>
                        </svg>
                    ),
                    "industry.water_waste": (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                id="water supply"
                                clipPath="url(#clip0_2355_45778)"
                            >
                                <path
                                    id="Vector"
                                    d="M12.5 21C16.088 21 19 18.0033 19 14.311C19 10.9666 15.334 5.85619 13.514 3.50167C12.994 2.83278 12.032 2.83278 11.512 3.50167C9.666 5.85619 6 10.9666 6 14.311C6 18.0033 8.912 21 12.5 21Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2355_45778">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    "industry.electricity_supply": (
                        <svg
                            width={size ? size : 24}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                id="electricity"
                                clipPath="url(#clip0_2355_45775)"
                            >
                                <path
                                    id="Vector"
                                    d="M7 3V12C7 12.55 7.45 13 8 13H10V20.15C10 20.66 10.67 20.84 10.93 20.4L16.12 11.5C16.51 10.83 16.03 10 15.26 10H13L15.49 3.35C15.74 2.7 15.26 2 14.56 2H8C7.45 2 7 2.45 7 3Z"
                                    fill="#929292"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2355_45775">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    minusIcon: (
                        <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                width="32"
                                height="32"
                                rx="16"
                                fill="#F7F7F7"
                            />
                            <g clipPath="url(#clip0_4781_19167)">
                                <path
                                    d="M23 17H17H15H9V15H15H17H23V17Z"
                                    fill={primaryColor}
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_4781_19167">
                                    <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(4 4)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    ),
                    plusIcon: (
                        <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                width="32"
                                height="32"
                                rx="16"
                                fill="#F7F7F7"
                            />
                            <g clipPath="url(#clip0_4781_19173)">
                                <path
                                    d="M23 17H17V23H15V17H9V15H15V9H17V15H23V17Z"
                                    fill={primaryColor}
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_4781_19173">
                                    <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(4 4)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    )
                }[iconName]
            }
        </span>
    );
};

export default DynamicSvg;
