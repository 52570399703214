import Header from "components/ActiveShare/Inner/Header";
import { DataSharedProps } from "types/data-shared.types";
import Info from "components/ActiveShare/Inner/Info";
import Table from "components/ActiveShare/Inner/Education/Container/Horizontal/Table";
import Legend from "components/Main/Education/Legend";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Horizontal = ({
    data,
    isShareModalVertical,
    isDesktop
}: {
    data: DataSharedProps;
    isShareModalVertical: boolean;
    isDesktop: boolean;
}) => (
    <>
        <Header
            isShareModalVertical={isShareModalVertical}
            isDesktop={isDesktop}
        />
        <main tw="p-4">
            <section tw="p-[0.25rem 0.5rem 0] flex justify-center gap-[3rem] h-full flex-col lg:gap-[2rem]">
                <Info isShareModalVertical={isShareModalVertical} data={data} />
                <>
                    <Table data={data?.data} />
                    <Legend isFromShare={true} />
                </>
                <p tw="text-[0.5rem] text-grey-400 px-[3rem]">
                    https://africayouthjobs.io/
                </p>
            </section>
        </main>
    </>
);

export default Horizontal;
