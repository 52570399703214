import Desktop from "components/Header/Navbar/Desktop";
import Mobile from "components/Header/Navbar/Mobile";
import useActiveLink from "hooks/useActiveLink";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Menu = ({ handleToggleState, toggleState }: any) => {
    const { activeLink, handleActiveSection } = useActiveLink();
    return (
        <>
            <Desktop
                handleActiveLink={handleActiveSection}
                activeLink={activeLink}
            />
            <Mobile
                handleToggleState={handleToggleState}
                toggleState={toggleState}
            />
        </>
    );
};

export default Menu;
