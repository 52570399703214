import { t } from "i18next";
import noData from "assets/images/noData.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const NoData = () => (
  <article tw="flex flex-col justify-center h-full gap-[1rem] text-grey-600 items-center text-center">
    <img width={120} alt="No data" src={noData} />
    <strong>{t("no_data_primary")}</strong>
    <p tw="text-grey-500">{t("no_data_secondary")}</p>
  </article>
);
export default NoData;
