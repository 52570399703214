import useWindowResize from "hooks/useWindowResize";
import useAutocomplete from "hooks/useAutocomplete";
import { t } from "i18next";
import { useAfricanYouth } from "context/africanYouth";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { AutoCompleteStyles } from "utils/autoComplete";
import ClearIcon from "assets/images/ClearIcon";
import { Country } from "types/country.types";
import { countries } from "utils/lists";
import { useMemo } from "react";
import Warning from "components/Warning";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";

const Filters = () => {
  const {
    ages,
    country,
    gender,
    mobileModalDropdown,
    setFilters,
    setMobileModalDropdown,
  } = useAfricanYouth();
  const { handleAutocompleteChange } = useAutocomplete();
  const { isDesktop } = useWindowResize();

  const memoCountryInput1 = useMemo(
    () => (
      <Autocomplete
        readOnly={isDesktop ? false : true}
        clearIcon={<ClearIcon />}
        autoHighlight
        options={countries}
        onChange={(_, value, reason) =>
          handleAutocompleteChange(
            value?.name!,
            reason,
            "comparison_country_1_section"
          )
        }
        value={
          country.comparison_country_1_section
            ? { name: country.comparison_country_1_section }
            : (null as any)
        }
        tw="w-full"
        sx={AutoCompleteStyles}
        getOptionLabel={(option) => option.name}
        renderOption={(props: any, option: Country) => (
          <button
            {...props}
            style={{
              color: theme`colors.grey.700`,
              display: "flex",
              fontSize: "0.8rem",
              fontWeight: 700,
              gap: "0.65rem",
              width: "100%",
              textAlign: "left",
            }}>
            {option.file_url ? (
              <img
                loading="lazy"
                width="16"
                src={option.file_url}
                alt={option.name}
              />
            ) : (
              <span style={{ width: "1rem" }}></span>
            )}
            {option.name}
          </button>
        )}
        renderInput={(params: any) => {
          const activeCountryFlag = countries.find(
            ({ name }) => name === params.inputProps.value
          );

          return (
            <div
              tw="flex items-center relative"
              onClick={() =>
                isDesktop
                  ? undefined
                  : setMobileModalDropdown({
                      value: !mobileModalDropdown.isMobileModalDropdownOpen,
                      name: "mobileModalDropdown",
                      section: "countries/comparison_country_1_section",
                    })
              }>
              {activeCountryFlag && (
                <img
                  key={params.inputProps.value + "'s flag"}
                  loading="lazy"
                  width="24"
                  src={activeCountryFlag.file_url}
                  alt="Country flag"
                  tw="absolute left-[0.5rem] z-10"
                />
              )}
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                sx={{
                  textAlign: "left",
                  paddingLeft: params.inputProps.value ? "2rem" : 0,
                  input: {
                    "&::placeholder": {
                      color: "#ADADAD !important",
                    },
                  },
                }}
                placeholder={t("type_or_select_a_region_placeholder")}
              />
            </div>
          );
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [country.comparison_country_1_section, isDesktop]
  );

  const memoCountryInput2 = useMemo(
    () => (
      <Autocomplete
        readOnly={isDesktop ? false : true}
        clearIcon={<ClearIcon />}
        autoHighlight
        options={countries}
        onChange={(_, value, reason) =>
          handleAutocompleteChange(
            value?.name!,
            reason,
            "comparison_country_2_section"
          )
        }
        value={
          country.comparison_country_2_section
            ? { name: country.comparison_country_2_section }
            : (null as any)
        }
        tw="w-full"
        sx={AutoCompleteStyles}
        getOptionLabel={(option) => option.name}
        renderOption={(props: any, option: Country) => (
          <button
            {...props}
            style={{
              color: theme`colors.grey.700`,
              display: "flex",
              fontSize: "0.8rem",
              fontWeight: 700,
              gap: "0.65rem",
              width: "100%",
              textAlign: "left",
            }}>
            {option.file_url ? (
              <img
                loading="lazy"
                width="16"
                src={option.file_url}
                alt={option.name}
              />
            ) : (
              <span style={{ width: "1rem" }}></span>
            )}
            {option.name}
          </button>
        )}
        renderInput={(params: any) => {
          const activeCountryFlag = countries.find(
            ({ name }) => name === params.inputProps.value
          );

          return (
            <div
              tw="flex items-center relative"
              onClick={() =>
                isDesktop
                  ? undefined
                  : setMobileModalDropdown({
                      value: !mobileModalDropdown.isMobileModalDropdownOpen,
                      name: "mobileModalDropdown",
                      section: "countries/comparison_country_2_section",
                    })
              }>
              {activeCountryFlag && (
                <img
                  key={params.inputProps.value + "'s flag"}
                  loading="lazy"
                  width="24"
                  src={activeCountryFlag.file_url}
                  alt="Country flag"
                  tw="absolute left-[0.5rem] z-10"
                />
              )}
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                sx={{
                  textAlign: "left",
                  paddingLeft: params.inputProps.value ? "2rem" : 0,
                  input: {
                    "&::placeholder": {
                      color: "#ADADAD !important",
                    },
                  },
                }}
                placeholder={t("type_or_select_a_region_placeholder")}
              />
            </div>
          );
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [country.comparison_country_2_section, isDesktop]
  );

  return (
    <article tw="max-w-[70rem] w-full p-[1rem 0] sm:p-[1rem]">
      <div tw="flex gap-[1rem] items-center w-full lg:flex-col">
        <div tw="flex gap-[1rem] w-full sm:flex-col">
          <div tw="w-full">
            <label tw="text-grey-500 leading-[0.938rem] text-sm pb-[0.625rem]">
              {t("country_1")}
            </label>
            <div tw="flex gap-2 items-center">
              {memoCountryInput1}{" "}
              <Warning countryName={country.comparison_country_1_section!} />
            </div>
          </div>
          <div tw="w-full">
            <label tw="text-grey-500 leading-[0.938rem] text-sm pb-[0.625rem]">
              {t("country_2")}
            </label>
            <div tw="flex gap-2 items-center">
              {memoCountryInput2}{" "}
              <Warning countryName={country.comparison_country_2_section!} />
            </div>
          </div>
        </div>
        <div tw="flex flex-col gap-[1rem] w-full sm:flex-wrap">
          <label tw="text-grey-500 leading-[0.938rem] text-sm pb-[0.625rem]">
            {t("filters")}
          </label>
          <div tw="flex gap-[1rem]">
            <button
              onClick={() => {
                setFilters({
                  value: true,
                  name: "isFiltersDialogOpen",
                });
                setFilters({
                  value: ["AGE", "GENDER"],
                  name: "filterListForSection",
                });
              }}
              tw="bg-orange-600 hover:bg-orange-700 p-[0.25rem 0.5rem] text-sm text-white font-medium rounded-[1rem]">
              {`${ages.from}-${ages.to} years`}
            </button>
            <button
              onClick={() => {
                setFilters({
                  value: true,
                  name: "isFiltersDialogOpen",
                });
                setFilters({
                  value: ["AGE", "GENDER"],
                  name: "filterListForSection",
                });
              }}
              tw="bg-orange-600 hover:bg-orange-700 p-[0.25rem 0.5rem] text-sm text-white font-medium rounded-[1rem]">
              {t(gender)}
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Filters;
