import { t } from "i18next";
import logo from "assets/images/footer/footerLogo.svg";
import ContactUs from "components/Footer/ContactUs";
import mastercardFoundation from "assets/images/footer/mastercardFoundation.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Footer = () => (
    <footer tw="max-w-[110rem] m-auto mb-[5rem] bg-green-600 text-yellow-100 p-[2.5rem 4rem] sm:p-[1rem 1rem 2rem] flex flex-wrap items-end justify-between sm:gap-[4rem]">
        <div tw="justify-start flex flex-row flex-wrap items-end sm:gap-[4rem] gap-[8rem]">
            <div>
                <h2 tw="max-w-[20rem] font-semiBold text-[2.188rem] uppercase">
                    {t("title")}
                </h2>
                <span>{t("footer_by")}</span>
                <div tw="w-[13.088rem] uppercase flex justify-between items-center sm:pt-0 pt-[0.5rem]">
                    <img tw="w-[3.708rem] h-[2.825rem]" alt="WDL" src={logo}/>
                    <span>{t("footer_world_data_lab")}</span>
                </div>
            </div>
            <ContactUs/>
        </div>
        <img src={mastercardFoundation} alt="Mastercard Foundation"/>
    </footer>
);

export default Footer;
