import { useAfricanYouth } from "context/africanYouth";
import { t } from "i18next";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { years } from "utils/lists";
import arrow from "assets/images/arrowDropdown.svg";
import info from "assets/images/info.svg";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";
import useWindowResize from "hooks/useWindowResize";

const StyledSlider = styled(Slider)(() => ({
  ".MuiSlider-mark": {
    borderRadius: 50,
    height: "1rem",
    width: "1rem",
    backgroundColor: theme`colors.grey.300`,
  },
  ".MuiSlider-markLabel": {
    color: theme`colors.grey.300`,
    fontSize: "0.938rem",
    fontWeight: 400,
    transform: "translateX(-30%)",
  },
  ".MuiSlider-markLabelActive": {
    fontSize: "0.938rem",
    fontWeight: 600,
    color: theme`colors.grey.500`,
  },
  ".MuiSlider-rail": {
    color: theme`colors.grey.200`,
    height: "1px",
    opacity: 1,
  },
  ".MuiSlider-thumb": {
    height: "2rem",
    width: "2rem",
    backgroundColor: theme`colors.orange.600`,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    transform: "translate(-35%, -50%)",
  },
  ".MuiSlider-markActive": {
    color: theme`colors.grey.400`,
  },
}));

const YearFilter = () => {
  const { year, mobileModalDropdown, setFilters, setMobileModalDropdown } =
    useAfricanYouth();
  const { isDesktop } = useWindowResize();

  const handleChange = (_: Event, value: number | number[]) =>
    setFilters({
      value,
      name: "year",
    });

  return (
    <section tw="max-w-[110rem] fixed bottom-0 w-full z-[1000]">
      <article tw="p-[0.75rem 4rem] sm:p-[1rem] sm:flex flex-col border-t border-t-orange-600 bg-orange-100 gap-[0.625rem] hidden lg:flex">
        <h3 tw="text-grey-700 leading-[0.938rem] text-sm font-semiBold">
          {t("select_year")}
        </h3>
        <div tw="flex items-center justify-between gap-3">
          <button
            onClick={() =>
              setMobileModalDropdown({
                value: !mobileModalDropdown.isMobileModalDropdownOpen,
                name: "mobileModalDropdown",
                section: "years",
              })
            }
            tw="flex justify-between items-center w-full bg-orange-100 border-b border-b-orange-600 text-grey-700">
            {t(year)}
            <img src={arrow} alt="Arrow" />
          </button>
          <button
            onClick={() =>
              setMobileModalDropdown({
                value: !mobileModalDropdown.isMobileModalDropdownOpen,
                name: "mobileModalDropdown",
                section: "timeline_info",
              })
            }>
            <img src={info} alt="Info" />
          </button>
        </div>
      </article>
      <article tw="w-[100%] p-[0.5rem 4rem 2.5rem] h-[5.3rem] lg:hidden bg-white shadow-[0px -4px 4px rgba(0, 0, 0, 0.1)] border-t border-t-orange-600 flex justify-between items-center">
        <StyledSlider
          track={false}
          getAriaLabel={() => "Year slider marks"}
          getAriaValueText={(value) => value.toString()}
          marks={years}
          min={2015}
          max={2030}
          onChange={handleChange}
          value={Number(year)}
          tw="m-0 p-0"
        />
        <button tw="relative ml-[2.4rem]" className={isDesktop ? "group" : ""}>
          <img src={info} alt="Info" />
          <span tw="absolute hidden group-hover:flex items-center -top-2/4 right-0 max-w-[max-content] bg-grey-500 rounded-[0.5rem] text-white p-[0.5rem] w-[15rem] -translate-y-full text-sm text-left">
            {t("timeline_info.text")}
          </span>
        </button>
      </article>
    </section>
  );
};

export default YearFilter;
