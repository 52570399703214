import { useAfricanYouth } from "context/africanYouth";
import { t } from "i18next";
import {
    ChangeEvent,
    MouseEvent,
    FormEvent,
    useCallback,
    useState
} from "react";
import { FormData } from "types/form.types";
import toast from "react-hot-toast";

const useForm = () => {
    const { setFilters } = useAfricanYouth();
    const [formData, setFormData] = useState<FormData>({
        value: "",
        message: "",
        isMessageSent: false
    });

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        const value = e.currentTarget as HTMLButtonElement;
        setFormData({ ...formData, value: value.name });
    };

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
        setFormData({ ...formData, message: e.currentTarget.value });

    const handleReset = useCallback(() => {
        setFilters({ name: "activeFeedback", value: null });
        setFormData({ value: "", message: "", isMessageSent: false });
    }, [setFilters]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (localStorage.getItem("feedbackAlreadySubmitted") === "true") {
            handleReset();
            return toast.error(t("submitting_twice"));
        }

        const payload = {
            fields: [
                { objectTypeId: "0-1", name: "grade", value: formData.value },
                {
                    objectTypeId: "0-1",
                    name: "feedback",
                    value: formData.message
                }
            ],
            context: {
                pageUri: "http://africayouthjobs.io",
                pageName: "Africa Youth Jobs Clock"
            }
        };
        try {
            await fetch(
                "https://api.hsforms.com/submissions/v3/integration/submit/25336566/1341cc51-ef35-4586-9191-387d99394d31 ",
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    mode: "cors",
                    body: JSON.stringify(payload)
                }
            ).then((e) => {
                if (e.status === 200) {
                    localStorage.setItem("feedbackAlreadySubmitted", "true");
                    toast.success(t("submitting_form"));
                    handleReset();
                } else toast.error(t("submitting_wrong"));
            });
        } catch (err: any) {
            toast.error(t("submitting_wrong"));
            console.error(err);
        }
    };

    return { formData, handleChange, handleClick, handleReset, handleSubmit };
};

export default useForm;
