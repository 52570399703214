import Header from "components/ActiveShare/Inner/Header";
import { DataSharedProps } from "types/data-shared.types";
import Info from "components/ActiveShare/Inner/Info";
import Table from "components/ActiveShare/Inner/CountryComparison/Container/Table";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Horizontal = ({
    countryComparison1,
    countryComparison2,
    isShareModalVertical,
    isDesktop
}: {
    countryComparison1: DataSharedProps;
    countryComparison2: DataSharedProps;
    isShareModalVertical: boolean;
    isDesktop: boolean;
}) => (
    <>
        <Header
            isShareModalVertical={isShareModalVertical}
            isDesktop={isDesktop}
        />
        <main tw="p-1">
            <section tw="p-[0.25rem] flex justify-center h-full flex-col">
                <Info
                    isShareModalVertical={isShareModalVertical}
                    data={
                        countryComparison1
                            ? countryComparison1
                            : countryComparison2
                    }
                />
                <Table
                    comparisonCountry1={countryComparison1.data}
                    comparisonCountry2={countryComparison2.data}
                />
                <p tw="px-[3rem] mt-2 text-[0.5rem] text-grey-400">
                    https://africayouthjobs.io/
                </p>
            </section>
        </main>
    </>
);

export default Horizontal;
