import { useAfricanYouth } from "context/africanYouth";
import { handleToFixed, handleToLocaleString } from "utils/formatting";
import { Fragment, useMemo } from "react";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
import { t } from "i18next";
import arrowLeft from "assets/images/arrowLeft.svg";
import PieChart from "components/Charts/Pie";
import { colorsPalette } from "utils/lists";
import { handleAgriculture } from "utils/utilities";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Sectors = ({ data }: any) => {
    const { setFilters } = useAfricanYouth();

    const updatedSectorsData = useMemo(
        () => ({
            ...data,
            sectors: data.sectors.map((sector: any, i: number) => ({
                ...sector,
                data: new Map()
                    .set(`${sector.percentage}`, sector.percentage)
                    .entries()
                    .next().value,
                color: colorsPalette[i]
            }))
        }),
        [data]
    );

    return (
        <div tw="flex flex-col gap-[1rem] shadow-[0 -0.25rem 0.25rem rgba(0, 0, 0, 0.1)] h-full p-[1rem] lg:p-[1.5rem 4rem] sm:p-[1rem]">
            <button
                onClick={() =>
                    setFilters({ name: "mapEmployedSector", value: null })
                }
                tw="my-[0.5rem] flex items-center text-orange-700 uppercase font-semiBold text-sm gap-[0.5rem]"
            >
                <img src={arrowLeft} alt="Arrow left" />
                {t(updatedSectorsData.sectorGroup)}
            </button>
            <div tw="flex flex-col text-grey-600 font-semiBold justify-between border border-grey-100 rounded p-[0.5rem] gap-[0.25rem]">
                <div tw="flex justify-between">
                    <div tw="flex justify-between gap-[1rem] items-center sm:flex-col sm:items-start">
                        <img
                            alt={updatedSectorsData.sectorGroup}
                            src={`assets/images/sectors_smalls/${handleAgriculture(
                                updatedSectorsData!.sectorGroup
                            )!.toLocaleLowerCase()}.svg`}
                        />
                        <h4 tw="text-grey-600 text-sm py-[0.25rem] text-left">
                            {t(updatedSectorsData.sectorGroup)}
                        </h4>
                    </div>
                    <div tw="flex justify-between items-center gap-[0.5rem] font-semiBold">
                        <DynamicSvg iconName="population" />
                        <p tw="text-grey-600">
                            {handleToLocaleString(
                                updatedSectorsData.totalPopulation
                            )}
                        </p>
                        <span css={[tw`text-sm text-grey-500`]}>
                            {handleToFixed(
                                updatedSectorsData.populationPercentage
                            )}
                            %
                        </span>
                    </div>
                </div>
            </div>
            <PieChart
                data={updatedSectorsData.sectors.map(({ data }: any) => data)}
                colors={updatedSectorsData.sectors.map(
                    ({ color }: any) => color
                )}
            />
            {updatedSectorsData.sectors.map(
                ({ name, percentage, color, population }: any) => (
                    <Fragment key={name}>
                        <div tw="border border-orange-300 hover:shadow-[0 0 0 0.1rem #F3BF7E] rounded p-[0.5rem] flex flex-col gap-[0.25rem]">
                            <div>
                                <div tw="flex justify-between items-center">
                                    <div tw="flex justify-between gap-[1rem] items-center sm:flex-col sm:items-start">
                                        <img
                                            alt={name}
                                            src={`assets/images/sectors_smalls/${handleAgriculture(
                                                name
                                            )?.toLowerCase()}.svg`}
                                        />
                                        <h4 tw="text-grey-600 text-sm py-[0.25rem] text-left">
                                            {t(name)}
                                        </h4>
                                    </div>
                                    <div tw="flex justify-between items-center gap-[0.5rem] font-semiBold">
                                        <DynamicSvg iconName="population" />
                                        <p tw="text-grey-600">
                                            {handleToLocaleString(population)}
                                        </p>
                                        <span css={[tw`text-sm text-grey-500`]}>
                                            {handleToFixed(percentage)}%
                                        </span>
                                    </div>
                                </div>
                                <div tw="flex justify-between items-center pt-[0.6rem] pb-[0.5rem]">
                                    <span
                                        tw="block w-[1rem] h-[1rem] rounded-[50%] ml-[0.25rem]"
                                        style={{ backgroundColor: color }}
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            )}
        </div>
    );
};

export default Sectors;
