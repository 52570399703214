import Vertical from "components/ActiveShare/Inner/Employment/Container/Vertical";
import Horizontal from "components/ActiveShare/Inner/Employment/Container/Horizontal";
import { useAfricanYouth } from "context/africanYouth";
import { urls } from "config/urls";
import useFetch from "hooks/useFetch";
import { getCountryId } from "utils/countries";
import { getGender } from "utils/filters";

const Container = ({
    isShareModalVertical,
    isDesktop
}: {
    isShareModalVertical: boolean;
    isDesktop: boolean;
}) => {
    const { year, country, regionMapSection, ages, gender, mapEmployedSector } =
        useAfricanYouth();
    const { data } = useFetch(
        `${
            urls.API_HOST_URL
        }employment-breakdown?year=${year}&countryCode=${getCountryId(
            country.employment_section!
        )}${regionMapSection ? `&region=${regionMapSection}` : ""}${
            ages.from ? `&ageFrom=${ages.from}` : ""
        }${ages.to ? `&ageTo=${ages.to}` : ""}${
            gender !== "all" ? `&genderFilter=${getGender(gender)}` : ""
        }`
    );

    const { data: sectorsData } = useFetch(
        `${urls.API_HOST_URL}sectors/${mapEmployedSector}?year=${year}&${
            ages.from ? `&ageFrom=${ages.from}` : ""
        }${ages.to ? `&ageTo=${ages.to}` : ""}&countryCode=${getCountryId(
            country.employment_section!
        )}${regionMapSection ? `&region=${regionMapSection}` : ""}${
            gender !== "all" ? `&genderFilter=${getGender(gender)}` : ""
        }`
    );

    return data && sectorsData ? (
        !isDesktop || isShareModalVertical ? (
            <Vertical
                data={{
                    data:
                        sectorsData && mapEmployedSector
                            ? { ...sectorsData }
                            : { ...data },
                    country: country.employment_section!,
                    year,
                    ages: [ages.from!, ages.to!],
                    gender,
                    sector: mapEmployedSector!,
                    region: regionMapSection!
                }}
                isShareModalVertical={isShareModalVertical}
                isDesktop={isDesktop}
            />
        ) : (
            <Horizontal
                data={{
                    data:
                        sectorsData && mapEmployedSector
                            ? { ...sectorsData }
                            : { ...data },
                    country: country.employment_section!,
                    year,
                    ages: [ages.from!, ages.to!],
                    gender,
                    sector: mapEmployedSector!,
                    region: regionMapSection!
                }}
                isShareModalVertical={isShareModalVertical}
                isDesktop={isDesktop}
            />
        )
    ) : null;
};

export default Container;
