import { useAfricanYouth } from "context/africanYouth";
import { useClickOutside } from "hooks/useClickOutside";
import { t } from "i18next";
import { genders, employments, areas } from "utils/lists";
import AgeSlider from "components/AgeSlider";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const FiltersDialog = () => {
    const {
        area,
        isFiltersDialogOpen,
        gender,
        employment,
        setFilters,
        status,
        filterListForSection
    } = useAfricanYouth();

    const dialogRef = useClickOutside(() =>
        isFiltersDialogOpen && setFilters({
            value: false,
            name: "isFiltersDialogOpen"
        })
    );

    return (
        <aside
            css={[
                tw`fixed bg-[rgba(0, 0, 0, .30)] top-0 left-0 w-full h-full z-[1005] invisible opacity-0 transition-all duration-500 flex justify-center items-center`,
                isFiltersDialogOpen && tw`opacity-100 visible`
            ]}
        >
            <dialog
                ref={dialogRef}
                tw="shadow p-[1rem] bg-white rounded-[0.5rem] z-[1006] block"
            >
                <h3 tw="text-orange-700 text-xs">{t("filters")}</h3>
                {filterListForSection.includes('AGE') && 
                    <>
                        <h4 tw="text-grey-500 text-sm my-[1rem]">
                            {t("all_age_groups")}
                        </h4>
                        <p tw="text-grey-700 pb-[2rem]">{t("filters_slider")}</p>
                        <AgeSlider />
                    </>
                }
                {filterListForSection.includes('GENDER') &&
                    <>
                        <h4 tw="text-grey-500 text-sm mt-[1.5rem] mb-[0.25rem]">
                            {t("genders.title")}
                        </h4>
                        <div tw="grid rounded-[2.75rem] grid-cols-[repeat(3, 1fr)] border border-orange-600 text-grey-500 overflow-hidden text-sm">
                            {genders.map(({ id }) => (
                                <button
                                    key={id}
                                    onClick={() =>
                                        setFilters({ value: id, name: "gender" })
                                    }
                                    css={[
                                        tw`rounded-[2.75rem] uppercase font-semiBold hover:text-orange-600 h-[2.25rem]`,
                                        gender === id &&
                                            tw`bg-orange-600 text-white hover:text-white`
                                    ]}
                                >
                                    {t(id)}
                                </button>
                            ))}
                        </div>
                    </>
                }
                {status === "Employed" && filterListForSection.includes('JOBTYPE') && (
                    <>
                        <h4 tw="text-grey-500 text-sm mt-[1.5rem] mb-[0.25rem]">
                            {t("employment")}
                        </h4>
                        <div tw="grid rounded-[2.75rem] grid-cols-[repeat(3, 1fr)] border border-orange-600 text-grey-500 overflow-hidden text-sm">
                            {employments.map(({ id }) => (
                                <button
                                    key={id}
                                    onClick={() =>
                                        setFilters({
                                            value: id,
                                            name: "employment"
                                        })
                                    }
                                    css={[
                                        tw`rounded-[2.75rem] uppercase font-semiBold hover:text-orange-600 h-[2.25rem]`,
                                        employment === id &&
                                            tw`bg-orange-600 text-white hover:text-white`
                                    ]}
                                >
                                    {t(id)}
                                </button>
                            ))}
                        </div>
                    </>
                )}
                {status === "Unemployed" && filterListForSection.includes('AREA') && (
                    <>
                        <h4 tw="text-grey-500 text-sm mt-[1.5rem] mb-[0.25rem]">
                            {t("areas")}
                        </h4>
                        <div tw="grid rounded-[2.75rem] grid-cols-[repeat(3, 1fr)] border border-orange-600 text-grey-500 overflow-hidden text-sm">
                            {areas.map(({ id }) => (
                                <button
                                    key={id}
                                    onClick={() =>
                                        setFilters({
                                            value: id,
                                            name: "area"
                                        })
                                    }
                                    css={[
                                        tw`rounded-[2.75rem] uppercase font-semiBold hover:text-orange-600 h-[2.25rem]`,
                                        area === id &&
                                            tw`bg-orange-600 text-white hover:text-white`
                                    ]}
                                >
                                    {t(id)}
                                </button>
                            ))}
                        </div>
                    </>
                )}
                <div tw="text-orange-700 mt-[2rem] text-sm">
                    <button
                        onClick={() =>
                            setFilters({
                                value: false,
                                name: "isFiltersDialogOpen"
                            })
                        }
                        tw="uppercase font-semiBold bg-orange-200 hover:bg-orange-600 hover:text-white rounded-[2.75rem] h-[2.75rem] w-full"
                    >
                        {t("confirm")}
                    </button>
                </div>
            </dialog>
        </aside>
    );
};

export default FiltersDialog;
