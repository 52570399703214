import { t } from "i18next";
import { percentages } from "utils/employments";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const containerVariants = {
  no: tw`bg-yellow-600`,
  "no education": tw`bg-yellow-600`,
  primary: tw`bg-yellow-400`,
  secondary: tw`bg-yellow-200`,
  tertiary: tw`bg-yellow-100`,
};

const styles = {
  container: ({ variant = "no" }) => [
    tw`flex w-full`,
    containerVariants[variant as keyof typeof containerVariants],
  ],
};

const Table = ({
  data,
}: {
  data: {
    sectorGroupName: string;
    educationLevels: {
      educationLevel: string;
      population: number;
      percentage: number;
    }[];
  }[];
}) => {
  return (
    <article tw="w-[42.5rem] lg:w-full m-auto sm:pb-[1.5rem]">
      <div tw="flex flex-col gap-[2rem] sm:gap-[1rem] relative m-[3rem 0] sm:m-[3rem 0 0] sm:h-[13.31rem]">
        {percentages.map((_percentage) => (
          <div
            key={_percentage}
            tw="border-b border-b-grey-100 first-of-type:mt-[-1.5rem]">
            <label tw="p-[2.5rem 1rem] sm:p-[2.5rem 1rem] text-sm text-grey-400">
              {_percentage}
            </label>
          </div>
        ))}

        <div
          className="scrollable"
          tw="flex justify-between w-[65%] md:w-[80%] sm:w-[calc(100% - 5rem)] sm:translate-x-0 translate-x-[-50%] left-[50%] sm:left-[5rem] h-full sm:h-[13.81rem] absolute sm:gap-[2rem] sm:overflow-x-scroll sm:pr-[1rem]">
          {data?.map((sector) => (
            <div
              key={sector.sectorGroupName}
              tw="h-full sm:h-[10.31rem] text-yellow-800 font-medium mt-[-1.7rem]">
              <img
                src={`assets/images/sectors/${sector.sectorGroupName?.toLocaleLowerCase()}.svg`}
                alt={`${sector.sectorGroupName} icon`}
                tw="m-auto pb-[0.2rem]"
              />
              {[...sector.educationLevels].reverse().map((levels) => (
                <div
                  key={levels.percentage}
                  css={[
                    tw`p-[0.25rem] w-[8rem] text-xs xxs:text-[0.65rem] flex items-center justify-center gap-[0.5rem]`,
                    {
                      height: `${levels.percentage.toFixed()}%`,
                    },
                    styles.container({
                      variant: levels.educationLevel,
                    }),
                  ]}>
                  <span>{levels.population.toLocaleString()}</span>
                  <span tw="text-red-800">
                    ({levels.percentage.toFixed(1)} %)
                  </span>
                </div>
              ))}
              <h4 tw="w-[8rem] text-center text-grey-600 mt-[0.5rem]">
                {t(sector.sectorGroupName)}
              </h4>
            </div>
          ))}
        </div>
      </div>
    </article>
  );
};

export default Table;
