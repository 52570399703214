import { GlobalState } from "types/african-youth-context.types";
import { currentYear } from "utils/utilities";
import mapData from "data/AfricaGeoJson.json";
import { countries } from "utils/lists";
import { getURLSearchParam } from "hooks/urlStateManagement";

const mapCoords = mapData[0].features.map((country) => {
  const mergedFlags = countries.find(({ iso3c }) => country.iso3c === iso3c);

  return { ...country, ...mergedFlags };
});

export let initialState: GlobalState = {
  activeFeedback: null,
  activeSummaryGender: null,
  ages: {
    from: Number(getURLSearchParam("agesFrom")) || 15,
    to: Number(getURLSearchParam("agesTo")) || 35,
  },
  area: getURLSearchParam("area") || "both",
  country: {
    hero_section: getURLSearchParam("country") || "Africa",
    employment_section: getURLSearchParam("country") || "",
    sectors_section: getURLSearchParam("country") || "",
    trends_section: getURLSearchParam("country") || "",
    education_section: getURLSearchParam("country") || "",
    comparison_country_1_section: getURLSearchParam("country") || "",
    comparison_country_2_section: getURLSearchParam("comparedCountry") || "",
  },
  employment: getURLSearchParam("employment") || "both",
  regionMapSection: getURLSearchParam("region") || null,
  regionGendersSection: getURLSearchParam("region") || null,
  regionTrendsSection: getURLSearchParam("region") || null,
  mobileModalDropdown: {
    isMobileModalDropdownOpen: false,
    section: null,
  },
  mapCoords,
  filterListForSection: ["AGE", "GENDER", "JOBTYPE", "AREA"],
  isFiltersDialogOpen: false,
  gender: getURLSearchParam("gender") || "all",
  language: localStorage.getItem("i18nextLng") || "eng",
  status: getURLSearchParam("status") || "Employed",
  disaggregated: getURLSearchParam("disaggregatedBy") || "educational",
  trendsEmployedSector: getURLSearchParam("sector") || "agriculture",
  mapEmployedStatus: getURLSearchParam("status") || null,
  mapEmployedSector: getURLSearchParam("sector") || null,
  genderSector: getURLSearchParam("sector") || null,
  genderStatus: getURLSearchParam("status") || "Employed",
  year: getURLSearchParam("year") || currentYear.toString(),
  sharedModalSection: null,
  isSharedModalOpen: false,
  sharedUrl: null,
  setActiveLanguage: () => {},
  setCountryFilters: () => {},
  setFilters: () => {},
  setFiltersDropdown: () => {},
  setMobileModalDropdown: () => {},
  setAgesFilters: () => {},
  resetMobileModalDropdown: () => {},
  showEthiopiaModal: false,
};
