import { t } from "i18next";
import { handleToLocaleString, handleToFixed } from "utils/formatting";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Sectors = ({ data }: { data: any }) =>
    data.map(({ sector, color }: any) => (
        <div tw="flex items-center justify-between border-b border-b-grey-100 py-[0.25rem]">
            <div tw="flex items-center gap-[0.5rem]">
                <span
                    style={{ background: color }}
                    tw="rounded-full w-full max-w-[0.65rem] h-[0.65rem]"
                ></span>
                <h4 tw="font-semiBold text-[0.56rem]">{t(sector.name)}</h4>
            </div>
            <div tw="flex gap-[0.5rem] items-center font-semiBold">
                <span tw="text-xs">
                    {handleToLocaleString(sector.population)}
                </span>
                <span tw="text-[0.56rem] text-grey-500">
                    {handleToFixed(sector.percentage)}%
                </span>
            </div>
        </div>
    ));

export default Sectors;
