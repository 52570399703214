import { useEffect, useMemo, useState } from "react";
import { buildChart } from "utils/trends";
import { useAfricanYouth } from "context/africanYouth";
import { TrendsListEntry } from "types/trends.types";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HighchartsPatternFill from "highcharts/modules/pattern-fill";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

HighchartsPatternFill(Highcharts);

const Chart = ({
  chartData,
  isFromShare,
  isShareModalVertical,
  isDesktop,
}: {
  chartData: TrendsListEntry[];
  isShareModalVertical?: boolean;
  isFromShare?: boolean;
  isDesktop?: boolean;
}) => {
  const { disaggregated, status, trendsEmployedSector } = useAfricanYouth();
  const [chartOptions, setChartOptions] = useState([{}]);

  useEffect(() => {
    setChartOptions(
      buildChart(chartData, status, disaggregated, trendsEmployedSector)
    );
  }, [chartData, disaggregated, status, trendsEmployedSector]);

  const options = useMemo(
    () => ({
      chart: {
        type: "area",
        height:
          isFromShare && (isShareModalVertical || isDesktop)
            ? 150
            : isFromShare
            ? 200
            : 283,
        style: {
          fontFamily: "Work Sans",
        },
      },
      title: {
        text: "",
      },
      credits: { enabled: false },
      plotOptions: {
        series: {
          pointStart: 2015,
          pointEnd: 2030,
          connectNulls: true,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
        area: {
          stacking: "normal",
          lineWidth: 0.1,
          lineColor: "",
          shadow: false,
          marker: {
            enabled: false,
          },
          enableMouseTracking: true,
          showInLegend: false,
        },
      },
      xAxis: {
        tickWidth: 0,
        lineColor: theme`colors.grey.200`,
        min: 2015,
        max: 2030,
        startOnTick: true,
        endOnTick: true,
        labels: {
          style: {
            color: theme`colors.grey.400`,
            fontSize:
              isFromShare && (isShareModalVertical || isDesktop)
                ? "0.4rem"
                : "0.8rem",
          },
        },
        tickInterval:
          isFromShare && (isShareModalVertical || isDesktop) ? 5 : 1,
        categories: Array.from({ length: 16 }, (_, i) => `${2015 + i}`),
      },
      yAxis: {
        title: {
          text: "",
        },
        tickPositioner: function (this: any) {
          let tick = Math.floor(this.dataMin);
          const positions = [],
            increment = Math.ceil((this.dataMax - this.dataMin) / 6);

          if (this.dataMax !== null && this.dataMin !== null) {
            for (tick; tick - increment <= this.dataMax; tick += increment) {
              positions.push(tick);
            }
          }
          return positions;
        },
        allowDecimals: true,
        labels: {
          style: {
            color: theme`colors.grey.400`,
            fontSize:
              isFromShare && (isShareModalVertical || isDesktop)
                ? "0.4rem"
                : "0.8rem",
          },
        },
      },
      tooltip:
        !disaggregated && !trendsEmployedSector
          ? {
              enabled: !isFromShare,
              shared: true,
              useHTML: true,
              headerFormat:
                '<table><tr><th colspan="2" style="font-size: 1.2rem; font-weight: 400">{point.key}</th></tr><tr><td><span class="chart-tooltip-color"></span>Total</td><td style="text-align: right; padding-left: 1rem"><b>{(multiply point.total 1000000):,.0f} <span style="font-weight: 200;">100%</span></b></td></tr></tr>',
              pointFormat: "<tr></tr>",
              footerFormat: "</table>",
              backgroundColor: "#252525",
              style: {
                color: "#fff",
              },
            }
          : {
              enabled: !isFromShare,
              shared: true,
              useHTML: true,
              headerFormat:
                '<table><tr><th colspan="2" style="font-size: 1.2rem; font-weight: 400">{point.key}</th></tr><tr><td><span class="chart-tooltip-color"></span>Total</td><td style="text-align: right; padding-left: 1rem"><b>{(multiply point.total 1000000):,.0f} <span style="font-weight: 200;">100%</span></b></td></tr></tr>',
              pointFormat:
                '<tr><td><span class="chart-tooltip-color" style="background: {series.color}"></span>{series.name} </td>' +
                `<td style="text-align: right; padding-left: 1rem"><b>{(multiply point.y 1000000):,.0f} <span style="font-weight: 200;">{point.percentage:.2f}%</span></b></td></tr>`,
              footerFormat: "</table>",
              backgroundColor: "#252525",
              style: {
                color: "#fff",
              },
            },
      series:
        !disaggregated && !trendsEmployedSector
          ? chartOptions[0]
          : [...chartOptions],
    }),
    [
      chartOptions,
      disaggregated,
      trendsEmployedSector,
      isFromShare,
      isShareModalVertical,
      isDesktop,
    ]
  );

  return (
    <div
      css={[
        tw`w-[65%] lg:w-[700px] md:w-full sm:w-[700px]`,
        isFromShare &&
          (!isShareModalVertical || isDesktop) &&
          tw`w-[65%] lg:w-full`,
        isFromShare &&
          (isShareModalVertical || isDesktop) &&
          tw`w-full lg:w-full`,
      ]}>
      <HighchartsReact
        containerProps={{
          style: {
            height:
              isFromShare && (isShareModalVertical || isDesktop)
                ? "150px"
                : isFromShare
                ? "200px"
                : "auto",
            width: isFromShare ? "100%" : "auto",
          },
        }}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default Chart;
