import { useAfricanYouth } from "context/africanYouth";
import { urls } from "config/urls";
import useFetch from "hooks/useFetch";
import { getCountryId } from "utils/countries";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
import { t } from "i18next";
import { Fragment } from "react";
import { SituationsType } from "types/situations.types";
import PieChart from "components/Charts/Pie";
import Inner from "components/Main/Employment/Country/Situations/Inner";
import Statuses from "components/Main/Employment/Country/Situations/Statuses";
import { Tooltip } from "types/employment.types";
import arrowOpen from "assets/images/arrowOpen.svg";
import { getGender } from "utils/filters";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import Loader from "components/Loader";

const Situations = ({
    data,
    setTooltip,
    tooltip
}: {
    data: SituationsType[];
    tooltip: Tooltip | null;
    setTooltip: (category: Tooltip | null) => void;
}) => {
    const {
        year,
        regionMapSection,
        ages,
        gender,
        country,
        mapEmployedStatus,
        setFilters
    } = useAfricanYouth();

    const { data: sectorsData, isLoading } = useFetch(
        `${
            urls.API_HOST_URL
        }employment-breakdown-extended?year=${year}&countryCode=${getCountryId(
            country.employment_section!
        )}${regionMapSection ? `&region=${regionMapSection}` : ""}${
            ages.from ? `&ageFrom=${ages.from}` : ""
        }${ages.to ? `&ageTo=${ages.to}` : ""}${
            gender !== "all" ? `&genderFilter=${getGender(gender)}` : ""
        }`
    );

    return isLoading ? (
        <Loader />
    ) : mapEmployedStatus && sectorsData ? (
        <Statuses
            tooltip={tooltip}
            data={sectorsData}
            setTooltip={setTooltip}
        />
    ) : (
        <div tw="flex flex-col gap-[1rem] shadow-[0 -0.25rem 0.25rem rgba(0, 0, 0, 0.1)] h-full p-[1rem] lg:p-[1.5rem 4rem] sm:p-[1rem]">
            {data.map(
                ({ name, innerGroups, percentage, color, colors, value }) => (
                    <Fragment key={name}>
                        <div
                            tw="border border-grey-100 rounded p-[0.5rem] flex flex-col gap-[0.25rem] "
                            css={[
                                (name === "Employed" || innerGroups) &&
                                    tw`border-orange-300 hover:shadow-[0 0 0 0.1rem #F3BF7E]`
                            ]}
                        >
                            <div>
                                <div tw="flex justify-between items-center">
                                    <div tw="flex gap-[0.5rem] items-center">
                                        {name !== "population" && (
                                            <DynamicSvg
                                                iconName={name!.toLowerCase()}
                                            />
                                        )}
                                        <h3
                                            css={[
                                                tw`font-semiBold text-grey-600`,
                                                name !== "population" &&
                                                    tw`font-regular text-sm`
                                            ]}
                                        >
                                            {t(`${name!}.title`)}
                                        </h3>
                                    </div>
                                    <div tw="flex justify-between items-center gap-[0.5rem] font-semiBold">
                                        <DynamicSvg iconName="population" />
                                        <p tw="text-grey-600">{value}</p>
                                        <span
                                            css={[
                                                tw`text-sm text-grey-500`,
                                                name === "population" &&
                                                    tw`hidden`
                                            ]}
                                        >
                                            {percentage}%
                                        </span>
                                    </div>
                                </div>
                                <div
                                    css={[
                                        tw`flex justify-between items-center pt-[0.6rem] pb-[0.5rem]`,
                                        name === "population" && tw`p-0`
                                    ]}
                                >
                                    <span
                                        css={[
                                            tw`block w-[1rem] h-[1rem] rounded-[50%] ml-[0.25rem]`,
                                            name === "population" && tw`w-0 h-0`
                                        ]}
                                        style={{ backgroundColor: color }}
                                    ></span>
                                </div>
                            </div>
                            {innerGroups && (
                                <>
                                    <Inner
                                        tooltip={tooltip}
                                        data={innerGroups}
                                        setTooltip={setTooltip}
                                    />
                                </>
                            )}
                            {name === "Employed" && (
                                <button
                                    onClick={() =>
                                        setFilters({
                                            name: "mapEmployedStatus",
                                            value: name
                                        })
                                    }
                                    tw="my-[0.5rem] flex ml-auto items-center text-orange-700 uppercase font-semiBold text-sm gap-[0.5rem]"
                                >
                                    {t("open")}
                                    <img src={arrowOpen} alt="Arrow open" />
                                </button>
                            )}
                        </div>
                        {name === "population" && (
                            <PieChart data={data[0].data} colors={colors!} />
                        )}
                    </Fragment>
                )
            )}
        </div>
    );
};

export default Situations;
