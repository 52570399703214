import { Actions } from "types/global-state.types";
import { GlobalState } from "types/african-youth-context.types";

export const globalStateReducer = (
  state: GlobalState,
  { type, payload }: Actions
) => {
  switch (type) {
    case "SELECT_COUNTRY_FILTERS":
      if (payload.name === "comparison_country_2_section") {
        return {
          ...state,
          country: {
            ...state.country,
            [payload.name]: payload.value === "Africa" ? null : payload.value,
          },
        };
      } else {
        return {
          ...state,
          country: {
            ...state.country,
            hero_section: payload.value || "Africa",
            sectors_section: payload.value === "Africa" ? null : payload.value,
            trends_section: payload.value === "Africa" ? null : payload.value,
            education_section:
              payload.value === "Africa" ||
              ![
                "Kenya",
                "Rwanda",
                "Ghana",
                "Ethiopia",
                "Nigeria",
                "Uganda",
              ].includes(payload.value)
                ? null
                : payload.value,
            employment_section:
              payload.value === "Africa" ? null : payload.value,
            comparison_country_1_section:
              payload.value === "Africa" ? null : payload.value,
          },
        };
      }
    case "SELECT_AGE_FILTERS":
      return {
        ...state,
        ages: {
          ...state.ages,
          [payload.name]: payload.value,
        },
      };
    case "SELECT_FILTERS":
      return {
        ...state,
        [payload.name]: payload.value,
      };
    case "SELECT_FILTERS_MODAL":
      return {
        ...state,
        [payload.name]: {
          ...state.mobileModalDropdown,
          isMobileModalDropdownOpen:
            payload.section === state.mobileModalDropdown.section
              ? payload.value
              : true,
          section: payload.section,
          data: payload?.data,
        },
      };
    case "RESET_MOBILE_MODAL_DROPDOWN":
      return {
        ...state,
        mobileModalDropdown: {
          isMobileModalDropdownOpen: false,
          section: payload.value,
        },
      };
    default:
      throw new Error();
  }
};
