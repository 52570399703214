import { getCountryId } from "utils/countries";
import { countries } from "utils/lists";

export const getGender = (gender: string) =>
  gender === "males" ? "MALE" : "FEMALE";

export const getDisaggregated = (disaggregated: string) => {
  switch (disaggregated) {
    case "educational":
      return "EDUCATION_LEVEL";
    case "rural_urban_areas":
      return "TERRITORY_TYPE";
    case "jobs":
      return "JOB_FORM_TYPE";
    case "poor":
      return "POVERTY";
    case "sectors":
      return "SECTORS";
  }
};

export const getSubnationals = (country: string, year: string) => {
  if (country === "Ethiopia") {
    const ethiopianSubregions = countries.find(
      ({ iso3c }) => iso3c === getCountryId(country || "")
    )?.subnationals;
    if (
      ethiopianSubregions &&
      typeof ethiopianSubregions === "object" &&
      !Array.isArray(ethiopianSubregions) &&
      ethiopianSubregions.hasOwnProperty(year)
    )
      return ethiopianSubregions[year] as any;
    else return null;
  } else
    return countries.find(({ iso3c }) => iso3c === getCountryId(country || ""))
      ?.subnationals;
};
