import { t } from "i18next";
import { percentages } from "utils/employments";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const containerVariants = {
    no: tw`bg-yellow-600`,
    primary: tw`bg-yellow-400`,
    secondary: tw`bg-yellow-200`,
    tertiary: tw`bg-yellow-100`
};

const styles = {
    container: ({ variant = "no" }) => [
        tw`flex w-full`,
        containerVariants[variant as keyof typeof containerVariants]
    ]
};

const Table = ({
    data
}: {
    data: {
        sectorGroupName: string;
        educationLevels: {
            educationLevel: string;
            population: number;
            percentage: number;
        }[];
    }[];
}) => {
    return (
        <article tw="w-full m-auto flex flex-col gap-[2rem] relative max-w-[28rem]">
            {percentages.map((_percentage) => (
                <div
                    key={_percentage}
                    tw="border-b border-b-grey-100 first-of-type:mt-[-1.5rem]"
                >
                    <label tw="p-[2.5rem 1rem] sm:p-[2.5rem 1rem] text-[0.56rem] text-grey-400">
                        {_percentage}
                    </label>
                </div>
            ))}
            <div
                className="scrollable"
                tw="flex justify-evenly h-full absolute left-16 gap-4 w-full"
            >
                {data?.map((sector) => (
                    <div
                        key={sector.sectorGroupName}
                        tw="h-full text-yellow-800 font-medium mt-[-1.1rem]"
                    >
                        <img
                            src={`assets/images/sectors/${sector.sectorGroupName?.toLocaleLowerCase()}.svg`}
                            alt={`${sector.sectorGroupName} icon`}
                            tw="m-auto pb-[0.2rem]"
                            width={14}
                        />
                        {[...sector.educationLevels].reverse().map((levels) => (
                            <div
                                key={levels.percentage}
                                css={[
                                    tw`p-[0.25rem] w-[4rem] text-[0.56rem] flex items-center justify-center gap-[0.5rem]`,
                                    {
                                        height: `${levels.percentage.toFixed()}%`
                                    },
                                    styles.container({
                                        variant: levels.educationLevel
                                    })
                                ]}
                            >
                                <span>
                                    {levels.population.toLocaleString()}
                                </span>
                                <span tw="text-red-800">
                                    ({levels.percentage.toFixed(1)} %)
                                </span>
                            </div>
                        ))}
                        <h4 tw="w-[4rem] m-[0.5rem auto 0] text-center text-grey-600 text-xs">
                            {t(sector.sectorGroupName)}
                        </h4>
                    </div>
                ))}
            </div>
        </article>
    );
};

export default Table;
