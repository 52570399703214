import Header from "components/ActiveShare/Inner/Header";
import { DataSharedProps } from "types/data-shared.types";
import Info from "components/ActiveShare/Inner/Info";
import Table from "components/ActiveShare/Inner/Education/Container/Vertical/Table";
import Legend from "components/Main/Education/Legend";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Vertical = ({
    data,
    isShareModalVertical,
    isDesktop
}: {
    data: DataSharedProps;
    isShareModalVertical: boolean;
    isDesktop: boolean;
}) => (
    <>
        <Header
            isShareModalVertical={isShareModalVertical}
            isDesktop={isDesktop}
        />
        <main tw="p-0 h-[calc(100% - 33px)]">
            <section tw="p-[0.25rem 0.5rem 0] flex flex-col justify-between h-full pb-4">
                <Info isShareModalVertical={isShareModalVertical} data={data} />
                <Table data={data?.data} />
                <Legend
                    isFromShare={true}
                    isShareModalVertical={isShareModalVertical}
                />
                <p tw="text-[0.5rem] text-grey-400 mt-2">
                    https://africayouthjobs.io/
                </p>
            </section>
        </main>
    </>
);

export default Vertical;
