import { useAfricanYouth } from "context/africanYouth";
import { countries } from "utils/lists";

const useAutocomplete = () => {
    const { setCountryFilters, setFilters } = useAfricanYouth();

    const handleAutocompleteChange = (
        newValue: string,
        reason: string,
        section: string
    ) => {
        if (section.includes("_section")) {
            const selectedCountry = countries.find(
                (country) => country.name === newValue
            );
            setFilters({
                name: "regionMapSection",
                value: null
            });
            setFilters({
                name: "regionGendersSection",
                value: null
            });
            setFilters({
                name: "regionTrendsSection",
                value: null
            });
            setCountryFilters({
                value:
                    reason === "clear"
                        ? null
                        : selectedCountry?.name || newValue,
                name: section
            });
        } else
            setFilters({
                value: reason === "clear" ? null : newValue,
                name: section
            });
    };

    return { handleAutocompleteChange };
};

export default useAutocomplete;
