import { useAfricanYouth } from "context/africanYouth";
import { t } from "i18next";
import flag from "assets/images/flags/kenya.svg";
import arrowRight from "assets/images/arrowRightAlt.svg";
import { TooltipInfo } from "types/tooltip.types";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
import { statusList } from "utils/lists";
import { IndexKey } from "types/indexKey";
import { handleToLocaleString } from "utils/formatting";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Tooltip = ({ tooltipInfo }: TooltipInfo) => {
  const { ages } = useAfricanYouth();

  return (
    <article
      css={[
        tw`md:hidden bg-grey-800 rounded-[0.5rem] p-[0.5rem 1rem] w-full max-w-[20.5rem] fixed z-[1999] text-white`,
        tooltipInfo?.target.feature.employedNumber === 0 && tw`hidden`,
      ]}
      style={{
        left: `${tooltipInfo?.originalEvent?.clientX}px`,
        top: `${tooltipInfo?.originalEvent?.clientY}px`,
      }}>
      <div tw="flex justify-start items-center pb-[1.5rem]">
        {tooltipInfo?.target?.feature.file_url && (
          <img
            width={32}
            src={tooltipInfo?.target?.feature.file_url || flag}
            alt="Flag"
          />
        )}
        <h3 tw="font-medium text-xl text-white pl-[1rem]">
          {tooltipInfo?.target?.feature?.properties.name}
        </h3>
      </div>
      <div tw="pb-[1.688rem] text-[0.938rem] flex flex-col gap-[0.5rem]">
        <div tw="flex justify-between items-center">
          <div tw="flex gap-[0.25rem]">
            <DynamicSvg iconName="population" />
            <h4>{t("population.title")}</h4>
            <span tw="lowercase">{`${ages.from === null ? 15 : ages.from}-${
              ages.to === null ? 35 : ages.to
            } years`}</span>
          </div>
          <span tw="font-semiBold text-white">
            {handleToLocaleString(
              tooltipInfo?.target?.feature?.youthPopulation
            )}
          </span>
        </div>
        {statusList.map(({ id, name }) => (
          <div key={id} tw="flex justify-between items-center">
            <div tw="flex">
              <DynamicSvg iconName={id} />
              <p tw="pl-[0.5rem]">{t(`${name}.title`)}</p>
            </div>
            <div tw="text-white flex justify-between items-center gap-[0.7rem]">
              <h4 tw="font-semiBold">{`${tooltipInfo?.target?.feature[
                `${id}Number` as keyof IndexKey
              ].toLocaleString()}`}</h4>
              {Number(
                tooltipInfo?.target?.feature[
                  `${id}Percentage` as keyof IndexKey
                ]
              ).toFixed(1)}
              %
            </div>
          </div>
        ))}
      </div>
      <div tw="flex justify-between items-center text-orange-400 uppercase font-semiBold text-[0.938rem]">
        {t("click_to_explore")}
        <img src={arrowRight} alt="Arrow right" />
      </div>
    </article>
  );
};

export default Tooltip;
