import { t } from "i18next";
import toast from "react-hot-toast";
import { useAfricanYouth } from "context/africanYouth";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const handleCopyToClipboard = () => {
    const element = document.querySelector("#sharedUrl")!.textContent;
    navigator.clipboard
        .writeText(element!)
        .then(() => toast.success("Url copied to clipboard!"))
        .catch(() => toast.error("Failed to copy url, try again"));
};

const Widget = () => {
    const { sharedUrl } = useAfricanYouth();

    return (
        <div tw="text-white flex flex-col gap-[1rem] items-center self-center justify-self-center p-[1rem] text-center max-w-[23rem]">
            <h2 tw="font-medium">{t("shareWidget_header")}</h2>
            <p>{t("shareWidget_description")}</p>
            {sharedUrl && (
                <textarea
                    defaultValue={`<iframe src="${sharedUrl}" width="100%" height="700" title="African Youth Employment Clock Widget">
                </iframe>`}
                    disabled={true}
                    id="sharedUrl"
                    tw="w-full h-24 bg-[transparent] border border-white rounded p-[0.5rem]"
                ></textarea>
            )}
            <button
                onClick={handleCopyToClipboard}
                tw="uppercase flex gap-[1rem] items-center max-w-max bg-none text-white hover:bg-grey-500 hover:bg-opacity-80 font-semiBold border border-white py-2 px-5 rounded-[2.75rem]"
            >
                {t("copy")}
            </button>
        </div>
    );
};

export default Widget;
