import females from "assets/images/females.svg";
import males from "assets/images/males.svg";
import { t } from "i18next";
import { Fragment } from "react";
import { DataSharedGendersProps } from "types/data-shared.types";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const CarouselItems = ({ items }: { items: DataSharedGendersProps[] }) => (
    <div tw="flex flex-col gap-2">
        {items
            .filter((notUndefinedItem) => notUndefinedItem!)
            .map(({ name, population, percentage, genderPercentageMap }) => (
                <Fragment key={name}>
                    <div tw="flex justify-between items-center text-grey-600 text-[0.6rem] font-semiBold">
                        <h4>{t(name)}</h4>
                        <div tw="flex items-center gap-2">
                            <span>{population.toLocaleString()}</span>
                            <span tw="text-grey-500 text-[0.56rem]">
                                {percentage}%
                            </span>
                        </div>
                    </div>
                    <div tw="flex flex-col gap-[0.25rem] w-[76%]">
                        <div tw="flex items-center gap-1">
                            <img tw="w-4" src={males} alt="Males" />
                            <span
                                tw="block bg-green-600 h-[1rem] w-[2rem] lg:w-[4rem] md:w-[2rem] rounded-[0 0.25rem 0.25rem 0]"
                                style={{
                                    flex: `0 0 ${genderPercentageMap.male}%`
                                }}
                            ></span>
                            <span tw="text-[0.56rem] text-green-600 font-semiBold">
                                {genderPercentageMap.male}%
                            </span>
                        </div>
                        <div tw="flex items-center gap-1">
                            <img tw="w-4" src={females} alt="Females" />
                            <span
                                tw="block bg-yellow-500 h-[1rem] w-[2rem] lg:w-[4rem] md:w-[2rem] rounded-[0 0.25rem 0.25rem 0]"
                                style={{
                                    flex: `0 0 ${genderPercentageMap.female}%`
                                }}
                            ></span>
                            <span tw="text-[0.56rem] text-yellow-500 font-semiBold">
                                {genderPercentageMap.female}%
                            </span>
                        </div>
                    </div>
                    <span tw="bg-grey-100 h-[1px] block last-of-type:hidden"></span>
                </Fragment>
            ))}
    </div>
);

export default CarouselItems;
