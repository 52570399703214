import Header from "components/ActiveShare/Inner/Header";
import { DataSharedProps } from "types/data-shared.types";
import { t } from "i18next";
import Info from "components/ActiveShare/Inner/Info";
import Chart from "components/Main/Trends/Chart";
import Legend from "components/Main/Trends/Legend";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Horizontal = ({
    data,
    isShareModalVertical,
    isDesktop
}: {
    data: DataSharedProps;
    isShareModalVertical: boolean;
    isDesktop: boolean;
}) => (
    <>
        <Header
            isShareModalVertical={isShareModalVertical}
            isDesktop={isDesktop}
        />
        <main tw="p-4">
            <section tw="p-[0.25rem 0.5rem 0] flex justify-center gap-8 h-full flex-col lg:gap-[2rem]">
                <Info isShareModalVertical={isShareModalVertical} data={data} />
                <div tw="border border-grey-200 bg-white rounded-[0.5rem] p-[0.5rem] flex flex-col text-xs gap-1">
                    <p tw="text-grey-800 font-medium">
                        {!data.disaggregated && !data.sector
                            ? `Total ${t(`${data.status}.title`)}`
                            : t(
                                  data.disaggregated
                                      ? `${data.status}.trends.title${
                                            data.status === "Employed" ||
                                            data.status === "Unemployed"
                                                ? `_${data.disaggregated}`
                                                : ""
                                        }`
                                      : `${data.status}.title`
                              )}
                    </p>
                    <p tw="text-grey-400">
                        {t(
                            `${data.status}.trends.subTitle${
                                data.status === "Employed" &&
                                data.disaggregated === "jobs"
                                    ? `_jobs`
                                    : ""
                            }`
                        )}
                    </p>
                    <div
                        tw="items-center gap-[1.5rem] flex lg:flex-col lg:gap-[0.5rem]"
                        className="scrollable"
                    >
                        <Chart isFromShare={true} chartData={data.data} />
                        <Legend isFromShare={true} />
                    </div>
                </div>
                <p tw="text-[0.5rem] text-grey-400">
                    https://africayouthjobs.io/
                </p>
            </section>
        </main>
    </>
);

export default Horizontal;
