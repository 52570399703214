import domtoimage from "dom-to-image";
import { useState } from "react";
import toast from "react-hot-toast";
import { pngOptions } from "utils/utilities";

const useExportImage = () => {
    const [isExporting, setIsExporting] = useState(false);

    const handleImageToExport = async (element: any, fileName: string) => {
        setIsExporting(true);
        await domtoimage
            .toPng(element, pngOptions(element))
            .then((dataUrl: any) => downloadImage(dataUrl, fileName))
            .catch(() => toast.error("Image not exported correctly, try again"))
            .finally(() => setIsExporting(false));
    };

    const downloadImage = (image: any, fileName: string) => {
        const fakeLink = window.document.createElement("a");
        fakeLink.download = `${fileName}-@2x.png`;
        fakeLink.href = image;
        document.body.appendChild(fakeLink);
        fakeLink.click();

        document.body.removeChild(fakeLink);
        fakeLink.remove();
        toast.success("Image exported successfully");
    };

    return { isExporting, handleImageToExport };
};

export default useExportImage;
