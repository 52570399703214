import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Legend = () => (
    <div tw="w-full max-w-max p-[0.5rem 1rem] bg-white shadow rounded-[0.5rem] border border-grey-100 md:gap-[1.5rem] flex flex-col md:flex-row justify-between md:items-center absolute md:bottom-[1rem] md:left-[50%] bottom-[5rem] left-[5rem] lg:bottom-[1rem] lg:left-[1rem] md:translate-x-[-50%] text-sm text-grey-700 z-[401] sm:left-[1rem] sm:translate-x-0 sm:flex-col sm:gap-0 sm:items-start">
        <p tw="text-xs flex justify-start items-center gap-[0.5rem] md:pb-0 pb-[0.625rem]">
            <span tw="block bg-orange-700 w-[0.625rem] h-[0.625rem] rounded-[1.031rem] border border-grey-400"></span>{" "}
            {t("available")}
        </p>
        <p tw="text-xs flex justify-start items-center gap-[0.5rem] md:pb-0 pb-[0.625rem]">
            <span tw="block bg-orange-500 w-[0.625rem] h-[0.625rem] rounded-[1.031rem] border border-grey-400"></span>{" "}
            {t("coming_soon")}
        </p>
        <p tw="text-xs flex justify-start items-center gap-[0.5rem]">
            <span tw="block bg-orange-300 w-[0.625rem] h-[0.625rem] rounded-[1.031rem] border border-grey-400"></span>{" "}
            {t("unavailable")}
        </p>
    </div>
);

export default Legend;
