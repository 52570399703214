import { t } from "i18next";
import Filters from "components/Main/Sectors/Filters";
import Table from "components/Main/Sectors/Table";
import Loader from "components/Loader";
import { urls } from "config/urls";
import { useAfricanYouth } from "context/africanYouth";
import useFetch from "hooks/useFetch";
import { getCountryId } from "utils/countries";
import NoData from "components/NoData";
import ShareBtn from "components/Share";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import useWindowResize from "hooks/useWindowResize";

const SummarySection = () => {
  const {
    country,
    year,
    employment,
    regionGendersSection,
    genderStatus,
    ages,
  } = useAfricanYouth();
  const { isDevice } = useWindowResize();

  const { data, isLoading, hasError } = useFetch(
    `${urls.API_HOST_URL}gender?year=${year}&ageFrom=${ages.from || 15}&ageTo=${
      ages.to || 35
    }&countryCode=${getCountryId(country.sectors_section || "")}&sector=${
      genderStatus && genderStatus.toLocaleUpperCase()
    }${
      employment !== "both" ? `&jobType=${employment.toLocaleUpperCase()}` : ""
    }${regionGendersSection ? `&region=${regionGendersSection}` : ""}`,
    !country.sectors_section || !genderStatus
  );

  return (
    <section
      id="Genders"
      tw="p-[0 4rem 5rem] sm:px-[0] md:pb-8 bg-grey-50 border-t border-t-grey-200 relative">
      <div tw="border-b border-b-grey-200 sm:border-none mb-[2rem] flex justify-between gap-[1rem] items-center md:flex-col md:m-0 md:items-start p-[2rem 0.5rem 1rem] sm:pr-[1rem] sm:px-[1rem] ">
        <div tw="flex flex-col gap-[1rem]">
          <h2 tw="font-semiBold text-xl text-grey-600">{t("summary_title")}</h2>
          <p tw="text-grey-500 text-sm">{t("summary_subtitle")}</p>
        </div>
        {country.sectors_section && !isDevice && (
          <ShareBtn
            section="Genders"
            country={country.sectors_section!}
            region={regionGendersSection!}
            status={genderStatus!}
            employment={employment}
            ages={{ from: ages.from!, to: ages.to! }}
          />
        )}
      </div>
      <Filters />
      {!country.sectors_section ? null : isLoading ? (
        <Loader />
      ) : data?.length > 0 && genderStatus && !hasError ? (
        <Table data={data} />
      ) : !data || !genderStatus ? null : (
        <NoData />
      )}
      {country.sectors_section && isDevice && (
        <ShareBtn
          section="Genders"
          country={country.sectors_section!}
          region={regionGendersSection!}
          status={genderStatus!}
          employment={employment}
          ages={{ from: ages.from!, to: ages.to! }}
        />
      )}
    </section>
  );
};

export default SummarySection;
