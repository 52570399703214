import { useAfricanYouth } from "context/africanYouth";
import info from "assets/images/info.svg";
import { t } from "i18next";
import FlipNumbers from "react-flip-numbers";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AutoCompleteStyles, AutoCompleteStylesHero } from "utils/autoComplete";
import { Country } from "types/country.types";
import useAutocomplete from "hooks/useAutocomplete";
import useWindowResize from "hooks/useWindowResize";
import { countries } from "utils/lists";
import { useMemo } from "react";
import Warning from "components/Warning";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

let flipNumberStyles = {
  color: theme`colors.gradient`,
  fontSize: "6.5rem",
  fontWeight: 800,
  fontFamily: "Work Sans",
  perspective: 100000,
  height: "6.5rem",
  lineHeight: "6.5rem",
};

const Header = ({ tickingValue }: { tickingValue: string | number }) => {
  const { ages, country, mobileModalDropdown, setMobileModalDropdown } =
    useAfricanYouth();
  const { handleAutocompleteChange } = useAutocomplete();
  const { isDesktop } = useWindowResize();
  flipNumberStyles = {
    ...flipNumberStyles,
    height: isDesktop ? "6.5rem" : "4rem",
    lineHeight: isDesktop ? "6.5rem" : "4.5rem",
    fontSize: isDesktop ? "6.5rem" : "4rem",
  };

  const countryList = useMemo(() => {
    return [
      {
        iso3c: "AFR",
        name: "Africa",
        label: "Africa",
        license: "Public domain",
        file_url: "",
        url: "",
      },
      ...countries,
    ];
  }, []);

  const memoInput = useMemo(
    () => (
      <Autocomplete
        readOnly={isDesktop ? false : true}
        disableClearable={true}
        options={countryList}
        autoHighlight
        onChange={(_, value, reason) =>
          handleAutocompleteChange(value?.name!, reason, "hero_section")
        }
        value={
          country.hero_section ? { name: country.hero_section } : (null as any)
        }
        sx={{ ...AutoCompleteStyles, ...AutoCompleteStylesHero }}
        getOptionLabel={(option) => option.name}
        renderOption={(props: any, option: Country) => (
          <button
            {...props}
            style={{
              color: theme`colors.grey.700`,
              display: "flex",
              fontSize: "0.8rem",
              fontWeight: 700,
              gap: "0.65rem",
              width: "100%",
              textAlign: "left",
            }}>
            {option.file_url ? (
              <img
                loading="lazy"
                width="16"
                src={option.file_url}
                alt={option.name}
              />
            ) : (
              <span style={{ width: "1rem" }}></span>
            )}
            {option.name}
          </button>
        )}
        renderInput={(params: any) => {
          const activeCountryFlag = countryList.find(
            (option) => option.name === params.inputProps.value
          );

          return (
            <div
              tw="flex items-center relative"
              onClick={() =>
                isDesktop
                  ? undefined
                  : setMobileModalDropdown({
                      value: !mobileModalDropdown.isMobileModalDropdownOpen,
                      name: "mobileModalDropdown",
                      section: "countries/hero_section",
                    })
              }>
              {activeCountryFlag && (
                <img
                  key={params.inputProps.value + "'s flag"}
                  loading="lazy"
                  width="24"
                  src={activeCountryFlag.file_url}
                  alt="Country flag"
                  css={[
                    tw`absolute left-[0.5rem] z-10`,
                    params.inputProps.value === "Africa" && tw`hidden`,
                  ]}
                />
              )}
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                sx={{
                  textAlign: "left",
                  paddingLeft:
                    params.inputProps.value && country.hero_section !== "Africa"
                      ? "2rem"
                      : 0,
                  input: {
                    "&::placeholder": {
                      color: "#ADADAD !important",
                    },
                  },
                }}
                placeholder={t(
                  "type_or_select_a_region_placeholder_hero_section"
                )}
              />
            </div>
          );
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [country.hero_section, isDesktop]
  );

  return (
    <article tw="flex flex-col gap-[1rem] items-start md:items-center md:pr-[1rem]">
      <div tw="flex justify-between items-center gap-[1rem] text-xl lg:text-lg">
        <span tw="font-medium text-grey-500">{t("hero_section_today")}</span>
        <div tw="flex gap-2 items-center">
          {memoInput}
          <Warning countryName={country.hero_section!} />
        </div>
        <span tw="font-medium text-grey-500">{t("hero_section_has")}</span>
      </div>
      {!isNaN(Number(tickingValue)) && (
        <FlipNumbers
          height={isDesktop ? 100 : 65}
          width={isDesktop ? 72 : 36}
          color={theme`colors.gradient`}
          play
          perspective={flipNumberStyles.perspective}
          numbers={
            tickingValue
              ? tickingValue.toLocaleString().replaceAll(".", ",")
              : ""
          }
          numberStyle={flipNumberStyles}
          nonNumberStyle={flipNumberStyles}
        />
      )}
      <h2 tw="flex justify-between items-center gap-[1rem] font-medium text-grey-500 md:flex-col max-w-max w-full text-xl lg:text-lg">
        <span tw="flex gap-[1rem] items-center">
          {t("hero_section_young_working_persons")}{" "}
          {`${ages.from ? ages.from : 15}-${ages.to ? ages.to : 35}`}{" "}
          {t("hero_section_young_working_years")}
          <button
            tw="relative"
            className={isDesktop ? "group" : ""}
            onClick={() =>
              isDesktop
                ? undefined
                : setMobileModalDropdown({
                    value: !mobileModalDropdown.isMobileModalDropdownOpen,
                    name: "mobileModalDropdown",
                    section: "hero_section_info",
                  })
            }>
            <img src={info} alt="Info" />
            <span tw="absolute hidden group-hover:flex items-center -top-2/4 right-0 translate-x-2/4 max-w-[max-content] bg-grey-500 rounded-[0.5rem] text-white p-[0.5rem] w-[15rem] -translate-y-full text-sm text-left z-10">
              {t("hero_section_info.text")}
            </span>
          </button>
        </span>
        <span>{t("hero_section_young_groups")}</span>
      </h2>
    </article>
  );
};

export default Header;
