import { useAfricanYouth } from "context/africanYouth";
import { SharedUrltypes } from "types/share-url.types";

export const params: Record<string, any> = new URLSearchParams(
    window.location.search
);

export const getURLSearchParam = (value: string) => params.get(value);

const useShareUrl = () => {
    const { setFilters } = useAfricanYouth();

    const handleShareUrl = ({
        ages,
        section,
        country,
        comparedCountry,
        year,
        gender,
        status,
        region,
        employment,
        disaggregated,
        sector
    }: SharedUrltypes) => {
        const url = `${window.location.origin}${window.location.pathname}?${
            country ? `country=${country}&` : ""
        }${comparedCountry ? `comparedCountry=${comparedCountry}&` : ""}${
            region ? `region=${region}&` : ""
        }${status ? `status=${status}&` : ""}${
            ages ? `agesFrom=${ages.from}&agesTo=${ages.to}&` : ""
        }${year ? `year=${year}&` : ""}${gender ? `gender=${gender}&` : ""}${
            sector ? `sector=${sector}&` : ""
        }${disaggregated ? `disaggregatedBy=${disaggregated}&` : ""}${
            employment ? `employment=${employment}&` : ""
        }${section ? `section=${section}` : ""}`;

        setFilters({ name: "sharedUrl", value: url });
    };

    return handleShareUrl;
};

export default useShareUrl;
