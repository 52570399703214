import { useAfricanYouth } from "context/africanYouth";
import { t } from "i18next";
import forecast from "assets/images/forcastData.svg";
import { getActiveTrendsList } from "utils/trends";
import { statusList } from "utils/lists";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw, { theme } from "twin.macro";

const Legend = ({
    isFromShare,
    isShareModalVertical
}: {
    isFromShare?: boolean;
    isShareModalVertical?: boolean;
    isDesktop?: boolean;
}) => {
    const { status, disaggregated, trendsEmployedSector } = useAfricanYouth();

    return !disaggregated && !trendsEmployedSector ? (
        <div
            css={[
                tw`text-sm text-grey-600 bg-white lg:p-[0.25rem] flex flex-col gap-[1rem] max-w-max lg:border lg:border-grey-100 lg:shadow lg:flex-row rounded-[0.5rem] sm:mr-[1rem] md:flex-wrap`,
                isFromShare && tw`text-[0.56rem] gap-2 flex-wrap`,
                isShareModalVertical &&
                    tw`gap-0 p-[0.25rem] border-grey-200 border shadow w-full max-w-none`
            ]}
        >
            <div tw="flex items-center gap-[0.5rem]">
                <span
                    css={[
                        tw`block w-[1rem] h-[1rem] rounded-[50%]`,
                        isFromShare && tw`w-2 h-2`
                    ]}
                    style={{
                        background: statusList.find(
                            ({ name }) => name === status
                        )?.color
                    }}
                ></span>
                <h4>Total {t(`${status}.title`)} data</h4>
            </div>
            <div tw="flex items-center gap-[0.5rem]">
                <span
                    css={[
                        tw`block w-[1rem] h-[1rem] rounded-[50%] border border-grey-300`,
                        isFromShare && tw`w-2 h-2`
                    ]}
                >
                    <img
                        width={isFromShare ? 8 : 16}
                        src={forecast}
                        alt="forecast"
                        tw="rounded-[50%]"
                    />
                </span>
                <h4>{t("forecast_data")}</h4>
            </div>
        </div>
    ) : getActiveTrendsList(status, disaggregated) ? (
        <div
            css={[
                tw`text-sm text-grey-600 bg-white lg:p-[0.25rem] flex flex-col gap-[1rem] max-w-max lg:border lg:border-grey-100 lg:shadow lg:flex-row rounded-[0.5rem] sm:mr-[1rem] md:flex-wrap`,
                isFromShare &&
                    tw`text-[0.56rem] gap-2 overflow-x-scroll flex-wrap`,
                isShareModalVertical &&
                    tw`gap-0 p-[0.25rem] border-grey-200 border shadow w-full max-w-none`
            ]}
        >
            {getActiveTrendsList(status, disaggregated)?.map(
                ({ name, color }) => (
                    <div key={color} tw="flex items-center gap-[0.5rem]">
                        <span
                            css={[
                                tw`block w-[1rem] h-[1rem] rounded-[50%]`,
                                isFromShare && tw`w-2 h-2`
                            ]}
                            style={{ background: color }}
                        ></span>
                        <h4>{t(`${status}.trends.list.${name}`)}</h4>
                    </div>
                )
            )}
            <div tw="flex items-center gap-[0.5rem]">
                <span
                    css={[
                        tw`block w-[1rem] h-[1rem] rounded-[50%] border border-grey-300`,
                        isFromShare && tw`w-2 h-2`
                    ]}
                >
                    <img
                        width={isFromShare ? 8 : 16}
                        src={forecast}
                        alt="forecast"
                        tw="rounded-[50%]"
                    />
                </span>
                <h4>{t("forecast_data")}</h4>
            </div>
        </div>
    ) : trendsEmployedSector ? (
        <div
            css={[
                tw`text-sm text-grey-600 bg-white lg:p-[0.25rem] flex flex-col gap-[1rem] max-w-max lg:border lg:border-grey-100 lg:shadow lg:flex-row rounded-[0.5rem] sm:mr-[1rem] md:flex-wrap`,
                isFromShare && tw`text-[0.56rem] gap-2 flex-wrap`,
                isShareModalVertical &&
                    tw`gap-0 p-[0.25rem] border-grey-200 border shadow w-full max-w-none`
            ]}
        >
            <div
                key={theme`colors.green.400`}
                tw="flex items-center gap-[0.5rem]"
            >
                <span
                    css={[
                        tw`block w-[1rem] h-[1rem] rounded-[50%]`,
                        isFromShare && tw`w-2 h-2`
                    ]}
                    style={{ background: theme`colors.green.400` }}
                ></span>
                <h4>{t(`${trendsEmployedSector}.title`)}</h4>
            </div>
            <div tw="flex items-center gap-[0.5rem]">
                <span
                    css={[
                        tw`block w-[1rem] h-[1rem] rounded-[50%] border border-grey-300`,
                        isFromShare && tw`w-2 h-2`
                    ]}
                >
                    <img
                        width={isFromShare ? 8 : 16}
                        src={forecast}
                        alt="forecast"
                        tw="rounded-[50%]"
                    />
                </span>
                <h4>{t("forecast_data")}</h4>
            </div>
        </div>
    ) : null;
};

export default Legend;
