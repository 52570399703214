import { t } from "i18next";
import info from "assets/images/info.svg";
import arrowDown from "assets/images/openArrowDropDown.svg";
import useToggle from "hooks/useToggle";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
import useWindowResize from "hooks/useWindowResize";
import { useAfricanYouth } from "context/africanYouth";
import {
  CompareSituationsProps,
  CountryComparisonType,
} from "types/compare-situation.types";
import { useEffect, useState } from "react";
import { mapCountryComparison } from "utils/countryComparison";
import { getCountryInfo } from "utils/countries";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { formatNumber } from "utils/formatting";

const Table = ({
  comparisonCountry1,
  comparisonCountry2,
}: {
  comparisonCountry1: CountryComparisonType;
  comparisonCountry2: CountryComparisonType;
}) => {
  const { setMobileModalDropdown, mobileModalDropdown, country } =
    useAfricanYouth();
  const [comparisonData, setComparisonData] =
    useState<CompareSituationsProps[]>();
  const { handleToggleState, toggleState } = useToggle();
  const { isDesktop } = useWindowResize();

  useEffect(() => {
    const comparisonData = mapCountryComparison(
      comparisonCountry1,
      comparisonCountry2
    );
    setComparisonData(comparisonData);
  }, [comparisonCountry1, comparisonCountry2]);

  if (!comparisonData) {
    return <></>;
  }

  return (
    <article tw="max-w-[70rem] m-[1rem auto]  w-full sm:border-t sm:border-none sm:border-t-grey-200 border border-grey-200 sm:rounded-none rounded-[0.5rem] sm:m-0">
      <div tw="sm:mt-[2rem] mt-[1rem] sm:px-[1rem] sm:border-t-grey-200 sm:border-t">
        <div tw="xxs:text-xs sm:text-sm grid grid-cols-[repeat(3, 1fr)] items-center justify-center lg:p-[1rem 2rem] p-[2rem 6rem 1rem] sm:p-4 gap-[0.25rem]">
          <div tw="font-semiBold text-grey-500 text-sm items-end flex flex-col gap-[0.5rem]">
            {country.comparison_country_1_section && (
              <img
                key={country.comparison_country_1_section}
                loading="lazy"
                width={32}
                src={
                  getCountryInfo(country.comparison_country_1_section)!.file_url
                }
                alt={`${country.comparison_country_1_section} flag`}
              />
            )}
            <span>{t(country.comparison_country_1_section || "")}</span>
          </div>
          <span tw="flex items-center p-[1rem] rounded-[50%] uppercase border border-purple-200 w-[2rem] justify-center h-[2rem] text-purple-800 justify-self-center text-[0.688rem]">
            vs
          </span>
          <div tw="font-semiBold text-grey-500 text-sm items-start flex flex-col gap-[0.5rem]">
            {country.comparison_country_2_section && (
              <img
                key={country.comparison_country_2_section}
                loading="lazy"
                width={32}
                src={
                  getCountryInfo(country.comparison_country_2_section)!.file_url
                }
                alt={`${country.comparison_country_2_section} flag`}
              />
            )}
            <span>{t(country.comparison_country_2_section || "")}</span>
          </div>
        </div>
      </div>
      {comparisonData.map((situation) => (
        <div
          key={situation.name}
          tw="border-b border-b-grey-200 last-of-type:border-none"
          css={[
            (situation.name === "student" ||
              situation.name === "formal_jobs") &&
              tw`border-t border-t-grey-200`,
            (situation.name === "employed" ||
              situation.name === "unemployed") &&
              tw`border-none`,
            situation.open ? tw`border-none` : null,
            situation.name === "formal_jobs" &&
              tw`border-b-0 m-[1rem 6rem 0] md:m-4`,
          ]}>
          <div
            tw="xxs:text-xs sm:text-sm grid grid-cols-[repeat(3, 1fr)] items-center justify-center lg:p-[1rem 2rem] p-[1rem 6rem] sm:p-4 gap-[0.25rem]"
            css={[
              situation.name === "formal_jobs" && tw`p-[1rem 0]`,
              situation.name === "formal_jobs" && tw`lg:p-[1rem] md:p-[1rem 0]`,
            ]}>
            <div tw="flex flex-col gap-[0.25rem] text-right ">
              <p css={[situation.name === "working_poverty" && tw`hidden`]}>
                {(situation.country1?.number || 0).toLocaleString()}{" "}
              </p>
              <div
                tw="flex self-end h-[0.5rem] bg-green-600 rounded-tl rounded-bl"
                css={[
                  situation.name === "formal_jobs" ||
                  situation.name === "urban_areas" ||
                  situation.name === "urban_areas_employed"
                    ? tw`bg-green-400`
                    : null,
                  situation.name === "working_poverty" && tw`hidden`,
                  situation.name === "employed" && tw`bg-transparent`,
                ]}
                style={{
                  width: `${
                    situation.name === "population"
                      ? "100"
                      : situation.country1.percentage
                  }%`,
                }}>
                {situation.name === "employed" &&
                  situation.openSituations &&
                  situation.openSituations.map(({ country1 }) => (
                    <div
                      key={country1.color}
                      tw="first-of-type:rounded-l"
                      style={{
                        width: `${country1.percentage}%`,
                        background: country1.color,
                      }}></div>
                  ))}
              </div>
              {situation.name !== "working_poverty" &&
                situation.country1.percentage && (
                  <span tw="text-grey-500 text-[0.813rem]">
                    {formatNumber(situation.country1.percentage)}%{" "}
                    {situation.name === "population" && t("total_youth")}
                  </span>
                )}
            </div>
            <div tw="flex items-center justify-center gap-[0.5rem]">
              <div tw="flex flex-col items-center gap-[0.5rem] text-center ">
                {situation.name === "working_poverty" ||
                situation.name === "formal_jobs" ||
                situation.name === "urban_areas" ||
                situation.name === "urban_areas_employed" ? null : (
                  <DynamicSvg iconName={situation.name} />
                )}
                <h3
                  tw="font-semiBold xxs:text-xs sm:text-sm text-[1.063rem] text-grey-500 lg:w-[12rem] md:w-[8rem] sm:w-[6.5rem] xxs:w-[4rem] flex justify-center capitalize"
                  css={[
                    situation.name === "working_poverty" ||
                    situation.name === "formal_jobs" ||
                    situation.name === "urban_areas" ||
                    situation.name === "urban_areas_employed"
                      ? tw`text-[0.9rem]`
                      : null,
                  ]}>
                  {t(
                    situation.name === "population"
                      ? `${situation.name}.title`
                      : situation.name
                  )}
                </h3>
              </div>
              {situation.name === "working_poverty" ||
              situation.name === "formal_jobs" ? (
                <button
                  tw="relative w-[25px]"
                  className={isDesktop ? "group" : ""}
                  onClick={() =>
                    isDesktop
                      ? undefined
                      : setMobileModalDropdown({
                          value: !mobileModalDropdown.isMobileModalDropdownOpen,
                          name: "mobileModalDropdown",
                          section: `${situation.name}_info`,
                        })
                  }>
                  <img width={25} src={info} alt="Info" />
                  <span tw="absolute hidden group-hover:flex justify-center -top-2/4 right-0 translate-x-2/4 max-w-[max-content] bg-grey-500 rounded-[0.5rem] text-white p-[0.5rem] w-[15rem] -translate-y-full text-sm text-left z-10">
                    {t(`${situation.name}_info.text`)}
                  </span>
                </button>
              ) : null}
            </div>
            <div tw="flex flex-col gap-[0.25rem] ">
              <p css={[situation.name === "working_poverty" && tw`hidden`]}>
                {(situation.country2?.number || 0).toLocaleString()}
              </p>
              <div
                tw="flex self-start h-[0.5rem] bg-yellow-400 rounded-tr rounded-br"
                css={[
                  situation.name === "formal_jobs" ||
                  situation.name === "urban_areas" ||
                  situation.name === "urban_areas_employed"
                    ? tw`bg-yellow-300`
                    : null,
                  situation.name === "working_poverty" && tw`hidden`,
                  situation.name === "employed" && tw`bg-transparent`,
                ]}
                style={{
                  width: `${
                    situation.name === "population"
                      ? "100"
                      : situation.country2.percentage
                  }%`,
                }}>
                {situation.name === "employed" &&
                  situation.openSituations &&
                  situation.openSituations.map(({ country2 }) => (
                    <div
                      key={country2.color}
                      tw="last-of-type:rounded-r"
                      style={{
                        width: `${country2.percentage}%`,
                        background: country2.color,
                      }}></div>
                  ))}
              </div>
              {situation.name !== "working_poverty" &&
                situation.country2.percentage && (
                  <span tw="text-grey-500 text-[0.813rem]">
                    {formatNumber(situation.country2.percentage)}%{" "}
                    {situation.name === "population" && t("total_youth")}
                  </span>
                )}
            </div>
          </div>
          {situation.open && (
            <button
              tw="p-[0 1rem] hover:bg-grey-50 hover:rounded-[0.5rem] flex gap-[0.75rem] items-center max-w-max m-[ 0 auto 1rem] font-semiBold text-orange-700 text-[0.938rem] uppercase sm:text-xs sm:p-[0.5rem]"
              css={[situation.name === "working_poverty" && tw`hidden`]}
              onClick={() => handleToggleState(`${situation.name}_comparison`)}>
              {(toggleState as any)[`${situation.name}_comparison`]
                ? t("close_subsectors")
                : t("open_subsectors")}
              <img
                src={arrowDown}
                alt="Arrow"
                width={20}
                css={[
                  (toggleState as any)[`${situation.name}_comparison`] &&
                    tw`rotate-180`,
                ]}
              />
            </button>
          )}
          {situation.name === "working_poverty" &&
            situation.open &&
            situation.openSituations &&
            situation.openSituations.map(({ name, country1, country2 }) => (
              <div
                key={name}
                tw="xxs:text-xs sm:text-sm text-sm text-grey-600 sm:p-[0.25rem 1rem] max-w-[70rem] grid grid-cols-[repeat(3, 1fr)] sm:grid-cols-[1fr 9rem 1fr] xs:grid-cols-[1fr 6rem 1fr] xxs:grid-cols-[1fr 5rem 1fr] gap-1 items-center justify-center lg:p-[0.5rem 2rem] xs:p-[0.25rem 0] p-[1rem 6rem]">
                <div tw="flex gap-1 items-center justify-end">
                  {country1.percentage && (
                    <span tw="text-grey-500 text-[0.813rem]">
                      {formatNumber(country1.percentage)}%
                    </span>
                  )}
                  <p tw="font-semiBold">{country1.number!.toLocaleString()}</p>
                  <span
                    tw="w-3 h-3 rounded-full"
                    style={{ background: country1.color }}></span>
                </div>
                <div tw="flex flex-col items-center gap-[0.5rem] text-center ">
                  <h3 tw="xxs:text-xs sm:text-sm text-center lg:w-[12rem] md:w-[8rem] sm:w-[6.5rem] xxs:w-[4rem]">
                    {t(name)}
                  </h3>
                </div>
                <div tw="flex gap-1 items-center justify-start">
                  <span
                    tw="w-3 h-3 rounded-full"
                    style={{ background: country2.color }}></span>
                  <p tw="font-semiBold">{country2.number!.toLocaleString()}</p>
                  {country2.percentage && (
                    <span tw="text-grey-500 text-[0.813rem]">
                      {formatNumber(country2.percentage)}%
                    </span>
                  )}
                </div>
              </div>
            ))}
          {situation.open &&
            situation.openSituations &&
            (toggleState as any)[`${situation.name}_comparison`] &&
            situation.openSituations.map(
              ({ name, country1, country2, open }) => (
                <div
                  key={name}
                  tw="xxs:text-xs sm:text-sm text-sm font-semiBold border-b border-b-[rgba(0, 0, 0, 0.12)] text-grey-600 last-of-type:border-none  sm:p-[1rem] bg-orange-50 max-w-[70rem]">
                  <div tw="grid grid-cols-[repeat(3, 1fr)] items-center justify-center p-[1rem 6rem] md:p-[1rem 2rem] sm:p-0 gap-[0.25rem]">
                    <div tw="flex flex-col gap-[0.25rem] text-right ">
                      <p>{country1.number!.toLocaleString()}</p>
                      <div
                        tw="flex self-end h-[0.5rem] bg-green-600 rounded-tl rounded-bl"
                        css={[situation.open && tw`bg-green-400`]}
                        style={{
                          width: `${country1.percentage}%`,
                        }}></div>
                      {country1.percentage && (
                        <span tw="text-grey-500 text-[0.813rem]">
                          {formatNumber(country1.percentage)}%
                        </span>
                      )}
                    </div>
                    <div tw="flex flex-col items-center gap-[0.5rem] text-center ">
                      <DynamicSvg iconName={name} />
                      <h3 tw="xxs:text-xs sm:text-sm text-center lg:w-[12rem] md:w-[8rem] xxs:w-[4rem]">
                        {t(name)}
                      </h3>
                    </div>
                    <div tw="flex flex-col gap-[0.25rem] ">
                      <p>{country2.number!.toLocaleString()}</p>
                      <div
                        tw="flex self-start h-[0.5rem] bg-yellow-400 rounded-tr rounded-br"
                        css={[open && tw`bg-yellow-300`]}
                        style={{
                          width: `${country2.percentage}%`,
                        }}></div>
                      {country2.percentage && (
                        <span tw="text-grey-500 text-[0.813rem]">
                          {formatNumber(country2.percentage)}%
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
      ))}
    </article>
  );
};

export default Table;
