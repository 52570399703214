import warning from "assets/images/warning.svg";
import { t } from "i18next";
import { isEthiopiaSelected } from "utils/utilities";
import useWindowResize from "hooks/useWindowResize";
import { useAfricanYouth } from "context/africanYouth";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Warning = ({ countryName }: { countryName: string }) => {
  const { mobileModalDropdown, setMobileModalDropdown } = useAfricanYouth();
  const { isDesktop } = useWindowResize();

  return isEthiopiaSelected(countryName) ? (
    <button
      tw="relative z-[1999]"
      className={isDesktop ? "group" : ""}
      onClick={() =>
        isDesktop
          ? undefined
          : setMobileModalDropdown({
              value: !mobileModalDropdown.isMobileModalDropdownOpen,
              name: "mobileModalDropdown",
              section: "ethiopia_info",
            })
      }>
      <img src={warning} alt="Warning" />
      <p tw="absolute hidden text-white text-xs max-w-max group-hover:block bg-grey-500 p-4 rounded whitespace-nowrap text-left">
        <strong>{t("warning_ethiopia_title")}</strong>
        <br />
        <strong>{t("warning_ethiopia_subtitle")}</strong>
        {t("warning_ethiopia_subtitle_1")} <strong>{t("Tigray")}</strong>
        <br />
        {t("warning_ethiopia_subtitle_2")}
        <br />
        {t("warning_ethiopia_subtitle_3")}
        <br />
        <strong>{t("warning_ethiopia_description")}</strong>
        {t("warning_ethiopia_description_1")} <br />
        {t("warning_ethiopia_description_1_1")} <strong>{t("Sidama")}</strong>{" "}
        <br />
        {t("warning_ethiopia_description_2")} <strong>{t("Southwest")}</strong>
        <br />
        {t("warning_ethiopia_description_3")} <strong>{t("central")}</strong>
      </p>
    </button>
  ) : null;
};

export default Warning;
