import { t } from "i18next";
import { useAfricanYouth } from "context/africanYouth";
import Table from "components/Main/CountryComparison/Table";
import Filters from "components/Main/CountryComparison/Filters";
import Loader from "components/Loader";
import { urls } from "config/urls";
import { getCountryId } from "utils/countries";
import useFetch from "hooks/useFetch";
import { getGender } from "utils/filters";
import NoData from "components/NoData";
import ShareBtn from "components/Share";
import useWindowResize from "hooks/useWindowResize";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const CountryComparison = () => {
  const { country, year, ages, gender } = useAfricanYouth();
  const { isDevice } = useWindowResize();

  const {
    data: comparisonCountry1,
    isLoading: isLoadingComparisonCountry1,
    hasError,
  } = useFetch(
    `${urls.API_HOST_URL}countries/${getCountryId(
      country.comparison_country_1_section || ""
    )}?year=${year}&ageFrom=${ages.from || 15}&ageTo=${ages.to || 35}${
      gender !== "all" ? `&genderFilter=${getGender(gender)}` : ""
    }
    `,
    !country.comparison_country_1_section
  );

  const {
    data: comparisonCountry2,
    isLoading: isLoadingComparisonCountry2,
    hasError: hasError2,
  } = useFetch(
    `${urls.API_HOST_URL}countries/${getCountryId(
      country.comparison_country_2_section || ""
    )}?year=${year}&ageFrom=${ages.from || 15}&ageTo=${ages.to || 35}${
      gender !== "all" ? `&genderFilter=${getGender(gender)}` : ""
    }
    `,
    !country.comparison_country_2_section
  );

  return (
    <section
      id="CountryComparison"
      tw="bg-white p-[0 4rem 5rem] sm:p-[0 0 2rem 0] md:pb-8 relative">
      <div tw="border-b border-b-grey-200 sm:border-none mb-[2rem] flex justify-between gap-[1rem] items-center md:flex-col md:m-0 md:items-start p-[2rem 1rem 1rem]">
        <div tw="flex flex-col gap-[1rem]">
          <h2 tw="font-semiBold text-xl text-grey-600">
            {t("country_comparison")}
          </h2>
          <p tw="text-grey-500 text-sm">
            {t("country_comparison_description")}
          </p>
        </div>
        {country.comparison_country_1_section &&
          country.comparison_country_2_section &&
          !isDevice && (
            <ShareBtn
              section="CountryComparison"
              country={country.comparison_country_1_section!}
              comparedCountry={country.comparison_country_2_section!}
              ages={{ from: ages.from!, to: ages.to! }}
            />
          )}
      </div>
      <Filters />
      {!country.comparison_country_1_section ||
      !country.comparison_country_2_section ? null : isLoadingComparisonCountry1 ||
        isLoadingComparisonCountry2 ? (
        <div tw="block relative m-[1rem]">
          <Loader />
        </div>
      ) : comparisonCountry1 &&
        comparisonCountry2 &&
        !hasError &&
        !hasError2 ? (
        <Table
          comparisonCountry1={comparisonCountry1}
          comparisonCountry2={comparisonCountry2}
        />
      ) : (
        <NoData />
      )}
      {country.comparison_country_1_section &&
        country.comparison_country_2_section &&
        isDevice && (
          <ShareBtn
            section="CountryComparison"
            country={country.comparison_country_1_section!}
            comparedCountry={country.comparison_country_2_section!}
            ages={{ from: ages.from!, to: ages.to! }}
          />
        )}
    </section>
  );
};

export default CountryComparison;
