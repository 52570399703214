import { t } from "i18next";
import logo from "assets/images/footer/footerLogo.svg";
import close from "assets/images/closeMenu.svg";
import facebook from "assets/images/footer/facebook.svg";
import globe from "assets/images/footer/globe.svg";
import instagram from "assets/images/footer/instagram.svg";
import linkedIn from "assets/images/footer/linkedIn.svg";
import twitter from "assets/images/footer/twitter.svg";
import { links } from "utils/lists";
import { handleLinks } from "utils/formatting";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const NavbarMobile = ({ handleToggleState, toggleState }: any) => (
    <nav
        css={[
            tw`hidden lg:flex flex-col fixed w-full left-0 top-0 h-auto z-[1004] justify-between translate-y-[-100%] transition-transform duration-500`,
            toggleState.isNavbarOpened && tw`translate-y-0 h-screen`
        ]}
    >
        <div tw="flex items-center justify-between p-[1.5rem 4rem] sm:p-[1rem] bg-orange-600 box-border shadow">
            <div tw="flex items-center gap-[1rem] text-orange-100">
                <img src={logo} alt="Social Protection Clock" />
                <h1 tw="font-regular text-[0.625rem] uppercase">
                    {t("title")}
                    <span tw="block">{t("sub_title")}</span>
                </h1>
            </div>
            <button onClick={() => handleToggleState("isNavbarOpened")}>
                <img src={close} alt="Close" />
            </button>
        </div>
        <div tw="flex flex-col bg-white px-[4rem] flex-1 sm:px-[1rem]">
            <ul tw="text-center flex flex-col flex-1 justify-evenly gap-[0.5rem] py-[1rem] sm:p-0">
                {handleLinks(links).map(({ name, id }) => (
                    <li
                        key={id}
                        tw="text-orange-700 text-[1.313rem] font-medium"
                        onClick={() => handleToggleState("isNavbarOpened")}
                    >
                        <a href={id}>{t(name)}</a>
                    </li>
                ))}
            </ul>
        </div>
        <div tw="p-[1rem 4rem] sm:p-[0.5rem 1rem] text-center bg-orange-600 text-orange-100">
            <h3 tw="tracking-[0.2em] uppercase pb-[1rem]">
                {t("footer_contact_us")}
            </h3>
            <a href="mailto:ayec@worlddata.io">ayec@worlddata.io</a>
            <ul tw="flex justify-between items-center gap-[1rem] pt-[1rem]">
                <li>
                    <a href="https://worlddata.io/">
                        <img src={globe} alt="Website" />
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/company/world-data-lab">
                        <img src={linkedIn} alt="LinkedIn" />
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/worlddatalab">
                        <img src={twitter} alt="Twitter" />
                    </a>
                </li>
                <li>
                    <a href="https://www.facebook.com/worlddatalab/">
                        <img src={facebook} alt="Facebook" />
                    </a>
                </li>
                <li>
                    <a href="https://instagram.com/worlddatalab">
                        <img src={instagram} alt="Instagram" />
                    </a>
                </li>
            </ul>
        </div>
    </nav>
);

export default NavbarMobile;
