import { t } from "i18next";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

const CheckboxSwitchLabel = styled.label([
    tw`w-[2.688rem] h-[1.5rem] rounded-[2.125rem] flex bg-orange-200 cursor-pointer items-center p-[0.1rem] pl-[1.3rem]`,
    css`
        &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 1.25rem;
            height: 1.25rem;
            background: ${theme`colors.orange.600`};
            transition: 0.2s;
        }
    `
]);

const CheckboxSwitch = styled.input([
    css`
        &:checked + label {
            background: ${theme`colors.grey.400`};
            transition: 0.2s;

            &::after {
                content: "";
                display: block;
                margin-left: -1.2rem;
                background: ${theme`colors.white`};
            }
        }
    `
]);

const ToggleSwitch = ({
    isActive,
    sectionName,
    handleToggleState
}: {
    isActive: boolean;
    sectionName: string;
    handleToggleState: any;
}) => (
    <div tw="flex justify-start items-center gap-[0.5rem] pt-[0.5rem]">
        <CheckboxSwitch
            tw="hidden"
            type="checkbox"
            checked={!isActive}
            id={sectionName}
            onChange={() => handleToggleState(sectionName)}
        />
        <CheckboxSwitchLabel htmlFor={sectionName} />
        <label tw="cursor-pointer text-grey-700" htmlFor={sectionName}>
            {t("subnational_data_label")}
        </label>
    </div>
);

export default ToggleSwitch;
