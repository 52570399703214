const ethiopianDivisions: { [year: string]: string } = {
  "2015": "updated_ethiopia_with_11_regions.geojson",
  "2016": "updated_ethiopia_with_11_regions.geojson",
  "2017": "updated_ethiopia_with_11_regions.geojson",
  "2018": "updated_ethiopia_with_11_regions.geojson",
  "2019": "updated_ethiopia_with_11_regions.geojson",
  "2020": "updated_ethiopia_with_11_regions.geojson",
  "2021": "updated_ethiopia_with_12_regions.geojson",
  "2022": "updated_ethiopia_with_13_regions.geojson",
  "2023": "updated_ethiopia_with_13_regions.geojson",
  "2024": "updated_ethiopia_with_14_regions.geojson",
  "2025": "updated_ethiopia_with_14_regions.geojson",
  "2026": "updated_ethiopia_with_14_regions.geojson",
  "2027": "updated_ethiopia_with_14_regions.geojson",
  "2028": "updated_ethiopia_with_14_regions.geojson",
  "2029": "updated_ethiopia_with_14_regions.geojson",
  "2030": "updated_ethiopia_with_14_regions.geojson",
};

export const loadEthiopianGeoJson = async (year: string): Promise<any> => {
  const geoJsonFile = ethiopianDivisions[year];
  if (!geoJsonFile) {
    throw new Error(`No GeoJSON file found for the year ${year}`);
  }

  try {
    const geoJsonUrl = (
      await import(`../data/subNationals/ethiopia/${geoJsonFile}`)
    ).default;

    const response = await fetch(geoJsonUrl);
    if (!response.ok) {
      throw new Error(
        `Failed to load GeoJSON file: ${geoJsonUrl}, status: ${response.status}`
      );
    }

    const geoJsonData = await response.json();
    return geoJsonData;
  } catch (error) {
    throw new Error(
      `Failed to load GeoJSON file: ${geoJsonFile}, error: ${error}`
    );
  }
};
