import { useAfricanYouth } from "context/africanYouth";
import { t } from "i18next";
import ConicGradient from "components/ConicGradient";
import info from "assets/images/info.svg";
import { SituationsType } from "types/situations.types";
import useWindowResize from "hooks/useWindowResize";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const SituationsData = ({
    data,
    tickingValue
}: {
    data: SituationsType[];
    tickingValue: string | number;
}) => {
    const { mobileModalDropdown, setMobileModalDropdown } = useAfricanYouth();
    const { isDesktop } = useWindowResize();

    return (
        <div
            tw="grid relative grid-cols-[repeat(2, 1fr)] justify-between items-end md:overflow-x-scroll md:gap-[1rem] md:p-[0 1rem 1rem 0]"
            className="scrollableHero"
        >
            {data.map(({ name, color, percentage }) => (
                <div
                    key={name}
                    tw="p-[0.75rem 1rem] relative gap-[1rem] md:gap-0 flex justify-between items-start md:border md:border-grey-200 md:bg-white md:rounded-[0.5rem] [&:nth-of-type(2)]:border-l [&:nth-of-type(2)]:border-l-grey-200 [&:nth-of-type(3)]:border-t [&:nth-of-type(3)]:border-t-grey-200 [&:nth-of-type(4)]:border-t [&:nth-of-type(4)]:border-t-grey-200 [&:nth-of-type(4)]:border-l [&:nth-of-type(4)]:border-l-grey-200"
                >
                    <div tw="flex gap-[1.5rem] sm:gap-[1rem] justify-between items-center">
                        <ConicGradient color={color} percentage={percentage} />
                        <div tw="flex flex-col justify-between">
                            <h3 tw="font-regular text-grey-500">
                                {t(`${name}.title`)}
                            </h3>
                            <strong tw="font-semiBold text-xl text-grey-600 flex items-center gap-[0.5rem]">
                                {(
                                    Number(tickingValue) *
                                    (Number(percentage) / 100)
                                ).toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                })}
                                <span tw="text-regular font-regular">
                                    {percentage!.toLocaleString(undefined, {
                                        minimumFractionDigits: 1,
                                        maximumFractionDigits: 1
                                    })}
                                    %
                                </span>
                            </strong>
                        </div>
                    </div>
                    <button
                        tw="relative w-[25px]"
                        className={isDesktop ? "group" : ""}
                        onClick={() =>
                            isDesktop
                                ? undefined
                                : setMobileModalDropdown({
                                      value: !mobileModalDropdown.isMobileModalDropdownOpen,
                                      name: "mobileModalDropdown",
                                      section: t(`${name}.title`)
                                  })
                        }
                    >
                        <img width={25} src={info} alt="Info" />
                        <span tw="absolute hidden group-hover:flex items-center -top-2/4 right-0 translate-x-2/4 max-w-[max-content] bg-grey-500 rounded-[0.5rem] text-white p-[0.5rem] w-[15rem] -translate-y-full text-sm text-left z-10">
                            {t(`${name}.text`)}
                        </span>
                    </button>
                </div>
            ))}
        </div>
    );
};

export default SituationsData;
