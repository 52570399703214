import { useState } from "react";

const useActiveSlide = () => {
    const [activeSlide, setActiveSlide] = useState(1);

    const handleActiveSlide = (index: number) => setActiveSlide(index + 1);

    return { activeSlide, handleActiveSlide };
};

export default useActiveSlide;
