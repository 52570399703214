import useFetch from "hooks/useFetch";
import { urls } from "config/urls";
import { useAfricanYouth } from "context/africanYouth";
import { getCountryId } from "utils/countries";
import { handleToFixed, handleToLocaleString } from "utils/formatting";
import Situations from "components/Main/Employment/Country/Situations";
import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { t } from "i18next";
import { Tooltip } from "types/employment.types";
import useWindowResize from "hooks/useWindowResize";
import { getGender } from "utils/filters";
import NoData from "components/NoData";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

const Country = () => {
  const [tooltip, setTooltip] = useState<Tooltip | null>(null);
  const { ages, country, gender, year, regionMapSection } = useAfricanYouth();

  const { isDesktop } = useWindowResize();
  const { isLoading, data, hasError } = useFetch(
    `${
      urls.API_HOST_URL
    }employment-breakdown?year=${year}&countryCode=${getCountryId(
      country.employment_section!
    )}${regionMapSection ? `&region=${regionMapSection}` : ""}${
      ages.from ? `&ageFrom=${ages.from}` : ""
    }${ages.to ? `&ageTo=${ages.to}` : ""}${
      gender !== "all" ? `&genderFilter=${getGender(gender)}` : ""
    }`,
    !country.employment_section
  );

  useEffect(() => {
    window.addEventListener("scroll", () => setTooltip(null));
    return () => window.removeEventListener("scroll", () => setTooltip(null));
  }, []);

  return (
    <>
      {tooltip && (
        <div
          style={{
            left: `${
              (!isDesktop
                ? tooltip?.event?.nativeEvent?.clientX
                : tooltip?.event?.nativeEvent?.pageX) -
              tooltip?.event?.nativeEvent?.offsetX
            }px`,
            top: `${
              (!isDesktop
                ? tooltip?.event?.nativeEvent?.clientY
                : tooltip?.event?.nativeEvent?.pageY) -
              tooltip?.event?.nativeEvent?.offsetY
            }px`,
          }}
          tw="absolute group-hover:flex items-center  bg-grey-500 rounded-[0.5rem] text-white p-[0.5rem] -translate-y-full -translate-x-1/2 md:-translate-x-0 md:max-w-[75vw] text-sm text-left z-10">
          {t(`${tooltip.category}_tooltip.text`)}
        </div>
      )}
      <div
        css={[
          tw`max-h-[35rem] lg:max-h-full lg:overflow-visible relative overflow-auto`,
          data?.totalPopulation === 0 || hasError ? tw`h-full` : tw`h-auto`,
        ]}
        className="scrollable">
        {hasError || data?.totalPopulation === 0 ? (
          <NoData />
        ) : isLoading ? (
          <Loader />
        ) : (
          <Situations
            tooltip={tooltip}
            setTooltip={setTooltip}
            data={[
              {
                name: "population",
                value: handleToLocaleString(data.totalPopulation),
                colors: [
                  theme`colors.green.600`,
                  theme`colors.red.600`,
                  theme`colors.yellow.600`,
                  theme`colors.blue.600`,
                ],
                data: [
                  new Map()
                    .set(
                      `Employed ${data.populationBreakDown.employedPercentage}%`,
                      data.populationBreakDown.employedPercentage
                    )
                    .entries()
                    .next().value,
                  new Map()
                    .set(
                      `Unemployed ${data.populationBreakDown.unemployedPercentage}%`,
                      data.populationBreakDown.unemployedPercentage
                    )
                    .entries()
                    .next().value,
                  new Map()
                    .set(
                      `Inactive ${data.populationBreakDown.inactivePercentage}%`,
                      data.populationBreakDown.inactivePercentage
                    )
                    .entries()
                    .next().value,
                  new Map()
                    .set(
                      `Student ${data.populationBreakDown.studentPercentage}%`,
                      data.populationBreakDown.studentPercentage
                    )
                    .entries()
                    .next().value,
                ],
              },
              {
                name: "Employed",
                value: handleToLocaleString(
                  data.populationBreakDown.totalEmployed
                ),
                percentage: handleToFixed(
                  data.populationBreakDown.employedPercentage
                ),
                color: theme`colors.green.600`,
                innerGroups: data?.employmentBreakdown
                  ?.povertyExtremelyPoorEmployed
                  ? [
                      {
                        name: "poor",
                        data: [
                          {
                            name: "notPoor",
                            value: handleToLocaleString(
                              data?.employmentBreakdown.povertyNotPoorEmployed
                            ),
                            percentage: handleToFixed(
                              data?.employmentBreakdown.povertyNotPoorPercentage
                            ),
                            color: theme`colors.green.600`,
                          },
                          {
                            name: "moderatelyPoor",
                            value: handleToLocaleString(
                              data?.employmentBreakdown
                                .povertyModeratelyPoorEmployed
                            ),
                            percentage: handleToFixed(
                              data?.employmentBreakdown
                                .povertyModeratelyPoorPercentage
                            ),
                            color: theme`colors.green.500`,
                          },
                          {
                            name: "extremelyPoor",
                            value: handleToLocaleString(
                              data?.employmentBreakdown
                                .povertyExtremelyPoorEmployed
                            ),
                            percentage: handleToFixed(
                              data?.employmentBreakdown
                                .povertyExtremelyPoorPercentage
                            ),
                            color: theme`colors.green.300`,
                          },
                        ],
                      },
                      {
                        name: "jobs",
                        data: [
                          {
                            color: theme`colors.green.600`,
                            name: "jobs",
                            value: handleToLocaleString(
                              data?.employmentBreakdown.totalFormalJobs
                            ),
                            percentage: handleToFixed(
                              data?.employmentBreakdown.formalJobsPercentage
                            ),
                          },
                        ],
                      },
                      {
                        name: "urban_areas",
                        data: [
                          {
                            color: theme`colors.green.600`,
                            name: "urban_areas",
                            value: handleToLocaleString(
                              data?.employmentBreakdown.totalUrbanArea
                            ),
                            percentage: handleToFixed(
                              data?.employmentBreakdown.urbanAreaPercentage
                            ),
                          },
                        ],
                      },
                    ]
                  : null,
              },
              {
                name: "Unemployed",
                value: handleToLocaleString(
                  data.populationBreakDown.totalUnemployed
                ),
                percentage: handleToFixed(
                  data.populationBreakDown.unemployedPercentage
                ),
                color: theme`colors.red.600`,
                innerGroups: data?.employmentBreakdown
                  ?.povertyExtremelyPoorEmployed
                  ? [
                      {
                        name: "urban_areas",
                        data: [
                          {
                            name: "urban_areas",
                            value: handleToLocaleString(
                              data.populationBreakDown.totalUnemployedUrban
                            ),
                            percentage: handleToFixed(
                              data.populationBreakDown.unemployedUrbanPercentage
                            ),
                            color: theme`colors.red.600`,
                          },
                        ],
                      },
                    ]
                  : null,
              },
              {
                name: "Inactive",
                value: handleToLocaleString(
                  data.populationBreakDown.totalInactive
                ),
                percentage: handleToFixed(
                  data.populationBreakDown.inactivePercentage
                ),
                color: theme`colors.yellow.600`,
              },
              {
                name: "Student",
                value: handleToLocaleString(
                  data.populationBreakDown.totalStudent
                ),
                percentage: handleToFixed(
                  data.populationBreakDown.studentPercentage
                ),
                color: theme`colors.blue.600`,
              },
            ]}
          />
        )}
      </div>
    </>
  );
};

export default Country;
