import Filters from "components/Main/Education/Filters";
import Table from "components/Main/Education/Table";
import Legend from "components/Main/Education/Legend";
import { t } from "i18next";
import { useAfricanYouth } from "context/africanYouth";
import { urls } from "config/urls";
import useFetch from "hooks/useFetch";
import Loader from "components/Loader";
import { getCountryId } from "utils/countries";
import { getGender } from "utils/filters";
import ShareBtn from "components/Share";
import NoData from "components/NoData";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import useWindowResize from "hooks/useWindowResize";

const EmploymentsSection = () => {
    const { country, year, ages, setFilters, gender } = useAfricanYouth();
    const { data, isLoading, hasError } = useFetch(
        `${urls.API_HOST_URL}education?year=${year}&countryCode=${getCountryId(
            country.education_section || ""
        )}&ageFrom=${ages.from || 15}&ageTo=${ages.to || 35}${
            gender !== "all" ? `&gender=${getGender(gender)}` : ""
        }`,
        !country.education_section
    );
    const { isDevice } = useWindowResize();

    return (
        <section
            id="Education"
            tw="bg-grey-50 p-[0 4rem 5rem] sm:p-[0 0 2rem 0] md:pb-8 border-t border-t-grey-200 border-b border-b-grey-200 relative"
        >
            <div tw="border-b border-b-grey-200 sm:border-none sm:mb-[1rem] mb-[2rem] flex justify-between gap-[1rem] items-center md:flex-col p-[2rem 0.5rem 1rem] sm:p-4 ">
                <div tw="flex flex-col gap-[1rem]">
                    <h2 tw="font-semiBold text-xl text-grey-600">
                        {t("employments_title")}
                    </h2>
                    <p tw="text-grey-500 text-sm">
                        {t("employments_sub_title")}
                    </p>
                </div>
                {country.education_section && !isDevice && (
                    <ShareBtn
                        section="Education"
                        country={country.education_section!}
                        gender={gender}
                        ages={{ from: ages.from!, to: ages.to! }}
                    />
                )}
            </div>
            <div tw="relative md:pb-8">
                <div tw="flex sm:flex-wrap sm:gap-[1rem] sm:px-4">
                    <Filters />
                    <div tw="flex flex-col gap-[1rem] w-full sm:flex-wrap pl-[1rem] sm:pl-0 sm:gap-2">
                        <label tw="text-grey-500 leading-[0.938rem] text-sm pb-[0.625rem] sm:pb-0">
                            {t("filters")}
                        </label>
                        <div tw="flex gap-[1rem]">
                            <button
                                onClick={() => {
                                    setFilters({
                                        value: true,
                                        name: "isFiltersDialogOpen"
                                    });
                                    setFilters({
                                        value: ["AGE", "GENDER"],
                                        name: "filterListForSection"
                                    });
                                }}
                                tw="bg-orange-600 hover:bg-orange-700 p-[0.25rem 0.5rem] text-sm text-white font-medium rounded-[1rem]"
                            >
                                {`${ages.from ? ages.from : 15}-${
                                    ages.to ? ages.to : 35
                                } years`}
                            </button>
                            <button
                                onClick={() => {
                                    setFilters({
                                        value: true,
                                        name: "isFiltersDialogOpen"
                                    });
                                    setFilters({
                                        value: ["AGE", "GENDER"],
                                        name: "filterListForSection"
                                    });
                                }}
                                tw="bg-orange-600 hover:bg-orange-700 p-[0.25rem 0.5rem] text-sm text-white font-medium rounded-[1rem]"
                            >
                                {t(gender)}
                            </button>
                        </div>
                    </div>
                </div>
                {!country.education_section || hasError ? null : isLoading ? (
                    <Loader />
                ) : data?.length > 0 && !hasError ? (
                    <div tw="lg:flex-col flex justify-between relative items-end flex-row-reverse p-[1rem 0] sm:p-0 lg:border-none border border-grey-200 bg-white rounded-[0.5rem] mt-[1rem] sm:bg-[transparent] m-[1rem auto] sm:m-0">
                        <Table data={data} />
                        <Legend />
                    </div>
                ) : (
                    <NoData />
                )}
            </div>
            {country.education_section && isDevice && (
                <ShareBtn
                    section="Education"
                    country={country.education_section!}
                    gender={gender}
                    ages={{ from: ages.from!, to: ages.to! }}
                />
            )}
        </section>
    );
};

export default EmploymentsSection;
