import { useMemo } from "react";
import { useAfricanYouth } from "context/africanYouth";
import Header from "components/ActiveShare/Inner/Header";
import { DataSharedProps } from "types/data-shared.types";
import StackedBarChart from "components/Charts/Bar";
import Info from "components/ActiveShare/Inner/Info";
import { colorsPalette, customColors } from "utils/lists";
import Statuses from "components/ActiveShare/Inner/Employment/Container/Vertical/Statuses";
import StatusesInnerRegion from "components/ActiveShare/Inner/Employment/Container/Vertical/StatusesInnerRegion";
import Sectors from "components/ActiveShare/Inner/Employment/Container/Vertical/Sectors";
import Pages from "components/ActiveShare/Inner/Pages";
import Carousel from "react-material-ui-carousel";
import { sectorsPerSlide, splitItems } from "utils/utilities";
import useActiveSlide from "hooks/useActiveSlide";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Vertical = ({
    data,
    isShareModalVertical,
    isDesktop
}: {
    data: DataSharedProps;
    isShareModalVertical: boolean;
    isDesktop: boolean;
}) => {
    const { mapEmployedSector } = useAfricanYouth();
    const { activeSlide, handleActiveSlide } = useActiveSlide();

    let chartData = useMemo(
        () =>
            mapEmployedSector
                ? data.data.sectors.map(
                      ({ percentage }: { percentage: number }) => [percentage]
                  )
                : [
                      [data.data.populationBreakDown.employedPercentage],
                      [data.data.populationBreakDown.studentPercentage],
                      [data.data.populationBreakDown.inactivePercentage],
                      [data.data.populationBreakDown.unemployedPercentage]
                  ],
        [data.data, mapEmployedSector]
    );

    chartData = chartData.map((item: any, i: number) => ({
        data: item,
        color: mapEmployedSector ? colorsPalette[i] : customColors[i],
        sector: mapEmployedSector ? data.data.sectors[i] : null
    }));

    return (
        <>
            <Header
                isShareModalVertical={isShareModalVertical}
                isDesktop={isDesktop}
            />
            <main tw="p-0 h-[calc(100% - 33px)]">
                <section tw="p-[0.25rem 0.5rem 0] flex flex-col justify-between h-full">
                    <Info
                        isShareModalVertical={isShareModalVertical}
                        data={data}
                        isInnerRegion={
                            data.data.employmentBreakdown ? false : true
                        }
                    />
                    <StackedBarChart data={chartData} />
                    <article tw="text-grey-600">
                        {mapEmployedSector ? (
                            chartData.length > sectorsPerSlide ? (
                                <>
                                    <Carousel
                                        onChange={(now) =>
                                            handleActiveSlide(now!)
                                        }
                                        sx={{
                                            width: "100%",
                                            height: "18.375rem"
                                        }}
                                        autoPlay={false}
                                        navButtonsAlwaysVisible={true}
                                        indicators={false}
                                    >
                                        {splitItems(
                                            chartData,
                                            sectorsPerSlide
                                        ).map((items: any, i: number) => (
                                            <Sectors
                                                key={data.data[i]?.name}
                                                data={items}
                                            />
                                        ))}
                                    </Carousel>
                                    <Pages
                                        activeSlide={activeSlide}
                                        itemsLength={
                                            splitItems(
                                                chartData,
                                                sectorsPerSlide
                                            ).length
                                        }
                                    />
                                </>
                            ) : (
                                <Sectors data={chartData} />
                            )
                        ) : data.data.employmentBreakdown ? (
                            <Statuses data={data} />
                        ) : (
                            <StatusesInnerRegion data={data} />
                        )}
                    </article>
                    <p tw="text-[0.5rem] text-grey-400 my-1">
                        https://africayouthjobs.io/
                    </p>
                </section>
            </main>
        </>
    );
};

export default Vertical;
