import Vertical from "components/ActiveShare/Inner/Education/Container/Vertical";
import Horizontal from "components/ActiveShare/Inner/Education/Container/Horizontal";
import { useAfricanYouth } from "context/africanYouth";
import { urls } from "config/urls";
import useFetch from "hooks/useFetch";
import { getCountryId } from "utils/countries";
import { getGender } from "utils/filters";

const Container = ({
    isShareModalVertical,
    isDesktop
}: {
    isShareModalVertical: boolean;
    isDesktop: boolean;
}) => {
    const { year, country, ages, gender } = useAfricanYouth();
    const { data } = useFetch(
        `${urls.API_HOST_URL}education?year=${year}&countryCode=${getCountryId(
            country.education_section || ""
        )}&ageFrom=${ages.from || 15}&ageTo=${ages.to || 35}${
            gender !== "all" ? `&gender=${getGender(gender)}` : ""
        }`
    );

    return data ? (
        !isDesktop || isShareModalVertical ? (
            <Vertical
                data={{
                    data,
                    country: country.education_section!,
                    year,
                    ages: [ages.from!, ages.to!],
                    gender
                }}
                isShareModalVertical={isShareModalVertical}
                isDesktop={isDesktop}
            />
        ) : (
            <Horizontal
                data={{
                    data,
                    country: country.education_section!,
                    year,
                    ages: [ages.from!, ages.to!],
                    gender
                }}
                isShareModalVertical={isShareModalVertical}
                isDesktop={isDesktop}
            />
        )
    ) : null;
};

export default Container;
