import { urls } from "config/urls";
import { useAfricanYouth } from "context/africanYouth";
import { useCallback, useState, useEffect } from "react";
import { getCountryId } from "utils/countries";
import { beginningCurrentYear, secondsPerYear } from "utils/utilities";
import useFetch from "./useFetch";

const useTicking = (value?: number) => {
    const { country, year } = useAfricanYouth();
    const isYearInPast = parseInt(year, 10) < new Date().getFullYear();

    const { data } = useFetch(
        `${urls.API_HOST_URL}main-hero?year=${Number(year) - 1}${
            country.hero_section && country.hero_section !== "Africa"
                ? `&countryCode=${getCountryId(country.hero_section)}`
                : ""
        }`
    );
    const handleTickingValue = useCallback((): number => {
        const now = Math.round(Date.now() / 1000);
        const secondsUntilNow = now - beginningCurrentYear;
        const currentyearPopulationChange = value! - data?.youthTotalNumber;
        const populatioCoveredPerSeconds =
            currentyearPopulationChange / secondsPerYear;
        const populatioCoveredPerSecondsUntilNow =
            secondsUntilNow * populatioCoveredPerSeconds;

        return populatioCoveredPerSecondsUntilNow;
    }, [value, data?.youthTotalNumber]);

    const currentCoverage = !value ? "" : handleTickingValue();
    const [tickingValue, setTickingValue] = useState(currentCoverage);

    useEffect(() => {
        const interval = setInterval(() => {
            setTickingValue(
                isYearInPast
                    ? value
                    : (Math.floor(value! + handleTickingValue()) as any)
            );
        }, 1000);

        return () => clearInterval(interval);
    }, [handleTickingValue, value, isYearInPast]);

    return { tickingValue };
};

export default useTicking;
