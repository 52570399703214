import DynamicSvg from "components/DynamicSvg/DynamicSvg";
import { t } from "i18next";
import { DataSharedProps } from "types/data-shared.types";
import { handleToLocaleString, handleToFixed } from "utils/formatting";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const StatusesInnerRegion = ({ data }: { data: DataSharedProps }) => (
    <>
        <div tw="border border-grey-100 p-[0.25rem] rounded">
            <div tw="flex items-center justify-between gap-[2rem] py-[0.25rem]">
                <div tw="flex items-center gap-[0.5rem]">
                    <img
                        src="assets/images/status/Employed.svg"
                        alt="Employed"
                        width={14}
                    />
                    <h4 tw="font-semiBold text-[0.56rem]">
                        {t("Employed.title")}
                    </h4>
                </div>
                <div tw="flex gap-[0.5rem] items-center font-semiBold">
                    <DynamicSvg iconName="population" size={14} />
                    <span tw="text-xs">
                        {handleToLocaleString(
                            data.data.populationBreakDown.totalEmployed
                        )}
                    </span>
                    <span tw="text-[0.56rem] text-grey-500">
                        {handleToFixed(
                            data.data.populationBreakDown.employedPercentage
                        )}
                        %
                    </span>
                </div>
            </div>
        </div>
        <div tw="border border-grey-100 p-[0.25rem] rounded flex items-start justify-between">
            <div tw="flex flex-col gap-[0.25rem]">
                <div tw="flex items-center gap-[0.5rem]">
                    <img
                        src="assets/images/status/Student.svg"
                        alt="Student"
                        width={14}
                    />
                    <h4 tw="font-semiBold text-[0.56rem]">
                        {t("Student.title")}
                    </h4>
                </div>
                <span tw="ml-[0.1rem] rounded-full bg-blue-600 w-[0.65rem] h-[0.65rem]"></span>
            </div>
            <div tw="flex gap-[0.5rem] items-center font-semiBold">
                <DynamicSvg iconName="population" size={14} />{" "}
                <span tw="text-xs">
                    {handleToLocaleString(
                        data.data.populationBreakDown.totalStudent
                    )}
                </span>
                <span tw="text-[0.56rem] text-grey-500">
                    {handleToFixed(
                        data.data.populationBreakDown.studentPercentage
                    )}
                    %
                </span>
            </div>
        </div>
        <div tw="border border-grey-100 p-[0.25rem] rounded flex justify-between">
            <div tw="flex flex-col gap-[0.25rem]">
                <div tw="flex items-center gap-[0.5rem]">
                    <img
                        src="assets/images/status/Inactive.svg"
                        alt="Inactive"
                        width={14}
                    />
                    <h4 tw="font-semiBold text-[0.56rem]">
                        {t("Inactive.title")}
                    </h4>
                </div>
                <span tw="ml-[0.1rem] rounded-full bg-yellow-600 w-[0.65rem] h-[0.65rem]"></span>
            </div>
            <div tw="flex gap-[0.5rem] items-start font-semiBold">
                <DynamicSvg iconName="population" size={14} />
                <span tw="text-xs">
                    {handleToLocaleString(
                        data.data.populationBreakDown.totalInactive
                    )}
                </span>
                <span tw="text-[0.56rem] text-grey-500">
                    {handleToFixed(
                        data.data.populationBreakDown.inactivePercentage
                    )}
                    %
                </span>
            </div>
        </div>
        <div tw="border border-grey-100 p-[0.25rem] rounded flex flex-col justify-between">
            <div tw="flex justify-between">
                <div tw="flex flex-col gap-[0.25rem]">
                    <div tw="flex items-center gap-[0.5rem]">
                        <img
                            src="assets/images/status/Unemployed.svg"
                            alt="Unemployed"
                            width={14}
                        />
                        <h4 tw="font-semiBold text-[0.56rem]">
                            {t("Unemployed.title")}
                        </h4>
                    </div>
                    <span tw="ml-[0.1rem] rounded-full bg-red-600 w-[0.65rem] h-[0.65rem]"></span>
                </div>
                <div tw="flex gap-[0.5rem] font-semiBold">
                    <DynamicSvg iconName="population" size={14} />
                    <span tw="text-xs">
                        {handleToLocaleString(
                            data.data.populationBreakDown.totalUnemployed
                        )}
                    </span>
                    <span tw="text-[0.56rem] text-grey-500">
                        {handleToFixed(
                            data.data.populationBreakDown.unemployedPercentage
                        )}
                        %
                    </span>
                </div>
            </div>
        </div>
    </>
);

export default StatusesInnerRegion;
