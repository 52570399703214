import { links } from "utils/lists";
import { t } from "i18next";
import { handleLinks } from "utils/formatting";
import { UseActiveLinkTypes } from "types/use-active-link.types";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const NavbarDesktop = ({
                           activeLink,
                           handleActiveLink
                       }: UseActiveLinkTypes) => (
    <nav tw="lg:hidden flex justify-between items-center">
        <ul tw="flex justify-between items-center gap-[1.5rem] xl:gap-[1rem] w-full">
            {handleLinks(links).map(({ name, id }) => (
                <li key={id} >
                    <a
                        href={id}
                        tw="text-grey-500 text-[0.813rem] hover:bg-grey-50 px-3 py-[1.5rem]"
                        css={[
                            activeLink === id &&
                            tw`text-orange-700 font-medium border-b border-b-orange-700`
                        ]}
                        onClick={() => handleActiveLink(id)}
                    >
                        {t(name)}
                    </a>
                </li>
            ))}
        </ul>
    </nav>
);

export default NavbarDesktop;
