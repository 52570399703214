import verticalWhite from "assets/images/verticalWhite.svg";
import verticalBlack from "assets/images/verticalBlack.svg";
import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Actions = ({
    isDesktop,
    handleToggleState,
    toggleState
}: {
    isDesktop: boolean;
    handleToggleState: any;
    toggleState: any;
}) => (
    <div css={[tw`flex items-center gap-[1rem]`, !isDesktop && tw`flex-col`]}>
        <h3 tw="font-semiBold text-white">
            {t(!isDesktop ? "share_as" : "share_images")}
        </h3>
        {!isDesktop ? (
            <div tw="flex gap-[1rem]">
                <button
                    tw="max-w-[6.25rem] w-full flex relative items-center font-semiBold uppercase border border-white rounded-[2.75rem] gap-[0.5rem] p-[0.5rem 0.75rem]"
                    css={[
                        toggleState.isWidgetActive
                            ? tw`bg-white text-grey-800`
                            : tw`bg-none text-white hover:bg-grey-500 hover:bg-opacity-80`
                    ]}
                    onClick={() =>
                        !toggleState.isWidgetActive &&
                        handleToggleState("isWidgetActive")
                    }
                >
                    {t("imageShare")}
                </button>
                <button
                    tw="max-w-[6.25rem] w-full flex relative items-center font-semiBold uppercase border border-white rounded-[2.75rem] gap-[0.5rem] p-[0.5rem 0.75rem]"
                    css={[
                        toggleState.isWidgetActive
                            ? tw`bg-none text-white hover:bg-grey-500 hover:bg-opacity-80`
                            : tw`bg-white text-grey-800`
                    ]}
                    onClick={() =>
                        toggleState.isWidgetActive &&
                        handleToggleState("isWidgetActive")
                    }
                >
                    {t("widget")}
                </button>
            </div>
        ) : (
            <>
                <button
                    className="group"
                    tw="flex relative items-center font-semiBold uppercase border border-white rounded-[2.75rem] gap-[0.5rem] p-[0.5rem 0.75rem]"
                    css={[
                        toggleState.isShareModalVertical
                            ? tw`bg-white text-grey-800`
                            : tw`bg-none text-white hover:bg-grey-500 hover:bg-opacity-80`
                    ]}
                    onClick={() =>
                        !toggleState.isShareModalVertical &&
                        handleToggleState("isShareModalVertical")
                    }
                >
                    <img
                        src={
                            toggleState.isShareModalVertical
                                ? verticalBlack
                                : verticalWhite
                        }
                        alt="Vertical"
                    />
                    {t("vertical")}
                    <div tw="absolute z-[1010] top-[-250%] w-[250%] left-[-50%] opacity-0 group-hover:opacity-100 inline-block px-6 py-4 text-sm font-medium text-white transition-opacity duration-300 bg-grey-500 rounded-lg shadow-sm dark:bg-grey-600">
                        {t("vertical_tooltip")}
                    </div>
                </button>
                <button
                    className="group"
                    tw="flex relative items-center font-semiBold uppercase border border-white rounded-[2.75rem] gap-[0.5rem] p-[0.5rem 0.75rem]"
                    css={[
                        toggleState.isShareModalVertical
                            ? tw`bg-none text-white hover:bg-grey-500 hover:bg-opacity-80`
                            : tw`bg-white text-grey-800`
                    ]}
                    onClick={() =>
                        toggleState.isShareModalVertical &&
                        handleToggleState("isShareModalVertical")
                    }
                >
                    <img
                        src={
                            toggleState.isShareModalVertical
                                ? verticalWhite
                                : verticalBlack
                        }
                        tw="rotate-90"
                        alt="Horizontal"
                    />
                    {t("horizontal")}
                    <div tw="absolute z-[1010] top-[-250%] w-[200%] left-[-50%] opacity-0 group-hover:opacity-100 inline-block px-6 py-4 text-sm font-medium text-white transition-opacity duration-300 bg-grey-500 rounded-lg shadow-sm dark:bg-grey-600">
                        {t("horizontal_tooltip")}
                    </div>
                </button>
            </>
        )}
    </div>
);

export default Actions;
