import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Tooltip = ({ activeSummaryGender }: any) => (
    <div tw="bg-grey-500 rounded-[0.5rem] top-[-1rem] left-[50%] translate-x-[-50%] text-white p-[1rem 0.5rem] absolute z-[1]">
        <h4 tw="font-semiBold">{t(activeSummaryGender.name)}</h4>
        <p tw="text-sm w-[7rem]">
            {activeSummaryGender.number} ({activeSummaryGender.percentage})%
        </p>
    </div>
);

export default Tooltip;
