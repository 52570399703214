import Header from "components/ActiveShare/Inner/Header";
import { DataSharedProps } from "types/data-shared.types";
import Info from "components/ActiveShare/Inner/Info";
import PieChart from "components/Charts/Pie";
import { useMemo } from "react";
import { useAfricanYouth } from "context/africanYouth";
import Statuses from "components/ActiveShare/Inner/Employment/Container/Horizontal/Statuses";
import StatusesInnerRegion from "components/ActiveShare/Inner/Employment/Container/Horizontal/StatusesInnerRegion";
import Sectors from "components/ActiveShare/Inner/Employment/Container/Horizontal/Sectors";
import { colorsPalette } from "utils/lists";
import Pages from "components/ActiveShare/Inner/Pages";
import Carousel from "react-material-ui-carousel";
import { sectorsPerSlide, splitItems } from "utils/utilities";
import useActiveSlide from "hooks/useActiveSlide";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";

const Horizontal = ({
    data,
    isShareModalVertical,
    isDesktop
}: {
    data: DataSharedProps;
    isShareModalVertical: boolean;
    isDesktop: boolean;
}) => {
    const { mapEmployedSector } = useAfricanYouth();
    const { activeSlide, handleActiveSlide } = useActiveSlide();

    const sectorsData = useMemo(
        () => ({
            ...data,
            data: mapEmployedSector
                ? data.data.sectors.map((sector: any, i: number) => ({
                      ...sector,
                      data: new Map()
                          .set(`${sector.percentage}`, sector.percentage)
                          .entries()
                          .next().value,
                      color: colorsPalette[i]
                  }))
                : [
                      {
                          data: new Map()
                              .set(
                                  `${data.data.populationBreakDown.employedPercentage}%`,
                                  data.data.populationBreakDown
                                      .employedPercentage
                              )
                              .entries()
                              .next().value,
                          color: theme`colors.green.600`
                      },
                      {
                          data: new Map()
                              .set(
                                  `${data.data.populationBreakDown.unemployedPercentage}%`,
                                  data.data.populationBreakDown
                                      .unemployedPercentage
                              )
                              .entries()
                              .next().value,
                          color: theme`colors.red.600`
                      },
                      {
                          data: new Map()
                              .set(
                                  `${data.data.populationBreakDown.inactivePercentage}%`,
                                  data.data.populationBreakDown
                                      .inactivePercentage
                              )
                              .entries()
                              .next().value,
                          color: theme`colors.yellow.600`
                      },
                      {
                          data: new Map()
                              .set(
                                  `${data.data.populationBreakDown.studentPercentage}%`,
                                  data.data.populationBreakDown
                                      .studentPercentage
                              )
                              .entries()
                              .next().value,
                          color: theme`colors.blue.600`
                      }
                  ]
        }),
        [data, mapEmployedSector]
    );

    return (
        <>
            <Header
                isShareModalVertical={isShareModalVertical}
                isDesktop={isDesktop}
            />
            <main tw="p-4">
                <section tw="p-[0.25rem 0.5rem 0] flex justify-center gap-[3rem] h-full">
                    <div tw="flex flex-col">
                        <Info
                            isShareModalVertical={isShareModalVertical}
                            data={data}
                        />
                        <PieChart
                            width={300}
                            colors={sectorsData.data.map(
                                ({ color }: any) => color
                            )}
                            data={sectorsData.data.map(({ data }: any) => data)}
                        />
                    </div>
                    <article tw="text-grey-600 flex flex-col gap-[0.5rem] w-full">
                        {mapEmployedSector ? (
                            sectorsData.data.length > sectorsPerSlide ? (
                                <>
                                    <Carousel
                                        onChange={(now) =>
                                            handleActiveSlide(now!)
                                        }
                                        sx={{
                                            width: "100%",
                                            height: "18.375rem"
                                        }}
                                        autoPlay={false}
                                        navButtonsAlwaysVisible={true}
                                        indicators={false}
                                    >
                                        {splitItems(
                                            sectorsData.data,
                                            sectorsPerSlide
                                        ).map((items: any, i: number) => (
                                            <Sectors
                                                key={data.data[i]?.name}
                                                data={items}
                                            />
                                        ))}
                                    </Carousel>
                                    <Pages
                                        activeSlide={activeSlide}
                                        itemsLength={
                                            splitItems(
                                                sectorsData.data,
                                                sectorsPerSlide
                                            ).length
                                        }
                                    />
                                </>
                            ) : (
                                <Sectors data={sectorsData.data} />
                            )
                        ) : data.data.employmentBreakdown ? (
                            <Statuses data={data} />
                        ) : (
                            <StatusesInnerRegion data={data} />
                        )}
                    </article>
                </section>
                <p tw="text-[0.5rem] text-grey-400 mt-2 px-[3rem]">
                    https://africayouthjobs.io/
                </p>
            </main>
        </>
    );
};

export default Horizontal;
