import { t } from "i18next";
import download from "assets/images/download.svg";
import Employment from "components/ActiveShare/Inner/Employment/Container";
import Genders from "components/ActiveShare/Inner/Genders/Container";
import Education from "components/ActiveShare/Inner/Education/Container";
import CountryComparison from "components/ActiveShare/Inner/CountryComparison/Container";
import Trends from "components/ActiveShare/Inner/Trends/Container";
import useToggle from "hooks/useToggle";
import { useAfricanYouth } from "context/africanYouth";
import Header from "components/ActiveShare/Header";
import Actions from "components/ActiveShare/Actions";
import Widget from "components/ActiveShare/Widget";
// import { shareSocials } from "utils/lists";
import { useRef } from "react";
import useExportImage from "hooks/useExportImage";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Container = ({ isDesktop }: { isDesktop: boolean }) => {
    const { isSharedModalOpen, sharedModalSection, sharedUrl } =
        useAfricanYouth();
    const { isExporting, handleImageToExport } = useExportImage();
    const { toggleState, handleToggleState } = useToggle();
    const exportRef = useRef<HTMLDivElement>(null);

    return (
        <aside
            css={[
                tw`flex justify-center fixed top-0 left-0 w-full h-full z-[1001] p-4 bg-grey-800 bg-opacity-80 transition-transform translate-y-[105%] duration-700 overflow-y-scroll`,
                isSharedModalOpen && tw`translate-y-0`
            ]}
        >
            <div tw="max-w-[82rem] w-full h-full flex flex-col justify-center md:block">
                <Header />
                <div
                    css={[
                        tw`grid grid-cols-[repeat(2, 1fr)] gap-[1rem]`,
                        !isDesktop && tw`flex justify-center`
                    ]}
                >
                    <div tw="flex items-center flex-col gap-[1rem] mt-4">
                        <Actions
                            isDesktop={isDesktop}
                            handleToggleState={handleToggleState}
                            toggleState={toggleState}
                        />
                        <div tw="flex flex-col transition ease-in-out items-center">
                            {!toggleState.isWidgetActive && !isDesktop ? (
                                <Widget />
                            ) : (
                                <>
                                    <div
                                        ref={exportRef}
                                        css={[
                                            tw`bg-grey-50 duration-300 h-auto w-[16rem] relative min-h-[24rem]`,
                                            !toggleState.isShareModalVertical &&
                                                isDesktop &&
                                                tw`w-[45rem] lg:w-[38rem]`,
                                            !isDesktop && tw`w-[16rem]`
                                        ]}
                                    >
                                        {
                                            {
                                                Employment: (
                                                    <Employment
                                                        isShareModalVertical={
                                                            toggleState.isShareModalVertical
                                                        }
                                                        isDesktop={isDesktop}
                                                    />
                                                ),
                                                Genders: (
                                                    <Genders
                                                        isShareModalVertical={
                                                            toggleState.isShareModalVertical
                                                        }
                                                        isDesktop={isDesktop}
                                                    />
                                                ),
                                                Education: (
                                                    <Education
                                                        isShareModalVertical={
                                                            toggleState.isShareModalVertical
                                                        }
                                                        isDesktop={isDesktop}
                                                    />
                                                ),
                                                Trends: (
                                                    <Trends
                                                        isShareModalVertical={
                                                            toggleState.isShareModalVertical
                                                        }
                                                        isDesktop={isDesktop}
                                                    />
                                                ),
                                                CountryComparison: (
                                                    <CountryComparison
                                                        isShareModalVertical={
                                                            toggleState.isShareModalVertical
                                                        }
                                                        isDesktop={isDesktop}
                                                    />
                                                )
                                            }[sharedModalSection!]
                                        }
                                    </div>
                                </>
                            )}
                            {isDesktop ? (
                                <button
                                    onClick={() =>
                                        handleImageToExport(
                                            exportRef.current,
                                            `Africa Youth Employment Clock - ${sharedModalSection} section`
                                        )
                                    }
                                    css={[
                                        tw`self-center mt-[1rem] uppercase flex gap-[1rem] items-center max-w-max bg-none text-white hover:bg-grey-500 hover:bg-opacity-80 font-semiBold border border-white py-2 px-5 rounded-[2.75rem]`,
                                        toggleState.isShareModalVertical &&
                                            tw`text-xs gap-2 px-3`
                                    ]}
                                    disabled={isExporting}
                                >
                                    <img
                                        src={download}
                                        alt="Download"
                                        width={10}
                                    />
                                    {t(
                                        isExporting ? "downloading" : "download"
                                    )}
                                </button>
                            ) : toggleState.isWidgetActive ? (
                                <ul
                                    className="scrollable"
                                    tw="mb-4 pb-4 pt-[2.6rem] flex gap-[1rem] flex-nowrap overflow-x-auto sm:w-[24rem]"
                                >
                                    <li>
                                        <button
                                            onClick={() =>
                                                handleImageToExport(
                                                    exportRef.current,
                                                    `Africa Youth Employment Clock - ${sharedModalSection} section`
                                                )
                                            }
                                            tw="flex items-center justify-center w-[3.5rem] h-[3.5rem] rounded-[50%] bg-white flex-[0 0 auto]"
                                            disabled={isExporting}
                                        >
                                            <img
                                                src="assets/images/socials/download.svg"
                                                alt="Download"
                                            />
                                        </button>
                                    </li>
                                    <li>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`mailto:?subject=Africa Youth Employment Clock - ${sharedModalSection} section&body=${encodeURIComponent(
                                                sharedUrl!
                                            )}`}
                                            tw="flex items-center justify-center w-[3.5rem] h-[3.5rem] rounded-[50%] bg-white flex-[0 0 auto]"
                                        >
                                            <img
                                                src="assets/images/socials/email.svg"
                                                alt="Email"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`whatsapp://send?text=${encodeURIComponent(
                                                sharedUrl!
                                            )}`}
                                            tw="flex items-center justify-center w-[3.5rem] h-[3.5rem] rounded-[50%] bg-white flex-[0 0 auto]"
                                        >
                                            <img
                                                src="assets/images/socials/whatsApp.svg"
                                                alt="WhatsApp"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`https://t.me/share/url?url=Africa Youth Employment Clock - ${sharedModalSection} section&text=${encodeURIComponent(
                                                sharedUrl!
                                            )}`}
                                            tw="flex items-center justify-center w-[3.5rem] h-[3.5rem] rounded-[50%] bg-white flex-[0 0 auto]"
                                        >
                                            <img
                                                src="assets/images/socials/telegram.svg"
                                                alt="telegram"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`http://www.instagram.com/?url=${encodeURIComponent(
                                                sharedUrl!
                                            )}`}
                                            tw="flex items-center justify-center w-[3.5rem] h-[3.5rem] rounded-[50%] bg-white flex-[0 0 auto]"
                                        >
                                            <img
                                                src="assets/images/socials/instagram.svg"
                                                alt="Instagram"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                                                sharedUrl!
                                            )}&via=`}
                                            tw="flex items-center justify-center w-[3.5rem] h-[3.5rem] rounded-[50%] bg-white flex-[0 0 auto]"
                                        >
                                            <img
                                                src="assets/images/socials/twitter.svg"
                                                alt="Twitter"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                                sharedUrl!
                                            )}`}
                                            tw="flex items-center justify-center w-[3.5rem] h-[3.5rem] rounded-[50%] bg-white flex-[0 0 auto]"
                                        >
                                            <img
                                                src="assets/images/socials/facebook.svg"
                                                alt="Facebook"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            ) : null}
                        </div>
                    </div>
                    {isDesktop && <Widget />}
                </div>
            </div>
        </aside>
    );
};

export default Container;
