import { t } from "i18next";
import logo from "assets/images/logo.svg";
import mastercard from "assets/images/mastercardShareLogo.jpg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Header = ({
    isShareModalVertical,
    isDesktop
}: {
    isShareModalVertical: boolean;
    isDesktop: boolean;
}) => (
    <header
        css={[
            tw`flex items-center justify-between bg-white shadow p-[0.25rem 4rem]`,
            (!isDesktop || isShareModalVertical) && tw`p-[0.25rem 0.5rem]`
        ]}
    >
        <div tw="flex items-center gap-[0.5rem]">
            <img src={logo} alt="Social Protection Clock" width={17} />
            <h1 tw="font-regular text-[0.3rem] uppercase text-green-700 w-[7.5rem]">
                {t("title")}
                <span tw="text-yellow-700 block">{t("sub_title")}</span>
            </h1>
        </div>
        <img width={20} alt="Master Card" src={mastercard} />
    </header>
);

export default Header;
