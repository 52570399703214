import { IdNamePair } from "types/id-pair.types";

export const handleToLocaleString = (value: string) => value.toLocaleString();

export const handleToFixed = (value: number) => value.toFixed(1);

export const handleLinks = (links: IdNamePair[]) => {
    if (localStorage.getItem("feedbackAlreadySubmitted") === "true")
        return links.filter((link) => link.name !== "help_us_get_better");
    return links;
};

export const formatNumber = (value:number, decimalPlace = 2) =>{
    return value.toLocaleString(undefined,{ minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace })
}
